.author-biography {
  border-bottom: 2px solid var(--color-black);
  border-top: 2px solid var(--color-black);
  margin-bottom: 0;
  padding-bottom: var(--space-between-small);
  padding-top: var(--space-between-small); }

.author-biography .row.author-biography__row {
  margin-bottom: 0; }

.author-biography__image {
  width: 100%; }

.author-biography__tag,
.author-biography__title,
.author-biography__icon {
  margin-bottom: var(--space-between-xxxsmall); }

.author-biography__icon {
  fill: var(--color-main); }

.author-biography__image {
  margin-bottom: var(--space-between-xxsmall); }

.author-biography__biography:not(:last-child) {
  margin-bottom: var(--space-between-xsmall); }

.author-biography__more {
  color: var(--color-main); }

.author-biography__more:hover {
  cursor: pointer; }

@media screen and (min-width: 1280px) {
  .author-biography__image {
    margin-bottom: 0; }
  .author-biography__colum-image {
    width: calc((2 / 8) * 100%); }
  .author-biography__column-content {
    width: calc((6 / 8) * 100%); } }
