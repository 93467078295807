:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.filter {
  width: 75%;
  position: fixed;
  z-index: 9999999;
  z-index: var(--z-index-modal);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: calc(32px*2 + 16px) 16px;
  padding: calc(var(--space-between-small)*2 + var(--space-between-xxsmall)) var(--space-between-xxsmall);
  background: #fff;
  height: 100%;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.2s ease;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  pointer-events: none; }

.filter.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  pointer-events: auto;
  opacity: 1; }

.filter__close-icon {
  position: absolute;
  top: 24px;
  top: var(--space-between-xsmall);
  right: 16px;
  right: var(--space-between-xxsmall);
  cursor: pointer; }

.filter__aside-title {
  display: none;
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .filter__aside-title {
      margin-bottom: calc(48px + 0px); } }

.filter__title {
  display: flex;
  padding: 16px 32px;
  padding: var(--space-between-xxsmall) var(--space-between-small);
  background-color: #fff;
  background-color: var(--color-secondary);
  color: #ee2537;
  color: var(--color-main);
  justify-content: space-between;
  -webkit-transform: ease all 400ms;
          transform: ease all 400ms;
  will-change: color, background-color; }

.filter__title:hover {
  cursor: pointer; }

.filter__title-icon svg {
  fill: #ee2537;
  fill: var(--color-main);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  will-change: transform, fill; }

.filter__items {
  max-height: 0;
  overflow: hidden;
  padding: 0 32px;
  padding: 0 var(--space-between-small);
  will-change: max-height, padding; }

.filter__item:not(:last-of-type) {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.filter__section {
  border: solid 1px #ee2537;
  border: solid 1px var(--color-main);
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.filter__items input[type='text'] {
  width: 100%;
  padding: 10px;
  border: solid 1px #ee2537;
  border: solid 1px var(--color-main); }

.filter__items > *:not(:last-of-type) {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.filter .form-checkbox__input {
  position: absolute; }

.filter__section--selected .filter__title {
  background-color: #ee2537;
  background-color: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.filter__section--selected .filter__items {
  max-height: 9999px;
  padding: 16px 32px;
  padding: var(--space-between-xxsmall) var(--space-between-small); }

.filter__section--selected .filter__title-icon svg {
  fill: #fff;
  fill: var(--color-secondary);
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.filter__section-clean {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .filter__section-clean {
      margin-bottom: calc(48px + 0px); } }

.filter__clean {
  text-decoration: underline;
  -webkit-text-decoration-color: #ee2537;
          text-decoration-color: #ee2537;
  -webkit-text-decoration-color: var(--color-main);
          text-decoration-color: var(--color-main); }

.filter--mobile {
  width: 100%;
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .filter--mobile {
      margin-bottom: calc(48px + 0px); } }

.filter--mobile .filter__title-icon svg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.filter__section-clean .button {
  display: none; }

.filter__item-date {
  font-size: 16px; }

@media screen and (min-width: 768px) {
  .filter--mobile {
    display: none; }
  .filter {
    display: inherit;
    position: relative;
    height: auto;
    padding: 0;
    z-index: 1;
    z-index: var(--base-z-index);
    -webkit-transform: translateX(0);
            transform: translateX(0);
    pointer-events: auto;
    opacity: 1; }
  .filter__close-icon {
    display: none; }
  .filter__aside-title {
    display: flex; } }

@media screen and (max-width: 767px) {
  .filter__section-clean {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 32px);
    grid-gap: 0;
    gap: 0;
    align-content: center; }
  .filter__section-clean .inline-button {
    order: 2;
    align-self: center;
    width: 50%;
    justify-content: center; }
  .filter__section-clean .button {
    display: block;
    font-weight: 700; } }
