:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative; }

.slider__carousel {
  outline: none;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  position: relative; }

.slider .is-fullscreen {
  padding-bottom: 0; }

.slider__card {
  height: auto;
  max-width: 100vw; }
  .slider__card > * {
    margin-right: calc(16px / 4);
    display: block; }
    @media screen and (min-width: 768px) {
      .slider__card > * {
        margin-right: calc(32px / 4); } }
    @media screen and (min-width: 1440px) {
      .slider__card > * {
        margin-right: calc(48px / 4); } }
    @media screen and (min-width: 1680px) {
      .slider__card > * {
        margin-right: calc(80px / 4); } }

.slider__card--no-margin {
  margin-right: 0; }

.body .flickity-enabled.is-fullscreen {
  overflow: hidden; }

.slider .flickity-enabled.is-fullscreen {
  background: #27251f;
  background: var(--color-black);
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20; }

.slider .flickity-enabled.is-fullscreen .slider__card {
  height: 100vh; }

.slider .flickity-enabled.is-fullscreen .video-component {
  margin: auto;
  width: auto; }

.slider .flickity-enabled.is-fullscreen .video-component iframe {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.slider .flickity-viewport {
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: height 0.2s; }

.slider .is-fullscreen .flickity-viewport {
  height: 100vh; }

.flickity-fullscreen-button-view,
.flickity-fullscreen-button-exit {
  display: none; }

.slider__carousel-nav .slider__card {
  width: 50%; }

.slider__buttons {
  width: calc(56px * 2);
  display: flex;
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall);
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1440px) {
    .slider__buttons {
      width: calc(64px * 2); } }

.slider__buttons .button {
  height: calc(56px + 0px);
  width: calc(56px + 0px); }
  @media screen and (min-width: 1440px) {
    .slider__buttons .button {
      height: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .slider__buttons .button {
      width: calc(64px + 0px); } }

.slider__buttons .button:first-child {
  border-right: none; }

.slider__fullscreen {
  top: calc(48px * -1.5);
  width: calc(56px + 0px);
  right: calc(16px + 0px);
  position: absolute;
  z-index: 9; }
  @media screen and (min-width: 1280px) {
    .slider__fullscreen {
      top: calc(64px * -1.5); } }
  @media screen and (min-width: 1440px) {
    .slider__fullscreen {
      width: calc(64px + 0px); } }
  @media screen and (min-width: 768px) {
    .slider__fullscreen {
      right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .slider__fullscreen {
      right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .slider__fullscreen {
      right: calc(80px + 0px); } }

.slider__fullscreen svg {
  display: flex; }

.slider .flickity-enabled.is-fullscreen .slider__fullscreen {
  right: 50px;
  top: calc(80px + 20px); }
  @media screen and (min-width: 1280px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(110px + 20px); } }
  @media screen and (min-width: 1680px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(120px + 20px); } }

.slider__progress {
  background: #27251f;
  background: var(--color-black); }

.slider__button-previous-icon {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.slider__button-next-icon {
  fill: #ee2537;
  fill: var(--color-main);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

@media screen and (min-width: 768px) {
  .slider__carousel {
    flex-grow: 1;
    order: 2; }
  .slider__card {
    height: auto;
    max-width: 100vw; }
    .slider__card > * {
      margin-right: calc(16px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 768px) {
      .slider__card > * {
        margin-right: calc(32px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1440px) {
      .slider__card > * {
        margin-right: calc(48px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1680px) {
      .slider__card > * {
        margin-right: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .slider .flickity-enabled.is-fullscreen .slider__card {
    margin-right: 0;
    max-height: 100vh;
    max-width: 100vw; }
  .slider__carousel-nav .slider__card {
    width: 20%; }
  .slider__buttons {
    margin-top: 0; } }

@media screen and (min-width: 1280px) {
  .slider__fullscreen {
    top: calc(48px * -1); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .slider__fullscreen {
      top: calc(64px * -1); } }

@media screen and (min-width: 1280px) {
  .slider__carousel {
    padding-bottom: 0; }
  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 100px; }
  .slider--with-horizontal-cards .slider__card {
    height: 180px;
    height: var(--dimension-horizontal-cards); }
  .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
    right: 50px;
    top: calc(80px + 10px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(110px + 10px); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(120px + 10px); } }

@media screen and (min-width: 1440px) {
  .slider {
    flex-direction: row; }
  .slider__buttons {
    height: calc(56px * 2);
    margin-right: calc(16px + 0px);
    width: calc(56px + 0px);
    flex-direction: column; } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .slider__buttons {
      height: calc(64px * 2); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .slider__buttons {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .slider__buttons {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1440px) and (min-width: 1680px) {
    .slider__buttons {
      margin-right: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .slider__buttons {
      width: calc(64px + 0px); } }

@media screen and (min-width: 1440px) {
  .slider__buttons .button:first-child {
    border-bottom: none;
    border-right: 2px solid #ee2537;
    border-right: var(--dimension-border) solid var(--color-main); }
  .slider__buttons .button--disabled:first-child {
    border-right: 2px solid rgba(37, 37, 31, 0.5);
    border-right: var(--dimension-border) solid var(--color-black-faded); }
  .slider__buttons--inverted .button:first-child {
    border-bottom: none;
    border-right: 2px solid #fff;
    border-right: var(--dimension-border) solid var(--color-secondary); }
  .slider__buttons--inverted .button--disabled:first-child {
    border-right: 2px solid rgba(37, 37, 31, 0.5);
    border-right: var(--dimension-border) solid var(--color-black-faded); }
  .slider__button-previous-icon {
    -webkit-transform: rotate(270deg) translateY(-15%);
            transform: rotate(270deg) translateY(-15%); }
  .slider__button-next-icon {
    -webkit-transform: rotate(90deg) translateY(-15%);
            transform: rotate(90deg) translateY(-15%); }
  .slider__fullscreen {
    display: none;
    right: 0;
    top: 0;
    border: 1px solid #ee2537;
    border: 1px solid var(--color-main); }
    .slider__fullscreen svg {
      display: flex;
      fill: #ee2537;
      fill: var(--color-main); }
  .slider__card:hover .slider__fullscreen {
    display: flex; } }

@media screen and (min-width: 1920px) {
  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 50px; } }
