.cms-form {
  margin-top: 48px;
  margin-bottom: 48px; }

.cms-form__form {
  margin-top: 28px; }

.cms-form__column {
  width: 100%; }

.cms-form__description {
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-xxxsmall); }
