.video-component {
  display: block;
  height: auto;
  position: relative;
  width: 100%;
}

.video-component .video::before {
  padding-top: 0;
}

.video-component .video {
  position: static;
  position: initial;
}

.video-component__image,
.video-component iframe {
  height: 100%;
  object-fit: cover;
}

.video-component iframe {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-component__icon {
  border: var(--dimension-border) solid var(--color-secondary);
  border-radius: 50%;
  cursor: pointer;
  fill: var(--color-secondary);
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 100px;
}

@media screen and (min-width: 768px) {
  .video-component {
    height: 100%;
  }
}
