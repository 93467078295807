.breadcrumb-item {
  margin-right: 2px;
}

.breadcrumb-item:hover {
  color: var(--color-main);
}

.breadcrumb-item:last-child {
  margin-right: 4px;
}