.project__hero-main-content {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-between-xxsmall);
}

.project__hero-main-content > div {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .project__hero-main-content {
    flex-direction: row;
    justify-content: space-between;
  }
}
