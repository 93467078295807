.section {
  display: grid;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .section > * {
    grid-area: 1/-1; }

.section__content {
  padding-top: 40px;
  padding-bottom: 30px;
  z-index: 1; }
  @media screen and (min-width: 992px) {
    .section__content {
      padding-top: 50px;
      padding-bottom: 80px; } }
