:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-blog-post-preview {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1024px) {
    .inspiring-blog-post-preview {
      margin-bottom: calc(48px + 0px); } }
  .inspiring-blog-post-preview a {
    flex-grow: 1; }

.inspiring-blog-post-preview__content {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  background: #fff;
  background: var(--color-secondary);
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 16px;
  padding-top: var(--space-between-xxsmall);
  position: relative;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing);
  z-index: 25;
  z-index: var(--z-index-card-content); }
  @media screen and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(80px / 2); } }
  .inspiring-blog-post-preview__content::after {
    background: #ee2537;
    background: var(--color-main);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform var(--transition-timing-default) ease-in-out;
    transition: transform var(--transition-timing-default) ease-in-out;
    transition: transform var(--transition-timing-default) ease-in-out, -webkit-transform var(--transition-timing-default) ease-in-out;
    width: 100%;
    z-index: -1;
    z-index: var(--before-after-z-index); }
  .inspiring-blog-post-preview__content .title-text-component__title {
    color: #ee2537;
    color: var(--color-main);
    margin-bottom: 8px;
    margin-bottom: var(--space-between-xxxsmall); }

.inspiring-blog-post-preview__date {
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  right: var(--space-between-xsmall);
  text-shadow: 1px 1px 2px #000;
  top: 16px;
  top: var(--space-between-xxsmall); }
  .inspiring-blog-post-preview__date .typography:first-child {
    margin: auto; }
  .inspiring-blog-post-preview__date .typography:last-child {
    text-transform: capitalize; }

.inspiring-blog-post-preview .inspiring-blog-post-preview__content {
  transition: 0s background; }

.inspiring-blog-post-preview .inspiring-blog-post-preview__picture {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }
  .inspiring-blog-post-preview .inspiring-blog-post-preview__picture .picture__image {
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: var(--generic-transform-timing); }

.inspiring-blog-post-preview__wrapper {
  height: 100%;
  position: relative; }

.inspiring-blog-post-preview__bottom {
  display: flex;
  flex-direction: row;
  width: 100%; }

.inspiring-blog-post-preview__title,
.inspiring-blog-post-preview__excerpt,
.inspiring-blog-post-preview__category {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.inspiring-blog-post-preview__category {
  text-decoration: underline; }

.inspiring-blog-post-preview__title--content {
  font-weight: 600;
  line-height: 32px;
  line-height: var(--line-height-for-20-smaller);
  font-size: 20px;
  font-size: var(--font-size-20); }

.inspiring-blog-post-preview__excerpt--content {
  line-height: 32px;
  line-height: var(--line-height-for-20-smaller);
  font-size: 16px;
  font-size: var(--font-size-16); }

.inspiring-blog-post-preview__left {
  overflow: hidden;
  width: 100%; }

.inspiring-blog-post-preview__right {
  --iconography-arrow: 40px;
  margin-left: auto;
  text-align: right;
  width: 100%; }

.inspiring-blog-post-preview__left .animated-inline-button .animated-inline-button__text,
.inspiring-blog-post-preview__right .animated-inline-button .animated-inline-button__text {
  margin-right: 24px;
  margin-right: var(--space-between-xsmall); }

.inspiring-blog-post-preview__author {
  font-weight: bold; }

.inspiring-blog-post-preview__share {
  left: 24px;
  left: var(--space-between-xsmall);
  position: absolute;
  top: 24px;
  top: var(--space-between-xsmall);
  z-index: 1000; }

.inspiring-blog-post-preview__wrapper:hover {
  cursor: pointer; }
  .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content {
    color: #fff;
    color: var(--color-secondary); }
    .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content:after {
      -webkit-transform: scaleY(1);
              transform: scaleY(1); }
    .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button {
      pointer-events: none; }
      .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__main {
        color: #fff;
        color: var(--color-secondary); }
      .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__shaft:before, .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__shaft:after, .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__shaft {
        background-color: #fff;
        background-color: var(--color-secondary); }
  .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__picture .picture__image {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

@media screen and (min-width: 768px) {
  .inspiring-blog-post-preview--horizontal {
    flex-direction: row;
    height: 100%; }
  .inspiring-blog-post-preview--horizontal .inspiring-blog-post-preview__picture {
    flex-shrink: 0;
    width: 30%; }
  .inspiring-blog-post-preview--bigger-image .inspiring-blog-post-preview__picture {
    flex-shrink: 0;
    width: 35%; }
  .inspiring-blog-post-preview__picture-image {
    height: 100%; }
  .inspiring-blog-post-preview__content {
    padding-right: calc(16px / 4);
    padding-left: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(80px / 4); } }

@media screen and (min-width: 768px) {
  .inspiring-blog-post-preview__left,
  .inspiring-blog-post-preview__right {
    width: 50%; } }

@media screen and (min-width: 1440px) {
  .inspiring-blog-post-preview__title--content {
    font-weight: 600;
    line-height: 40px;
    line-height: var(--line-height-for-20);
    font-size: 24px;
    font-size: var(--font-size-24); }
  .inspiring-blog-post-preview__excerpt--content {
    line-height: 32px;
    line-height: var(--line-height-for-20-smaller);
    font-size: 18px;
    font-size: var(--font-size-18); } }

@media screen and (min-width: 1440px) {
  .inspiring-blog-hero-card__content-title {
    line-height: 64px;
    line-height: var(--line-height-for-64-smaller); }
  .inspiring-blog-hero-card__date {
    right: 32px;
    right: var(--space-between-small);
    top: 32px;
    top: var(--space-between-small); } }
