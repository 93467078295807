:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-gallery {
  max-width: 100vw;
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-gallery {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-gallery {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-gallery {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-gallery {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-gallery {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-gallery {
      padding-left: calc(80px + 0px); } }
  .landing-gallery__slider {
    margin-right: calc(16px * -1); }
    @media screen and (min-width: 768px) {
      .landing-gallery__slider {
        margin-right: calc(32px * -1); } }
    @media screen and (min-width: 1440px) {
      .landing-gallery__slider {
        margin-right: calc(48px * -1); } }
    @media screen and (min-width: 1680px) {
      .landing-gallery__slider {
        margin-right: calc(80px * -1); } }
  .landing-gallery .swiper-container {
    display: flex;
    flex-direction: column; }
    .landing-gallery .swiper-container .swiper-slide {
      width: auto; }
  .landing-gallery__item {
    display: flex;
    flex-direction: column;
    grid-row-gap: calc(0.33rem * 3);
    row-gap: calc(0.33rem * 3);
    grid-row-gap: calc(var(--s) * 3);
    row-gap: calc(var(--s) * 3); }
    .landing-gallery__item img {
      height: 100vw;
      width: auto; }
      @media screen and (min-width: 576px) {
        .landing-gallery__item img {
          height: 36vw; } }
    .landing-gallery__item-caption {
      color: #6e6e6e;
      color: var(--landing-color-neutral-600); }
  .landing-gallery__navigation {
    display: flex;
    grid-column-gap: 0.33rem;
    -webkit-column-gap: 0.33rem;
            column-gap: 0.33rem;
    grid-column-gap: var(--s);
    -webkit-column-gap: var(--s);
            column-gap: var(--s);
    margin-top: calc(0.33rem * 10);
    margin-top: calc(var(--s) * 10);
    margin-left: auto;
    margin-right: calc(16px + 0px); }
    @media screen and (min-width: 768px) {
      .landing-gallery__navigation {
        margin-right: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .landing-gallery__navigation {
        margin-right: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .landing-gallery__navigation {
        margin-right: calc(80px + 0px); } }
    .landing-gallery__navigation button {
      display: flex;
      cursor: pointer;
      background-color: transparent;
      border: 0.5px solid #252525;
      border: 0.5px solid var(--landing-color-neutral-800);
      border-radius: 1.25em;
      padding: 0.75em 2em; }
      .landing-gallery__navigation button[disabled] {
        opacity: 0.5;
        cursor: not-allowed; }
