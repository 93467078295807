@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: var(--font-size-16);
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-accordion {
  display: flex;
  flex-direction: column;
  width: 100%; }

.landing-accordion__item + .landing-accordion__item .landing-accordion__header {
  border-top: 1px solid #252525;
  border-top: 1px solid var(--landing-color-neutral-800); }

.landing-accordion__header {
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: calc(0.33rem * 15);
  min-height: calc(var(--s) * 15);
  text-transform: uppercase;
  padding-top: 1.38em;
  padding-bottom: 1.38em;
  cursor: pointer; }

.landing-accordion__header-text {
  margin-right: calc(0.33rem * 3);
  margin-right: calc(var(--s) * 3);
  text-align: left; }

.landing-accordion__header .landing-icon {
  --size: 2em;
  margin-left: auto; }

.landing-accordion__animation-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 200ms ease;
  transition: grid-template-rows 200ms ease, -ms-grid-rows 200ms ease; }

.landing-accordion__item.landing-accordion__item--expanded .landing-accordion__animation-wrapper {
  grid-template-rows: 1fr; }

.landing-accordion__item.landing-accordion__item--expanded .landing-accordion__transform-wrapper {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  transition: visibility 0s linear, -webkit-transform 300ms ease;
  transition: transform 300ms ease, visibility 0s linear;
  transition: transform 300ms ease, visibility 0s linear, -webkit-transform 300ms ease; }

.landing-accordion__item.landing-accordion__item--expanded .landing-accordion__content {
  -webkit-animation: landing_accordion_fade_in 300ms 200ms forwards;
          animation: landing_accordion_fade_in 300ms 200ms forwards; }

.landing-accordion__animation {
  min-height: 0; }

.landing-accordion__transform-wrapper {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  visibility: hidden;
  transition: visibility 0s var(--transitionLength) var(--transitionTiming), -webkit-transform var(--transitionLength) var(--transitionTiming);
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming), -webkit-transform var(--transitionLength) var(--transitionTiming); }

.landing-accordion__content {
  padding: 0 4em 2.5em 1.5em;
  margin: 0em;
  opacity: 0; }
  .landing-accordion__content > * + * {
    margin-top: calc(0.33rem * 3);
    margin-top: calc(var(--s) * 3); }
  .landing-accordion__content a {
    text-decoration: underline;
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }
  .landing-accordion__content a:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }
  .landing-accordion__content p {
    display: block; }
  .landing-accordion__content ol {
    list-style: decimal; }
  .landing-accordion__content ul {
    list-style: disc; }
  .landing-accordion__content ol,
  .landing-accordion__content ul {
    padding-left: 1.05em;
    padding-left: var(--landing-list-padding); }
    .landing-accordion__content ol > * + *,
    .landing-accordion__content ul > * + * {
      margin-top: calc(0.33rem * 2);
      margin-top: calc(var(--s) * 2); }
  .landing-accordion__content strong {
    font-weight: 700;
    font-variation-settings: 'wght' 700; }
  .landing-accordion__content em {
    font-style: italic; }

@-webkit-keyframes landing_accordion_fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes landing_accordion_fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
