:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.form {
  height: 100%;
  width: 100%; }
  .form:not(.form--full-height) iframe {
    height: 86vh;
    overflow: scroll; }

.form--catalog .form__image {
  display: none; }

.form form.form {
  padding: 0; }

.form .form__form {
  padding-top: 0; }

.form__title {
  margin-left: calc(16px + 0px);
  color: #27251f;
  color: var(--color-black);
  display: block;
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }
  @media screen and (min-width: 768px) {
    .form__title {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .form__title {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .form__title {
      margin-left: calc(80px + 0px); } }

.form--catalog .form__title {
  margin-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .form--catalog .form__title {
      margin-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form--catalog .form__title {
      margin-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form--catalog .form__title {
      margin-left: calc(80px / 2); } }

.form__form {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  opacity: 1; }
  @media screen and (min-width: 768px) {
    .form__form {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__form {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form {
      padding-right: calc(80px / 2); } }

.form__form-column--full,
.form__form-column--half {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall);
  width: 100%; }

.form .required label:after {
  content: ' *'; }

.form__form form.form input.text {
  width: 100%; }

.form__form form.form input,
.form__form form.form textarea.standard {
  padding: calc(16px / 4); }
  @media screen and (min-width: 768px) {
    .form__form form.form input,
    .form__form form.form textarea.standard {
      padding: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form input,
    .form__form form.form textarea.standard {
      padding: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form input,
    .form__form form.form textarea.standard {
      padding: calc(80px / 4); } }

.form-input input {
  height: 64px;
  height: var(--dimension-input); }

.form__form form.form textarea.standard {
  background: transparent;
  height: 160px;
  height: var(--dimension-textarea);
  outline: none;
  width: 100%; }

.form-input input,
.form-select select,
.form__form form.form textarea.standard {
  padding: calc(16px / 4);
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black);
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }
  @media screen and (min-width: 768px) {
    .form-input input,
    .form-select select,
    .form__form form.form textarea.standard {
      padding: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form-input input,
    .form-select select,
    .form__form form.form textarea.standard {
      padding: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form-input input,
    .form-select select,
    .form__form form.form textarea.standard {
      padding: calc(80px / 4); } }

.form-select {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.form-select select {
  padding-left: calc(16px / 4);
  -webkit-appearance: none;
  background: url(./../../svg/pyke.png) 97% no-repeat transparent;
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black);
  border-radius: 0;
  font-size: 18px;
  font-size: var(--font-size-18);
  height: 64px;
  height: var(--dimension-input);
  line-height: 24px;
  line-height: var(--line-height-for-18);
  outline: none;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .form-select select {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form-select select {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form-select select {
      padding-left: calc(80px / 4); } }

.form__form .form-checkbox {
  align-items: baseline;
  color: #27251f;
  color: var(--color-black);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }
  .form__form .form-checkbox a {
    color: #ee2537;
    color: var(--color-main); }

.form__form .form-field .description {
  color: #27251f;
  color: var(--color-black); }
  .form__form .form-field .description a {
    text-decoration: underline; }

input[type='checkbox'] {
  margin-right: calc(16px / 4);
  cursor: pointer;
  height: 20px;
  height: var(--dimension-checkbox);
  outline: none;
  position: relative;
  width: 20px;
  width: var(--dimension-checkbox); }
  @media screen and (min-width: 768px) {
    input[type='checkbox'] {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    input[type='checkbox'] {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    input[type='checkbox'] {
      margin-right: calc(80px / 4); } }

input[type='checkbox']:before {
  background-color: #f7f6f5;
  background-color: var(--color-desert-storm);
  border: 2px solid #ee2537;
  border: var(--dimension-border) solid var(--color-main);
  content: '';
  display: block;
  height: 20px;
  height: var(--dimension-checkbox);
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  width: var(--dimension-checkbox); }

.form-input input::-moz-selection {
  background-color: rgba(37, 37, 31, 0.5);
  background-color: var(--color-black-faded); }

.form-input input::selection {
  background-color: rgba(37, 37, 31, 0.5);
  background-color: var(--color-black-faded); }

input[type='checkbox']:focus:before,
input[type='checkbox']:active:before {
  border: calc(2px * 2) solid #ee2537;
  border: calc(var(--dimension-border) * 2) solid var(--color-main); }

input[type='checkbox']:checked:after {
  align-content: center;
  color: #ee2537;
  color: var(--color-main);
  content: '\2715';
  font-size: 18px;
  font-size: var(--font-size-18);
  font-weight: bolder;
  height: 20px;
  height: var(--dimension-checkbox);
  left: 4px;
  position: absolute;
  top: 0;
  width: 20px;
  width: var(--dimension-checkbox); }

input[type='checkbox']:focus:after,
input[type='checkbox']:active:after {
  left: 6px;
  top: 4px; }

.form-checkbox .inline-button {
  text-decoration: underline; }

.form__form-checkbox:not(:last-child) {
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.form-input input:focus,
.form-input input:active,
.form-select select:focus,
.form-select select:active,
.form__form form.form textarea.standard:focus,
.form__form form.form textarea.standard:active {
  border: calc(2px * 2) solid #27251f;
  border: calc(var(--dimension-border) * 2) solid var(--color-black);
  outline: none; }

.form__form form.form span.value {
  margin-left: 0; }

.form__form-input {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-secondary-faded); }

.form__form-button {
  width: calc(3 / 12 * 100%); }

.form__form-button-button {
  display: block;
  width: 100%; }

.form__form form.form .pd-captcha {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  left: 0;
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  position: relative;
  width: calc(5 / 12 * 100%); }
  @media screen and (min-width: 768px) {
    .form__form form.form .pd-captcha {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form .pd-captcha {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form .pd-captcha {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__form form.form .pd-captcha {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form .pd-captcha {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form .pd-captcha {
      padding-right: calc(80px / 2); } }

.form__form form.form p.no-label {
  margin-left: 0; }

.form__form form.form div.error {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .form__form form.form div.error {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form div.error {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form div.error {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__form form.form div.error {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form div.error {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form div.error {
      padding-right: calc(80px / 2); } }

.form__form form.form p.error,
.form__form form.form div.error {
  color: #ee2537;
  color: var(--color-main); }

.form__form form.form div.error input,
.form__form form.form div.error textarea.standard,
.form__form form.form div.error select {
  border-color: #ee2537;
  border-color: var(--color-main); }

.form__form form.form p.errors {
  background: #ee2537;
  background: var(--color-main); }

.form__before-form {
  padding-bottom: calc(16px / 2);
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18); }
  @media screen and (min-width: 768px) {
    .form__before-form {
      padding-bottom: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__before-form {
      padding-bottom: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__before-form {
      padding-bottom: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__before-form {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__before-form {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__before-form {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__before-form {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__before-form {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__before-form {
      padding-right: calc(80px / 2); } }

.form__after-form a,
.form__after-message a,
.form__before-form a {
  color: #ee2537;
  color: var(--color-main); }
  .form__after-form a:hover,
  .form__after-message a:hover,
  .form__before-form a:hover {
    text-decoration: underline; }

@media screen and (min-width: 768px) {
  .form:not(.form--full-height) iframe {
    height: 70vh;
    overflow: scroll; }
  .form__form--no-right {
    margin-right: 50%; }
  .form__content {
    width: calc((8 / 12) * 100%); }
  .form--catalog,
  iframe#download {
    display: flex; }
    .form--catalog .form__image,
    iframe#download .form__image {
      padding-left: calc(16px + 0px);
      display: block;
      width: calc((2 / 12) * 100%); } }
    @media screen and (min-width: 768px) and (min-width: 768px) {
      .form--catalog .form__image,
      iframe#download .form__image {
        padding-left: calc(32px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1440px) {
      .form--catalog .form__image,
      iframe#download .form__image {
        padding-left: calc(48px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1680px) {
      .form--catalog .form__image,
      iframe#download .form__image {
        padding-left: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
    .form--catalog .form__content,
    iframe#download .form__content {
      width: calc((8 / 12) * 100%); }
  .form__form-column--half {
    width: calc(6 / 12 * 100%); } }
