/* Eric Meyer's Reset CSS v2.0
/ (http://meyerweb.com/eric/tools/css/reset/) http://cssreset.com */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font-style: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: none;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

img {
  display: inline-block;
  height: auto;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  vertical-align: middle;
}

input {
  background: none;
}

input:hover,
input:focus,
input::selection,
input:-webkit-autofill {
  background: none;
  transition: background 5000s ease-in-out 0s;
}

[dir="ltr"] th {
  text-align: left;
}

[dir="rtl"] th {
  text-align: right;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

input[placeholder],
::-moz-placeholder,
input:-moz-placeholder {
  text-overflow: ellipsis;
}

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

/* math */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: normal;
  font-weight: 300 800;
  src: url(/static/media/open-sans-math-300-800.467f697c.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}

/* symbols */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: normal;
  font-weight: 300 800;
  src: url(/static/media/open-sans-symbols-300-800.b505d29c.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}

/* latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: normal;
  font-weight: 300 800;
  src: url(/static/media/open-sans-latin-ext-300-800.9cb88d5b.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: normal;
  font-weight: 300 800;
  src: url(/static/media/open-sans-latin-300-800.ee1b2a15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* math */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: italic;
  font-weight: 300 800;
  src: url(/static/media/open-sans-math-italic-300-800.199bbe49.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}

/* symbols */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: italic;
  font-weight: 300 800;
  src: url(/static/media/open-sans-symbols-italic-300-800.f38024fc.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8B1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5, U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}

/* latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: italic;
  font-weight: 300 800;
  src: url(/static/media/open-sans-latin-ext-italic-300-800.87696ee1.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-style: italic;
  font-weight: 300 800;
  src: url(/static/media/open-sans-latin-italic-300-800.7f0facb9.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: 'Mark';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/Mark-Light.e5a8f607.otf) format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Mark';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/Mark-Book.df9cbfb3.otf) format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Mark';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Mark-Bold.b39198a0.otf) format('opentype');
}
:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.typography {
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-style: normal;
  font-weight: 400;
  font-weight: var(--font-weight-regular); }

.typography--menu,
.typography--breadcrumb,
.typography--body-big,
.typography--heading,
.typography--h1,
.typography--h3 {
  font-family: 'Mark', sans-serif;
  font-family: var(--font-family-headers); }

.typography--italic {
  font-style: italic; }

.typography--heading-lower,
.typography--body-light,
.typography--huge,
.typography--h2,
.typography--heading-light,
.typography--heading-medium,
.typography--breadcrumb {
  font-weight: 300;
  font-weight: var(--font-weight-light); }

.typography--body-small,
.typography--excerpt,
.typography--menu,
.typography--number,
.typography--body-big,
.typography--body-xsmall,
.typography--heading,
.typography--h1 {
  font-weight: 700;
  font-weight: var(--font-weight-bold); }

.typography--heading,
.typography--heading-small,
.typography--menu,
.typography--breadcrumb,
.typography--huge,
.typography--h2,
.typography--h4,
.typography--label,
.typography--body-xsmall-regular,
.typography--heading-light,
.typography--heading-medium {
  text-transform: uppercase; }

.typography--number {
  font-size: 96px;
  font-size: var(--font-size-96);
  line-height: 112px;
  line-height: var(--line-height-for-96); }

.typography--number-small {
  font-size: 48px;
  font-size: var(--font-size-48);
  line-height: 48px;
  line-height: var(--line-height-for-48-smaller); }

.typography--huge,
.typography--h1 {
  font-size: 32px;
  font-size: var(--font-size-32);
  line-height: 48px;
  line-height: var(--line-height-for-32); }

.typography--h2,
.typography--excerpt,
.typography--heading,
.typography--heading-lower,
.typography--body-big {
  line-height: 40px;
  font-size: 24px;
  font-size: var(--font-size-24); }
  @media screen and (min-width: 768px) {
    .typography--h2,
    .typography--excerpt,
    .typography--heading,
    .typography--heading-lower,
    .typography--body-big {
      line-height: 48px; } }

.typography--h3,
.typography--h4,
.typography--body-light {
  font-size: 20px;
  font-size: var(--font-size-20);
  line-height: 40px;
  line-height: var(--line-height-for-20); }

.typography--body,
.typography--body-small,
.typography--body-smaller,
.typography--heading-small,
.typography--footer,
.typography--heading-light,
.typography--heading-medium {
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18); }

.typography--contact,
.typography--body-xsmall,
.typography--body-xsmall-lowercase,
.typography--body-xsmall-regular {
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16); }

.typography--menu,
.typography--label-small {
  font-size: 14px;
  font-size: var(--font-size-14);
  line-height: 24px;
  line-height: var(--line-height-for-14); }

.typography--footer-small,
.typography--label,
.typography--breadcrumb {
  font-size: 14px;
  font-size: var(--font-size-14);
  line-height: 24px;
  line-height: var(--line-height-for-14); }

@media screen and (min-width: 1280px) {
  .typography--label {
    font-size: 16px;
    font-size: var(--font-size-16);
    line-height: 24px;
    line-height: var(--line-height-for-16); } }

@media screen and (min-width: 1440px) {
  .typography--menu, .typography--label-small {
    font-size: 16px;
    font-size: var(--font-size-16);
    line-height: 24px;
    line-height: var(--line-height-for-16); }
  .typography--number {
    font-size: 192px;
    font-size: var(--font-size-192);
    line-height: 225px;
    line-height: var(--line-height-for-192); }
  .typography--huge {
    font-size: 80px;
    font-size: var(--font-size-80);
    line-height: 104px;
    line-height: var(--line-height-for-80); }
  .typography--h1 {
    font-size: 48px;
    font-size: var(--font-size-48);
    line-height: var(--line-height-for-48); }
  .typography--h2 {
    line-height: 48px;
    font-size: 48px;
    font-size: var(--font-size-48); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .typography--h2 {
      line-height: 64px; } }

@media screen and (min-width: 1440px) {
  .typography--heading,
  .typography--heading-lower {
    font-size: 32px;
    font-size: var(--font-size-32);
    line-height: 48px;
    line-height: var(--line-height-for-32); }
  .typography--excerpt {
    font-size: 32px;
    font-size: var(--font-size-32);
    line-height: 48px;
    line-height: var(--line-height-for-32); }
  .typography--h3,
  .typography--heading-medium {
    line-height: 40px;
    font-size: 24px;
    font-size: var(--font-size-24); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .typography--h3,
    .typography--heading-medium {
      line-height: 48px; } }

@media screen and (min-width: 1440px) {
  .typography--body-smaller {
    font-size: 20px;
    font-size: var(--font-size-20);
    line-height: 32px;
    line-height: var(--line-height-for-20-smaller); } }

@media screen and (min-width: 1680px) {
  .typography--h1 {
    font-size: 56px;
    font-size: var(--font-size-64);
    line-height: 72px;
    line-height: var(--line-height-for-64); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inline-button {
  align-items: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  outline: 0;
  position: relative; }

.inline-button:hover .inline-button__content {
  text-decoration: underline; }

.inline-button--inverted {
  color: #fff;
  color: var(--color-secondary); }

.inline-button--boxed::after {
  bottom: 0;
  color: #ee2537;
  color: var(--color-main);
  content: '';
  height: 2px;
  height: var(--dimension-border);
  left: 0;
  position: absolute;
  width: 100%; }

.inline-button--boxed:hover::after {
  height: calc(2px * 2);
  height: calc(var(--dimension-border) * 2); }

.inline-button--inverted.inline-button--boxed::after {
  background-color: #fff;
  background-color: var(--color-secondary); }

.inline-button:disabled {
  opacity: 0.25;
  pointer-events: none; }

.inline-button__content {
  align-items: center;
  color: #ee2537;
  color: var(--color-main);
  display: inline-flex;
  height: 40px;
  height: var(--dimension-inline-button); }

.inline-button__content:not(:first-child) {
  margin-left: calc(16px / 4); }
  @media screen and (min-width: 768px) {
    .inline-button__content:not(:first-child) {
      margin-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .inline-button__content:not(:first-child) {
      margin-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .inline-button__content:not(:first-child) {
      margin-left: calc(80px / 4); } }

@media screen and (min-width: 768px) {
  .inline-button__content {
    white-space: nowrap; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.row {
  margin-left: calc(16px / 2);
  margin-right: calc(16px / 2);
  display: flex;
  flex-flow: row wrap;
  max-width: 1920px;
  max-width: var(--body-max-width); }
  @media screen and (min-width: 768px) {
    .row {
      margin-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .row {
      margin-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .row {
      margin-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .row {
      margin-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .row {
      margin-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .row {
      margin-right: calc(80px / 2); } }

.row .row {
  margin-left: calc(16px / -2);
  margin-left: calc(16px / -2);
  width: calc(16px + 100%); }
  @media screen and (min-width: 768px) {
    .row .row {
      margin-left: calc(32px / -2); } }
  @media screen and (min-width: 1440px) {
    .row .row {
      margin-left: calc(48px / -2); } }
  @media screen and (min-width: 1680px) {
    .row .row {
      margin-left: calc(80px / -2); } }
  @media screen and (min-width: 768px) {
    .row .row {
      margin-left: calc(32px / -2); } }
  @media screen and (min-width: 1440px) {
    .row .row {
      margin-left: calc(48px / -2); } }
  @media screen and (min-width: 1680px) {
    .row .row {
      margin-left: calc(80px / -2); } }
  @media screen and (min-width: 768px) {
    .row .row {
      width: calc(32px + 100%); } }
  @media screen and (min-width: 1440px) {
    .row .row {
      width: calc(48px + 100%); } }
  @media screen and (min-width: 1680px) {
    .row .row {
      width: calc(80px + 100%); } }

/* This is 1920px plus the row gutter, so it keeps the margins until they are needed */
@media screen and (min-width: 2000px) {
  .row {
    margin-left: auto;
    margin-right: auto; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.column {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  max-width: 100%; }
  @media screen and (min-width: 768px) {
    .column {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .column {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .column {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .column {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .column {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .column {
      padding-right: calc(80px / 2); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.error-template {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .error-template {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .error-template {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .error-template {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .error-template {
      margin-bottom: calc(120px + 0px); } }

.error-template__title {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .error-template__title {
      margin-bottom: calc(64px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.iconography {
  display: inline-block;
  fill: #27251f;
  fill: var(--color-black);
  flex-shrink: 0;
  height: 24px;
  height: var(--iconography-size-default);
  width: 24px;
  width: var(--iconography-size-default); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.picture {
  overflow: hidden;
  position: relative; }

.picture picture {
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.3s;
  transition: opacity var(--transition-timing-default) ease-in-out var(--transition-timing-default); }

.picture--mounted picture {
  opacity: 0; }

.picture--mounted .picture__preload {
  opacity: 1; }

.picture--loaded picture {
  opacity: 1; }

.picture__preload {
  display: flex;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  height: 100%;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out 0.5s;
  width: 100%;
  z-index: 10;
  z-index: var(--z-index-picture); }

.picture--loaded .picture__preload {
  opacity: 0; }

.picture[class*='picture--ratio-'] picture::before {
  content: '';
  display: block;
  height: 0;
  position: relative; }

.picture[class*='picture--ratio-'] img {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.picture--ratio-533-409 picture::before {
  padding-bottom: calc(409 / 533 * 100%);
  padding-bottom: calc(var(--aspect-ratio-533-409) * 100%); }

.picture--ratio-24-19 picture::before {
  padding-bottom: calc(19 / 24 * 100%);
  padding-bottom: calc(var(--aspect-ratio-24-19) * 100%); }

.picture--ratio-16-9 picture::before {
  padding-bottom: calc(9 / 16 * 100%);
  padding-bottom: calc(var(--aspect-ratio-16-9) * 100%); }

.picture--ratio-8-3 picture::before {
  padding-bottom: calc(3 / 8 * 100%);
  padding-bottom: calc(var(--aspect-ratio-8-3) * 100%); }

.picture--ratio-4-3 picture::before {
  padding-bottom: calc(3 / 4 * 100%);
  padding-bottom: calc(var(--aspect-ratio-4-3) * 100%); }

.picture--ratio-3-2 picture::before {
  padding-bottom: calc(2 / 3 * 100%);
  padding-bottom: calc(var(--aspect-ratio-3-2) * 100%); }

.picture--ratio-1-1 picture::before {
  padding-bottom: calc(1 / 1 * 100%);
  padding-bottom: calc(var(--aspect-ratio-1-1) * 100%); }

.picture--ratio-5-4 picture::before {
  padding-bottom: calc(4 / 5 * 100%);
  padding-bottom: calc(var(--aspect-ratio-5-4) * 100%); }

.picture__caption {
  margin-top: calc(48px / 4); }
  @media screen and (min-width: 1280px) {
    .picture__caption {
      margin-top: calc(64px / 4); } }

.picture__image--cover {
  object-fit: cover;
  object-position: center;
  min-width: 100%;
  min-height: 100%; }

.picture__picture--cover {
  height: 100%;
  width: 100%; }

.picture__image--contain {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
  max-width: 100%; }

.picture__picture--contain {
  height: 100%;
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.input {
  position: relative; }

.input__input {
  padding: calc(16px / 2);
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black);
  color: #27251f;
  color: var(--color-black);
  font-size: 20px;
  font-size: var(--font-size-20);
  height: 64px;
  height: var(--dimension-input);
  line-height: 32px;
  line-height: var(--line-height-for-20-smaller);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .input__input {
      padding: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .input__input {
      padding: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .input__input {
      padding: calc(80px / 2); } }

.input--secondary .input__input {
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black); }

.input__label,
.input__input {
  color: #27251f;
  color: var(--color-black); }

.input--secondary .input__label {
  color: #27251f;
  color: var(--color-black);
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall); }

.input--secondary .input__input {
  color: #27251f;
  color: var(--color-black); }

.input__input:focus,
.input__input:active {
  border: calc(2px * 2) solid #27251f;
  border: calc(var(--dimension-border) * 2) solid var(--color-black);
  outline: none; }

.input--secondary .input__input:focus,
.input--secondary .input__input:active {
  border: calc(2px * 2) solid #27251f;
  border: calc(var(--dimension-border) * 2) solid var(--color-black);
  outline: none; }

.input__icon {
  right: calc(16px / 2);
  cursor: pointer;
  height: 32px;
  height: var(--iconography-search);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 32px;
  width: var(--iconography-search); }
  @media screen and (min-width: 768px) {
    .input__icon {
      right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .input__icon {
      right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .input__icon {
      right: calc(80px / 2); } }

.input .input__input::-moz-selection {
  background-color: #d7d2cb;
  background-color: var(--color-timberwolf); }

.input .input__input::selection {
  background-color: #d7d2cb;
  background-color: var(--color-timberwolf); }

@media screen and (min-width: 768px) {
  .input__input {
    padding: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .input__input {
      padding: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .input__input {
      padding: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .input__input {
      padding: calc(80px / 4); } }

@media screen and (min-width: 1280px) {
  .input__icon {
    right: calc(16px / 4); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .input__icon {
      right: calc(32px / 4); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .input__icon {
      right: calc(48px / 4); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .input__icon {
      right: calc(80px / 4); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.search-box {
  margin: auto 0;
  width: 100%; }

.search-box__close {
  top: calc(80px / 2);
  right: calc(16px + 0px);
  cursor: pointer;
  height: 32px;
  height: var(--iconography-search);
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 32px;
  width: var(--iconography-search); }
  @media screen and (min-width: 1280px) {
    .search-box__close {
      top: calc(110px / 2); } }
  @media screen and (min-width: 1680px) {
    .search-box__close {
      top: calc(120px / 2); } }
  @media screen and (min-width: 768px) {
    .search-box__close {
      right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-box__close {
      right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .search-box__close {
      right: calc(80px + 0px); } }

.search-box__input .input__input {
  background: #fff;
  background: var(--color-secondary); }

@media screen and (min-width: 1280px) {
  .search-box {
    margin: auto; }
  .search-box__input {
    margin: 0 auto;
    width: 40%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.header--menu-open .header__language-selector {
  display: none; }

.header__language-selector--mobile {
  display: flex;
  justify-content: flex-end; }

.header__language-selector {
  padding-right: calc(16px / 4);
  padding-left: calc(16px / 4);
  cursor: pointer;
  padding: 0;
  position: relative;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) {
    .header__language-selector {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .header__language-selector {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .header__language-selector {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) {
    .header__language-selector {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .header__language-selector {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .header__language-selector {
      padding-left: calc(80px / 4); } }

.header__language-selector:hover,
.header__language-selector:active {
  background-color: #fff;
  background-color: var(--color-secondary); }

.header__language-selector:hover .header__language-selector-options,
.header__language-selector:active .header__language-selector-options {
  display: block; }

.header__language-selector-option:hover {
  color: #ee2537;
  color: var(--color-main); }

.header__language-selector-selected {
  align-items: center;
  display: flex; }

.header__language-selector-selected:hover {
  color: #ee2537;
  color: var(--color-main); }

.header__language-selector-selected:hover svg {
  fill: #ee2537;
  fill: var(--color-main); }

.header__language-selector .landing-icon {
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }

.header__language-selector:hover .landing-icon,
.header__language-selector:active .landing-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.header__language-selector-options {
  background: #fff;
  background: var(--color-secondary);
  display: none;
  left: 0;
  padding-bottom: 8px;
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: 15;
  z-index: var(--z-index-languages); }

.header__language-selector-option {
  padding-left: calc(16px / 4);
  display: flex; }
  @media screen and (min-width: 768px) {
    .header__language-selector-option {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .header__language-selector-option {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .header__language-selector-option {
      padding-left: calc(80px / 4); } }

.header-menu .header__language-selector-option:not(:first-child) {
  padding-left: 15px; }

.hheader-menu .header__language-selector-option:not(:last-child) {
  padding-right: 15px; }

.header__language-selector .iconography {
  transition: all 0.3s ease;
  transition: all var(--transition-timing-default) ease; }

.header__language-selector:hover .iconography {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.header__language-selector-option--selected {
  display: none; }

.header__language-selector-current {
  text-decoration: underline; }

@media screen and (min-width: 1280px) {
  .header__language-selector-option:not(.header__language-selector-option--selected) {
    margin-top: 10px; } }

.full-row {
  width: 100%;
}

.full-row--inverted {
  background-color: var(--color-secondary);
}

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.header-menu-mobile {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  top: calc(80px + 0px);
  padding-bottom: calc(80px + 0px);
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  padding-top: 32px;
  padding-top: var(--space-between-small);
  background-color: #fff;
  background-color: var(--landing-color-white);
  opacity: 0;
  -webkit-animation: header_menu_mobile_item_fade_in 150ms ease-in forwards;
          animation: header_menu_mobile_item_fade_in 150ms ease-in forwards; }
  @media screen and (min-width: 768px) {
    .header-menu-mobile {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .header-menu-mobile {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header-menu-mobile {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .header-menu-mobile {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .header-menu-mobile {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header-menu-mobile {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) {
    .header-menu-mobile {
      top: calc(110px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header-menu-mobile {
      top: calc(120px + 0px); } }
  @media screen and (min-width: 1280px) {
    .header-menu-mobile {
      padding-bottom: calc(110px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header-menu-mobile {
      padding-bottom: calc(120px + 0px); } }

.header-menu-mobile__header {
  display: flex;
  align-items: center;
  margin-bottom: 1em; }

.header__language-selector--mobile {
  margin-left: auto; }

.header-menu-mobile__items-wrapper {
  display: flex;
  flex-direction: column; }

.header-menu-mobile__item {
  width: 100%; }

.header-menu-mobile__item--link {
  font-size: 14px;
  font-size: var(--font-size-14);
  line-height: 24px;
  line-height: var(--line-height-for-14);
  display: flex;
  padding: 1em 0;
  min-height: 4em;
  align-items: center; }

.header-menu-mobile__item-header {
  font-size: 14px;
  font-size: var(--font-size-14);
  line-height: 24px;
  line-height: var(--line-height-for-14);
  padding: 1em 0;
  min-height: 4em;
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer; }
  .header-menu-mobile__item-header .landing-icon {
    --size: 2em;
    margin-left: auto; }

.header-menu-mobile__item--featured {
  color: #ee2537;
  color: var(--color-main); }

.header-menu-mobile__item--selected,
.header-menu-mobile__subitem--featured {
  color: #ee2537;
  color: var(--color-main); }
  .header-menu-mobile__item--selected svg,
  .header-menu-mobile__subitem--featured svg {
    fill: #ee2537;
    fill: var(--color-main); }

.header-menu-mobile__item--active .landing-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.header-menu-mobile__item-animation-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 200ms ease;
  transition: grid-template-rows 200ms ease, -ms-grid-rows 200ms ease; }

.header-menu-mobile__item.header-menu-mobile__item--active .header-menu-mobile__item-animation-wrapper {
  grid-template-rows: 1fr; }

.header-menu-mobile__item.header-menu-mobile__item--active .header-menu-mobile__item-transform-wrapper {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  transition: visibility 0s linear, -webkit-transform 300ms ease;
  transition: transform 300ms ease, visibility 0s linear;
  transition: transform 300ms ease, visibility 0s linear, -webkit-transform 300ms ease; }

.header-menu-mobile__item.header-menu-mobile__item--active .header-menu-mobile__item-content {
  -webkit-animation: header_menu_mobile_item_fade_in 300ms 200ms forwards;
          animation: header_menu_mobile_item_fade_in 300ms 200ms forwards; }

.header-menu-mobile__item-animation {
  min-height: 0; }

.header-menu-mobile__item-transform-wrapper {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  visibility: hidden;
  transition: visibility 0s var(--transitionLength) var(--transitionTiming), -webkit-transform var(--transitionLength) var(--transitionTiming);
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming), -webkit-transform var(--transitionLength) var(--transitionTiming); }

.header-menu-mobile__item-content {
  padding: 0em 0 2em 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  grid-gap: calc(0.33rem * 2);
  gap: calc(0.33rem * 2);
  grid-gap: calc(var(--s) * 2);
  gap: calc(var(--s) * 2); }

.header-menu-mobile__subitem a {
  text-decoration: none;
  font-variation-settings: 'wght' 400; }

.header-menu-mobile__submenu-typo {
  font-weight: 400; }

@-webkit-keyframes header_menu_mobile_item_fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes header_menu_mobile_item_fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.grid {
  display: flex; }

.grid__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.main-menu-child {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall);
  position: relative; }

.main-menu-child .grid__item {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.main-menu-child__grid {
  position: relative; }

.main-menu-child__grid-item-link:hover {
  color: #ee2537;
  color: var(--color-main); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.main-menu {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  background: #fff;
  background: var(--color-secondary);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--box-shadow);
  max-height: calc(100vh - 80px);
  overflow: auto;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .main-menu {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .main-menu {
      padding-bottom: calc(48px + 0px); } }

.main-menu__close {
  right: calc(16px + 0px);
  cursor: pointer;
  position: absolute;
  top: 24px;
  top: var(--space-between-xsmall);
  z-index: 9999999;
  z-index: var(--z-index-modal); }
  @media screen and (min-width: 768px) {
    .main-menu__close {
      right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .main-menu__close {
      right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .main-menu__close {
      right: calc(80px + 0px); } }

.main-menu__grid {
  max-width: 100vw; }

.main-menu__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.main-menu__item-selected .main-menu__item-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.main-menu__link {
  display: flex;
  flex-direction: row; }
  .main-menu__link--featured {
    color: #ee2537;
    color: var(--color-main); }

.main-menu__link:hover {
  color: #ee2537;
  color: var(--color-main); }
  .main-menu__link:hover .main-menu__item-icon {
    fill: #ee2537;
    fill: var(--color-main); }

.main-menu__item-icon {
  margin: auto 0; }

.main-menu__item-title {
  cursor: pointer;
  float: left; }

.main-menu__item-title:hover {
  color: #ee2537;
  color: var(--color-main); }

@media screen and (min-width: 1280px) {
  max-height: calc(100vh - 110px); }

@media screen and (min-width: 1440px) {
  max-height: calc(100vh - 120px); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.header {
  height: calc(80px + 0px);
  background: #fff;
  background: var(--color-secondary);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  z-index: var(--z-index-header);
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }
  @media screen and (min-width: 1280px) {
    .header {
      height: calc(110px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header {
      height: calc(120px + 0px); } }

.header--scrolled {
  height: calc(80px * 0.67);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--box-shadow); }
  @media screen and (min-width: 1280px) {
    .header--scrolled {
      height: calc(110px * 0.67); } }
  @media screen and (min-width: 1680px) {
    .header--scrolled {
      height: calc(120px * 0.67); } }

.header__wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between; }

.header__logo {
  padding-right: calc(16px / 2);
  padding-left: calc(16px + 0px);
  width: 50%; }
  @media screen and (min-width: 768px) {
    .header__logo {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .header__logo {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .header__logo {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .header__logo {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .header__logo {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header__logo {
      padding-left: calc(80px + 0px); } }
  .header__logo a {
    width: 100%; }
  .header__logo img {
    height: calc(80px * 0.65);
    width: auto;
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }
    @media screen and (min-width: 1280px) {
      .header__logo img {
        height: calc(110px * 0.65); } }
    @media screen and (min-width: 1680px) {
      .header__logo img {
        height: calc(120px * 0.65); } }

.header__logo-link {
  width: 100%; }

.header--scrolled .header__logo {
  width: 45%; }
  .header--scrolled .header__logo img {
    height: calc(80px * 0.47); }
    @media screen and (min-width: 1280px) {
      .header--scrolled .header__logo img {
        height: calc(110px * 0.47); } }
    @media screen and (min-width: 1680px) {
      .header--scrolled .header__logo img {
        height: calc(120px * 0.47); } }

.header__logo,
.header__links,
.header__language-selector,
.header__search,
.header__menu {
  align-items: center;
  display: inline-flex;
  margin: auto 0; }

.header__links-item:hover {
  color: #ee2537;
  color: var(--color-main); }

.header__links-item--selected {
  color: #ee2537;
  color: var(--color-main); }

.header__search {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .header__search {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .header__search {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header__search {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .header__search {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .header__search {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .header__search {
      padding-left: calc(80px / 2); } }

.header__search--mobile {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end; }

.header__search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }
  .header__search-icon:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }
  .header__search-icon .landing-icon {
    --color: var(--landing-color-text);
    --size: 32px; }

.header__search-icon:hover {
  fill: #ee2537;
  fill: var(--color-main); }

.header__menu {
  padding-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .header__menu {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .header__menu {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .header__menu {
      padding-right: calc(80px + 0px); } }

.header__menu-icon {
  height: 32px;
  height: var(--iconography-search);
  width: 32px;
  width: var(--iconography-search); }

.header__search-bar {
  background: #fff;
  background: var(--color-secondary);
  margin: auto 0;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  width: 100%; }

.header__search-input {
  width: 70%; }

.header__links {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  grid-gap: 2em;
  gap: 2em; }

.header__links-item {
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  text-align: center;
  cursor: pointer; }

@media screen and (min-width: 768px) {
  .header__logo {
    max-width: none;
    width: calc(1.2 / 3 * 100%); }
  .header--scrolled .header__logo {
    width: calc(0.9 / 3 * 100%); } }

@media screen and (min-width: 1024px) {
  .header__logo {
    width: 25%; }
  .header--scrolled .header__logo {
    width: 20%; } }

@media screen and (min-width: 1280px) {
  .header__logo {
    width: calc(2.5 / 9 * 100%); }
  .header--scrolled .header__logo {
    width: calc(2 / 9 * 100%); }
  .header__search-bar {
    margin: auto; }
  .header__search-input {
    margin: 0 auto;
    width: 40%; }
  .landing-button.header__contact {
    font-size: 14px;
    font-size: var(--font-size-14);
    line-height: 24px;
    line-height: var(--line-height-for-14); } }

@media screen and (min-width: 1440px) {
  .landing-button.header__contact {
    font-size: 16px;
    font-size: var(--font-size-16);
    line-height: 24px;
    line-height: var(--line-height-for-16); } }

@media screen and (min-width: 1920px) {
  .header__logo {
    width: calc(2 / 9 * 100%); }
  .header--scrolled .header__logo {
    width: calc(1.8 / 9 * 100%); }
  .header__logo img {
    max-width: 312px;
    max-width: var(--header-logo-max-width); } }

.header__contact-col {
  display: flex;
  align-items: center;
  justify-content: center; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: 300;
  font-weight: var(--font-weight-light);
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.content h1 {
  font-size: 40px;
  font-size: var(--font-size-40);
  line-height: 64px;
  line-height: var(--line-height-for-40); }

.content h2 {
  font-size: 32px;
  font-size: var(--font-size-32);
  line-height: 48px;
  line-height: var(--line-height-for-32); }

.content h3,
.content h4,
.content h5 {
  font-size: 20px;
  font-size: var(--font-size-20);
  line-height: 40px;
  line-height: var(--line-height-for-20); }

.content h6 {
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16); }

.content dd:not(:last-child),
.content ul:not(:last-child),
.content ol:not(:last-child),
.content p:not(:last-child) {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.content strong {
  font-weight: 700;
  font-weight: var(--font-weight-bold); }

.content em {
  font-style: italic; }

.content dt {
  font-size: 20px;
  font-size: var(--font-size-20);
  line-height: 40px;
  line-height: var(--line-height-for-20);
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.content hr {
  border: 2px solid #fff;
  border: var(--dimension-border) solid var(--color-secondary);
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall);
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.content a {
  color: #ee2537;
  color: var(--color-main);
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall); }

.content a:hover {
  border-bottom-width: 2px;
  border-bottom-width: var(--dimension-border);
  color: #ee2537;
  color: var(--color-main); }

.content ul {
  padding-left: calc(16px + 0px);
  list-style-type: disc;
  list-style-type: initial; }
  @media screen and (min-width: 768px) {
    .content ul {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .content ul {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .content ul {
      padding-left: calc(80px + 0px); } }

.content ul ul {
  list-style-type: circle; }

.content ul ul ul {
  list-style-type: square; }

.content li {
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.content ol {
  padding-left: calc(16px / 4);
  list-style-type: decimal; }
  @media screen and (min-width: 768px) {
    .content ol {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .content ol {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .content ol {
      padding-left: calc(80px / 4); } }

@media screen and (min-width: 1440px) {
  .content h1 {
    font-size: 48px;
    font-size: var(--font-size-48);
    line-height: 48px;
    line-height: var(--font-size-48); }
  .content h3,
  .content h4,
  .content h5 {
    line-height: 40px;
    font-size: 24px;
    font-size: var(--font-size-24); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .content h3,
    .content h4,
    .content h5 {
      line-height: 48px; } }

@media screen and (min-width: 1440px) {
  .content h6 {
    font-size: 18px;
    font-size: var(--font-size-18);
    line-height: 24px;
    line-height: var(--line-height-for-18); }
  .content dt {
    line-height: 40px;
    font-size: 24px;
    font-size: var(--font-size-24); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .content dt {
      line-height: 48px; } }

@media screen and (min-width: 1440px) {
  .content ul {
    padding-left: calc(16px / 2); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .content ul {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .content ul {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1440px) and (min-width: 1680px) {
    .content ul {
      padding-left: calc(80px / 2); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.button {
  min-height: calc(56px + 0px);
  padding-right: calc(16px / 4);
  padding-left: calc(16px / 4);
  align-items: center;
  background-color: transparent;
  border: 2px solid #ee2537;
  border: var(--dimension-border) solid var(--color-main);
  box-sizing: border-box;
  color: #ee2537;
  color: var(--color-main);
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  outline: none;
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: 8px;
  padding-top: var(--space-between-xxxsmall);
  position: relative;
  transition: 0.3s;
  transition: var(--transition-timing-default); }
  @media screen and (min-width: 1440px) {
    .button {
      min-height: calc(64px + 0px); } }
  @media screen and (min-width: 768px) {
    .button {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .button {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .button {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) {
    .button {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .button {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .button {
      padding-left: calc(80px / 4); } }

.button:hover {
  color: #fff;
  color: var(--color-secondary); }

.button--secondary {
  border: 2px solid #fff;
  border: var(--dimension-border) solid var(--color-secondary);
  color: #fff;
  color: var(--color-secondary); }

.button--secondary:hover {
  color: #ee2537;
  color: var(--color-main); }

.button::after {
  background: #ee2537;
  background: var(--color-main);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform var(--transition-timing-faster) ease-in-out;
  transition: transform var(--transition-timing-faster) ease-in-out;
  transition: transform var(--transition-timing-faster) ease-in-out, -webkit-transform var(--transition-timing-faster) ease-in-out;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.button--secondary::after {
  background: #fff;
  background: var(--color-secondary);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out, -webkit-transform var(--transition-timing-default) ease-in-out;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.button:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.button svg {
  fill: #ee2537;
  fill: var(--color-main); }

.button:hover svg {
  fill: #fff;
  fill: var(--color-secondary); }

.button--disabled:hover {
  background-color: transparent;
  color: rgba(37, 37, 31, 0.5);
  color: var(--color-black-faded); }

.button--disabled svg {
  fill: rgba(37, 37, 31, 0.5);
  fill: var(--color-black-faded); }

.button--secondary:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.button--secondary svg {
  fill: #fff;
  fill: var(--color-secondary); }

.button--secondary.button--disabled {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border: var(--dimension-border) solid var(--color-secondary-faded);
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-secondary-faded); }

.button--secondary:hover svg {
  fill: #ee2537;
  fill: var(--color-main); }

.button--secondary:hover.button--disabled {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-secondary-faded); }

.button--disabled {
  background-color: transparent;
  border: 2px solid rgba(37, 37, 31, 0.5);
  border: var(--dimension-border) solid var(--color-black-faded);
  color: rgba(37, 37, 31, 0.5);
  color: var(--color-black-faded);
  cursor: auto;
  pointer-events: none; }

.button--secondary.button--disabled svg {
  fill: rgba(255, 255, 255, 0.5);
  fill: var(--color-secondary-faded); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.prefooter-mobile {
  display: inline-flex;
  position: relative;
  width: 100%; }

.prefooter-mobile__title-container {
  border: none;
  border-right: 2px solid #27251f;
  border-right: var(--dimension-border) solid var(--color-black);
  padding: 0; }

.prefooter-mobile__title {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  align-items: center;
  color: #27251f;
  color: var(--color-black);
  display: flex;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .prefooter-mobile__title {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__title {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .prefooter-mobile__title {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .prefooter-mobile__title {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__title {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .prefooter-mobile__title {
      padding-left: calc(80px + 0px); } }

.prefooter-mobile__title .typography {
  display: flex;
  flex: 1; }

.prefooter-mobile__anchor {
  min-width: calc(56px + 0px);
  align-items: center;
  display: flex;
  justify-content: center; }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__anchor {
      min-width: calc(64px + 0px); } }

.prefooter-mobile__menu {
  top: calc(56px + 0px);
  background: #fff;
  background: var(--color-secondary);
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black);
  position: absolute;
  width: 100%;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__menu {
      top: calc(64px + 0px); } }

.prefooter-mobile__menu-item {
  height: calc(56px + 0px);
  align-items: center;
  display: flex;
  width: 100%; }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__menu-item {
      height: calc(64px + 0px); } }

.prefooter-mobile__menu-item > .typography {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .prefooter-mobile__menu-item > .typography {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__menu-item > .typography {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .prefooter-mobile__menu-item > .typography {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .prefooter-mobile__menu-item > .typography {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .prefooter-mobile__menu-item > .typography {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .prefooter-mobile__menu-item > .typography {
      padding-left: calc(80px + 0px); } }

@media screen and (min-width: 1024px) {
  .prefooter-mobile__menu {
    display: flex;
    justify-content: space-between; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.prefooter {
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black);
  color: #27251f;
  color: var(--color-black);
  position: relative; }

.prefooter__grid {
  height: 100%; }

.prefooter .grid__item {
  display: flex; }

.prefooter__menu-title {
  font-weight: normal; }

.prefooter__menu-title,
.prefooter__menu-items {
  align-items: center;
  display: flex; }

.prefooter__menu-items:hover {
  color: #ee2537;
  color: var(--color-main); }

.prefooter__anchor {
  width: calc(56px + 0px);
  align-items: center;
  border-left: 2px solid #27251f;
  border-left: var(--dimension-border) solid var(--color-black);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0; }
  @media screen and (min-width: 1440px) {
    .prefooter__anchor {
      width: calc(64px + 0px); } }

.prefooter__anchor:hover {
  background: #ee2537;
  background: var(--color-main); }

.prefooter__anchor:hover svg {
  fill: #fff;
  fill: var(--color-secondary); }

@media screen and (min-width: 1280px) {
  .prefooter {
    height: calc(56px + 0px);
    justify-content: space-between; } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .prefooter {
      height: calc(64px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.animated-inline-button {
  color: #ee2537;
  color: var(--color-main);
  display: inline-block;
  position: relative;
  transition: all 0.2s;
  transition: all var(--transition-timing-faster); }

.animated-inline-button--inverted {
  color: #fff;
  color: var(--color-secondary); }

.animated-inline-button__arrow {
  transition: all 0.2s;
  transition: all var(--transition-timing-faster);
  width: 60px;
  width: var(--iconography-arrow); }

.animated-inline-button__arrow > .animated-inline-button__shaft {
  background-color: #ee2537;
  background-color: var(--color-main);
  display: block;
  height: 2px;
  height: var(--dimension-border);
  position: relative;
  transition: all 0.2s;
  transition: all var(--transition-timing-faster);
  transition-delay: 0;
  will-change: transform; }

/* stylelint-disable */
.animated-inline-button--inverted .animated-inline-button__arrow > .animated-inline-button__shaft,
.animated-inline-button--inverted
.animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft,
.animated-inline-button--inverted .animated-inline-button__arrow > .animated-inline-button__shaft::before,
.animated-inline-button--inverted .animated-inline-button__arrow > .animated-inline-button__shaft::after,
.animated-inline-button--inverted
.animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft::before,
.animated-inline-button--inverted
.animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft::after {
  background-color: #fff;
  background-color: var(--color-secondary); }

.animated-inline-button__arrow.animated-inline-button__arrow--left {
  left: 0;
  position: absolute;
  top: 50%; }

.animated-inline-button__arrow.animated-inline-button__arrow--left > .animated-inline-button__shaft {
  background-color: #ee2537;
  background-color: var(--color-main);
  width: 0; }

.animated-inline-button__arrow > .animated-inline-button__shaft::before,
.animated-inline-button__arrow > .animated-inline-button__shaft::after {
  background-color: #ee2537;
  background-color: var(--color-main);
  content: '';
  display: block;
  height: 2px;
  height: var(--dimension-border);
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
  transition: all var(--transition-timing-faster);
  transition-delay: 0; }

.animated-inline-button__arrow > .animated-inline-button__shaft::before {
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.animated-inline-button__arrow.animated-inline-button__arrow--left > .animated-inline-button__shaft::before,
.animated-inline-button__arrow.animated-inline-button__arrow--left > .animated-inline-button__shaft::after {
  background-color: #ee2537;
  background-color: var(--color-main);
  width: 0; }

.animated-inline-button__arrow.animated-inline-button__arrow--left > .animated-inline-button__shaft::before,
.animated-inline-button__arrow.animated-inline-button__arrow--left > .animated-inline-button__shaft::after,
.animated-inline-button:hover
> .animated-inline-button__main
> .animated-inline-button__arrow.animated-inline-button__arrow--right
> .animated-inline-button__shaft::before,
.animated-inline-button:hover
> .animated-inline-button__main
> .animated-inline-button__arrow.animated-inline-button__arrow--right
> .animated-inline-button__shaft::after {
  -webkit-transform: rotate(0);
          transform: rotate(0); }

.animated-inline-button__arrow > .animated-inline-button__shaft::after {
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right; }

.animated-inline-button > .animated-inline-button__main {
  align-items: center;
  display: flex;
  transition: all 0.2s;
  transition: all var(--transition-timing-faster); }

.animated-inline-button > .animated-inline-button__main > .animated-inline-button__text {
  margin-right: 16px;
  margin-right: var(--space-between-xxsmall); }

.animated-inline-button > .animated-inline-button__main > .animated-inline-button__arrow {
  position: relative; }

.animated-inline-button__arrow.animated-inline-button__arrow--right > .animated-inline-button__shaft {
  transition-delay: 0.2s;
  transition-delay: var(--transition-timing-faster);
  width: 60px;
  width: var(--iconography-arrow); }

.animated-inline-button__arrow.animated-inline-button__arrow--right > .animated-inline-button__shaft::before,
.animated-inline-button__arrow.animated-inline-button__arrow--right > .animated-inline-button__shaft::after {
  transition: all 0.5s;
  transition-delay: 0.2s;
  transition-delay: var(--transition-timing-faster);
  width: 8px;
  width: var(--iconography-arrow-head); }

.animated-inline-button__arrow.animated-inline-button__arrow--right > .animated-inline-button__shaft::before,
.animated-inline-button:hover
> .animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft::before {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg); }

.animated-inline-button__arrow.animated-inline-button__arrow--right > .animated-inline-button__shaft::after,
.animated-inline-button:hover
> .animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft::after {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg); }

/* Hover */
.animated-inline-button:hover
> .animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft {
  transition-delay: 0.1s;
  width: 60px;
  width: var(--iconography-arrow); }

.animated-inline-button:hover
> .animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft::before,
.animated-inline-button:hover
> .animated-inline-button__arrow.animated-inline-button__arrow--left
> .animated-inline-button__shaft::after {
  transition-delay: 0.1s;
  width: 8px;
  width: var(--iconography-arrow-head); }

.animated-inline-button:hover > .animated-inline-button__main {
  -webkit-transform: translateX(80px);
          transform: translateX(80px); }

.animated-inline-button:hover
> .animated-inline-button__main
> .animated-inline-button__arrow.animated-inline-button__arrow--right
> .animated-inline-button__shaft {
  -webkit-transform: translateX(200%);
          transform: translateX(200%);
  transition-delay: 0;
  width: 0; }

.animated-inline-button:hover
> .animated-inline-button__main
> .animated-inline-button__arrow.animated-inline-button__arrow--right
> .animated-inline-button__shaft::before,
.animated-inline-button:hover
> .animated-inline-button__main
> .animated-inline-button__arrow.animated-inline-button__arrow--right
> .animated-inline-button__shaft::after {
  transition: all 0.1s;
  transition-delay: 0;
  width: 0; }

/* stylelint-enable */

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.modal {
  padding: calc(16px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--box-shadow);
  color: #27251f;
  color: var(--color-black);
  height: auto;
  margin: auto;
  max-width: 1920px;
  max-width: var(--body-max-width);
  overflow-y: scroll;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  z-index: 9999999;
  z-index: var(--z-index-modal); }
  @media screen and (min-width: 768px) {
    .modal {
      padding: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .modal {
      padding: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .modal {
      padding: calc(80px + 0px); } }
  .modal.modal--full {
    height: 100%; }

.modal-close {
  cursor: pointer;
  float: right; }

.modal--opened {
  overflow: hidden; }

.modal:focus {
  outline: none; }

.modal__body {
  height: 100%; }

@media screen and (min-width: 1024px) {
  .modal {
    max-height: 100vh;
    overflow-y: auto; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.loader {
  margin-top: calc(120px + 0px);
  margin-bottom: calc(120px + 0px);
  text-align: center;
  width: 100%; }
  @media screen and (min-width: 1440px) {
    .loader {
      margin-top: calc(240px + 0px); } }
  @media screen and (min-width: 1440px) {
    .loader {
      margin-bottom: calc(240px + 0px); } }

.loader--small {
  margin-top: calc(48px + 0px);
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .loader--small {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .loader--small {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .loader--small {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .loader--small {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .loader--small {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .loader--small {
      margin-bottom: calc(80px + 0px); } }

.loader-dots {
  display: inline-block;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 80px; }

.loader-dots div {
  -webkit-animation: loader 1.2s linear infinite;
          animation: loader 1.2s linear infinite;
  background: #ee2537;
  background: var(--color-main);
  border-radius: 50%;
  height: 16px;
  position: absolute;
  width: 16px; }

.loader-dots div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  left: 8px;
  top: 8px; }

.loader-dots div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  left: 32px;
  top: 8px; }

.loader-dots div:nth-child(3) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  left: 56px;
  top: 8px; }

.loader-dots div:nth-child(4) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  left: 8px;
  top: 32px; }

.loader-dots div:nth-child(5) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  left: 32px;
  top: 32px; }

.loader-dots div:nth-child(6) {
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  left: 56px;
  top: 32px; }

.loader-dots div:nth-child(7) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  left: 8px;
  top: 56px; }

.loader-dots div:nth-child(8) {
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  left: 32px;
  top: 56px; }

.loader-dots div:nth-child(9) {
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  left: 56px;
  top: 56px; }

@-webkit-keyframes loader {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

@keyframes loader {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.form {
  height: 100%;
  width: 100%; }
  .form:not(.form--full-height) iframe {
    height: 86vh;
    overflow: scroll; }

.form--catalog .form__image {
  display: none; }

.form form.form {
  padding: 0; }

.form .form__form {
  padding-top: 0; }

.form__title {
  margin-left: calc(16px + 0px);
  color: #27251f;
  color: var(--color-black);
  display: block;
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }
  @media screen and (min-width: 768px) {
    .form__title {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .form__title {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .form__title {
      margin-left: calc(80px + 0px); } }

.form--catalog .form__title {
  margin-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .form--catalog .form__title {
      margin-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form--catalog .form__title {
      margin-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form--catalog .form__title {
      margin-left: calc(80px / 2); } }

.form__form {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  opacity: 1; }
  @media screen and (min-width: 768px) {
    .form__form {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__form {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form {
      padding-right: calc(80px / 2); } }

.form__form-column--full,
.form__form-column--half {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall);
  width: 100%; }

.form .required label:after {
  content: ' *'; }

.form__form form.form input.text {
  width: 100%; }

.form__form form.form input,
.form__form form.form textarea.standard {
  padding: calc(16px / 4); }
  @media screen and (min-width: 768px) {
    .form__form form.form input,
    .form__form form.form textarea.standard {
      padding: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form input,
    .form__form form.form textarea.standard {
      padding: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form input,
    .form__form form.form textarea.standard {
      padding: calc(80px / 4); } }

.form-input input {
  height: 64px;
  height: var(--dimension-input); }

.form__form form.form textarea.standard {
  background: transparent;
  height: 160px;
  height: var(--dimension-textarea);
  outline: none;
  width: 100%; }

.form-input input,
.form-select select,
.form__form form.form textarea.standard {
  padding: calc(16px / 4);
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black);
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }
  @media screen and (min-width: 768px) {
    .form-input input,
    .form-select select,
    .form__form form.form textarea.standard {
      padding: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form-input input,
    .form-select select,
    .form__form form.form textarea.standard {
      padding: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form-input input,
    .form-select select,
    .form__form form.form textarea.standard {
      padding: calc(80px / 4); } }

.form-select {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.form-select select {
  padding-left: calc(16px / 4);
  -webkit-appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAH5JREFUSA3tUFsKgDAMG+JtvI9ep5fxlAqaMALTDeajny1kj6RrRlKKigQigV4CU68B+pOe5hgDuwFzU80kNfZYvtbrUFMXZsRtBVom5Kix53MZXh7ADiyAimdy1Az4VYbXpYnrcP2sNHH7uYZrl4lLLBp632lCREUCkcCLBE4Yjxo1l6isZAAAAABJRU5ErkJggg==) 97% no-repeat transparent;
  border: 2px solid #27251f;
  border: var(--dimension-border) solid var(--color-black);
  border-radius: 0;
  font-size: 18px;
  font-size: var(--font-size-18);
  height: 64px;
  height: var(--dimension-input);
  line-height: 24px;
  line-height: var(--line-height-for-18);
  outline: none;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .form-select select {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form-select select {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form-select select {
      padding-left: calc(80px / 4); } }

.form__form .form-checkbox {
  align-items: baseline;
  color: #27251f;
  color: var(--color-black);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }
  .form__form .form-checkbox a {
    color: #ee2537;
    color: var(--color-main); }

.form__form .form-field .description {
  color: #27251f;
  color: var(--color-black); }
  .form__form .form-field .description a {
    text-decoration: underline; }

input[type='checkbox'] {
  margin-right: calc(16px / 4);
  cursor: pointer;
  height: 20px;
  height: var(--dimension-checkbox);
  outline: none;
  position: relative;
  width: 20px;
  width: var(--dimension-checkbox); }
  @media screen and (min-width: 768px) {
    input[type='checkbox'] {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    input[type='checkbox'] {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    input[type='checkbox'] {
      margin-right: calc(80px / 4); } }

input[type='checkbox']:before {
  background-color: #f7f6f5;
  background-color: var(--color-desert-storm);
  border: 2px solid #ee2537;
  border: var(--dimension-border) solid var(--color-main);
  content: '';
  display: block;
  height: 20px;
  height: var(--dimension-checkbox);
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  width: var(--dimension-checkbox); }

.form-input input::-moz-selection {
  background-color: rgba(37, 37, 31, 0.5);
  background-color: var(--color-black-faded); }

.form-input input::selection {
  background-color: rgba(37, 37, 31, 0.5);
  background-color: var(--color-black-faded); }

input[type='checkbox']:focus:before,
input[type='checkbox']:active:before {
  border: calc(2px * 2) solid #ee2537;
  border: calc(var(--dimension-border) * 2) solid var(--color-main); }

input[type='checkbox']:checked:after {
  align-content: center;
  color: #ee2537;
  color: var(--color-main);
  content: '\2715';
  font-size: 18px;
  font-size: var(--font-size-18);
  font-weight: bolder;
  height: 20px;
  height: var(--dimension-checkbox);
  left: 4px;
  position: absolute;
  top: 0;
  width: 20px;
  width: var(--dimension-checkbox); }

input[type='checkbox']:focus:after,
input[type='checkbox']:active:after {
  left: 6px;
  top: 4px; }

.form-checkbox .inline-button {
  text-decoration: underline; }

.form__form-checkbox:not(:last-child) {
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.form-input input:focus,
.form-input input:active,
.form-select select:focus,
.form-select select:active,
.form__form form.form textarea.standard:focus,
.form__form form.form textarea.standard:active {
  border: calc(2px * 2) solid #27251f;
  border: calc(var(--dimension-border) * 2) solid var(--color-black);
  outline: none; }

.form__form form.form span.value {
  margin-left: 0; }

.form__form-input {
  color: rgba(255, 255, 255, 0.5);
  color: var(--color-secondary-faded); }

.form__form-button {
  width: calc(3 / 12 * 100%); }

.form__form-button-button {
  display: block;
  width: 100%; }

.form__form form.form .pd-captcha {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  left: 0;
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  position: relative;
  width: calc(5 / 12 * 100%); }
  @media screen and (min-width: 768px) {
    .form__form form.form .pd-captcha {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form .pd-captcha {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form .pd-captcha {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__form form.form .pd-captcha {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form .pd-captcha {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form .pd-captcha {
      padding-right: calc(80px / 2); } }

.form__form form.form p.no-label {
  margin-left: 0; }

.form__form form.form div.error {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .form__form form.form div.error {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form div.error {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form div.error {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__form form.form div.error {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__form form.form div.error {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__form form.form div.error {
      padding-right: calc(80px / 2); } }

.form__form form.form p.error,
.form__form form.form div.error {
  color: #ee2537;
  color: var(--color-main); }

.form__form form.form div.error input,
.form__form form.form div.error textarea.standard,
.form__form form.form div.error select {
  border-color: #ee2537;
  border-color: var(--color-main); }

.form__form form.form p.errors {
  background: #ee2537;
  background: var(--color-main); }

.form__before-form {
  padding-bottom: calc(16px / 2);
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18); }
  @media screen and (min-width: 768px) {
    .form__before-form {
      padding-bottom: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__before-form {
      padding-bottom: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__before-form {
      padding-bottom: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__before-form {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__before-form {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__before-form {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .form__before-form {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .form__before-form {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .form__before-form {
      padding-right: calc(80px / 2); } }

.form__after-form a,
.form__after-message a,
.form__before-form a {
  color: #ee2537;
  color: var(--color-main); }
  .form__after-form a:hover,
  .form__after-message a:hover,
  .form__before-form a:hover {
    text-decoration: underline; }

@media screen and (min-width: 768px) {
  .form:not(.form--full-height) iframe {
    height: 70vh;
    overflow: scroll; }
  .form__form--no-right {
    margin-right: 50%; }
  .form__content {
    width: calc((8 / 12) * 100%); }
  .form--catalog,
  iframe#download {
    display: flex; }
    .form--catalog .form__image,
    iframe#download .form__image {
      padding-left: calc(16px + 0px);
      display: block;
      width: calc((2 / 12) * 100%); } }
    @media screen and (min-width: 768px) and (min-width: 768px) {
      .form--catalog .form__image,
      iframe#download .form__image {
        padding-left: calc(32px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1440px) {
      .form--catalog .form__image,
      iframe#download .form__image {
        padding-left: calc(48px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1680px) {
      .form--catalog .form__image,
      iframe#download .form__image {
        padding-left: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
    .form--catalog .form__content,
    iframe#download .form__content {
      width: calc((8 / 12) * 100%); }
  .form__form-column--half {
    width: calc(6 / 12 * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.footer {
  background: #27251f;
  background: var(--color-black);
  color: #fff;
  color: var(--color-secondary); }

.footer__grid {
  padding-top: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .footer__grid {
      padding-top: calc(48px + 0px); } }

.footer__logos {
  margin-bottom: calc(32px + 0px);
  height: 64px;
  height: var(--dimension-footer-logos); }
  @media screen and (min-width: 1024px) {
    .footer__logos {
      margin-bottom: calc(48px + 0px); } }

.footer__logos img {
  max-height: 64px;
  max-height: var(--dimension-footer-logos);
  width: auto; }

.footer__logo--left {
  max-width: 50%; }

.footer__logo--right a:first-child {
  margin-right: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .footer__logo--right a:first-child {
      margin-right: calc(48px + 0px); } }

.footer__right {
  display: flex;
  flex-direction: column-reverse; }

.footer__info {
  border-top: 2px solid #fff;
  border-top: var(--dimension-border) solid var(--color-secondary);
  padding: 32px 0;
  padding: var(--space-between-small) 0;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }

.footer__header,
.footer__info .typography {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.footer__arrow .typography {
  margin: 0; }

.footer__center .animated-inline-button {
  cursor: pointer; }

.footer__center .animated-inline-button__text {
  margin-bottom: 0; }

.footer__info-content a {
  display: inline-block;
  font-weight: bold; }

.footer__info-content p:first-child a {
  text-decoration: underline; }

.footer__logo--right {
  display: flex;
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.footer__logo--right a:not(:last-child) {
  margin-right: calc(16px * 2); }
  @media screen and (min-width: 768px) {
    .footer__logo--right a:not(:last-child) {
      margin-right: calc(32px * 2); } }
  @media screen and (min-width: 1440px) {
    .footer__logo--right a:not(:last-child) {
      margin-right: calc(48px * 2); } }
  @media screen and (min-width: 1680px) {
    .footer__logo--right a:not(:last-child) {
      margin-right: calc(80px * 2); } }

.footer__social {
  margin-bottom: calc(32px + 0px);
  align-items: center;
  display: flex;
  justify-content: space-between; }
  @media screen and (min-width: 1024px) {
    .footer__social {
      margin-bottom: calc(48px + 0px); } }

.footer__social a {
  display: flex; }

.footer__social a:not(:last-child) {
  margin-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .footer__social a:not(:last-child) {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .footer__social a:not(:last-child) {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .footer__social a:not(:last-child) {
      margin-right: calc(80px + 0px); } }

.footer__social-icon {
  align-items: center;
  fill: #fff;
  fill: var(--color-secondary);
  height: 24px;
  height: var(--iconography-size-default);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.footer__social-icon:hover {
  fill: #ee2537;
  fill: var(--color-main); }

.footer__newsletter-cta {
  width: 100%; }

.footer__newsletter-cta .typography {
  margin-bottom: 0; }

.footer__privacy {
  padding-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .footer__privacy {
      padding-bottom: calc(48px + 0px); } }

.footer__privacy-item {
  display: inline-block; }

.footer__privacy-item:hover {
  color: #ee2537;
  color: var(--color-main); }

.footer__privacy-item:not(:last-child) {
  margin-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .footer__privacy-item:not(:last-child) {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .footer__privacy-item:not(:last-child) {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .footer__privacy-item:not(:last-child) {
      margin-right: calc(80px + 0px); } }

.footer__social-icon svg {
  vertical-align: bottom; }

.footer .content a {
  color: #fff;
  color: var(--color-secondary); }

.footer .content a:hover {
  color: #ee2537;
  color: var(--color-main); }

.footer__privacy-text {
  width: 100%; }

.footer__privacy-links {
  display: flex;
  padding-top: 16px;
  padding-top: var(--space-between-xxsmall);
  width: 100%; }

@media screen and (min-width: 768px) {
  .footer__newsletter-cta {
    width: calc(5 / 12 * 100%); }
  .footer__logo--left {
    max-width: calc(3 / 12 * 100%); }
  .footer__social {
    max-width: calc(6 / 12 * 100%); }
  .footer__privacy-links {
    justify-content: space-between; } }

@media screen and (min-width: 1024px) {
  .footer__grid {
    padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1024px) {
    .footer__grid {
      padding-bottom: calc(48px + 0px); } }

@media screen and (min-width: 1024px) {
  .footer__info {
    padding-top: calc(32px + 0px);
    padding-bottom: 0; } }
  @media screen and (min-width: 1024px) and (min-width: 1024px) {
    .footer__info {
      padding-top: calc(48px + 0px); } }

@media screen and (min-width: 1024px) {
  .footer__right {
    flex-direction: column; }
  .footer__logo--right {
    justify-content: space-between;
    margin-top: 0; }
  .footer__logo--right a:not(:last-child),
  .footer__social a:not(:last-child) {
    margin-right: calc(16px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .footer__logo--right a:not(:last-child),
    .footer__social a:not(:last-child) {
      margin-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .footer__logo--right a:not(:last-child),
    .footer__social a:not(:last-child) {
      margin-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .footer__logo--right a:not(:last-child),
    .footer__social a:not(:last-child) {
      margin-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
  .footer__newsletter-cta {
    width: 100%; }
  .footer__privacy {
    padding-bottom: 32px;
    padding-bottom: var(--space-between-small);
    padding-top: 32px;
    padding-top: var(--space-between-small); }
  .footer__privacy-text {
    width: 33%; }
  .footer__privacy-links {
    padding-top: 0;
    width: 66%; }
  .footer__logo--left {
    max-width: calc(8 / 12 * 100%); }
  .footer__social {
    max-width: calc(12 / 12 * 100%); } }

@media screen and (min-width: 1280px) {
  .footer__newsletter-cta {
    width: calc(3 / 4 * 100%); }
  .footer__social {
    max-width: calc(9 / 12 * 100%); }
  .footer__privacy-links {
    justify-content: flex-start; } }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: var(--font-size-16);
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-language-selector {
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em; }

.landing-language-selector-option {
  display: flex;
  align-items: center;
  text-transform: uppercase; }
  .landing-language-selector-option span {
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }

.landing-language-selector-option span:hover {
  opacity: 0.8;
  opacity: var(--landing-link-hover-opacity); }

.landing-language-selector-option--selected {
  pointer-events: none;
  cursor: default; }
  .landing-language-selector-option--selected span {
    font-weight: 600;
    font-variation-settings: 'wght' 600; }

.landing-language-selector-option--selected span:hover {
  opacity: 1; }

.landing-language-selector-option + .landing-language-selector-option::before {
  display: inline-block;
  content: '';
  background-color: black;
  height: 1em;
  width: 1px;
  margin: 0 0.75em; }

.landing-language-selector--dark {
  color: #fafafa;
  color: var(--landing-color-light); }
  .landing-language-selector--dark .landing-language-selector-option + .landing-language-selector-option::before {
    background-color: #fafafa;
    background-color: var(--landing-color-light); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.landing-header {
  height: calc(80px + 0px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
  z-index: var(--z-index-header); }
  @media screen and (min-width: 1280px) {
    .landing-header {
      height: calc(110px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-header {
      height: calc(120px + 0px); } }

.landing-header--scrolled {
  height: calc(80px * 0.67);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--box-shadow); }
  @media screen and (min-width: 1280px) {
    .landing-header--scrolled {
      height: calc(110px * 0.67); } }
  @media screen and (min-width: 1680px) {
    .landing-header--scrolled {
      height: calc(120px * 0.67); } }

.landing-header__wrapper {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between; }
  @media screen and (min-width: 768px) {
    .landing-header__wrapper {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-header__wrapper {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-header__wrapper {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-header__wrapper {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-header__wrapper {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-header__wrapper {
      padding-left: calc(80px + 0px); } }

.landing-header__logo {
  width: 50%; }
  .landing-header__logo a {
    width: 100%; }
  .landing-header__logo img {
    height: calc(80px * 0.65);
    width: auto; }
    @media screen and (min-width: 1280px) {
      .landing-header__logo img {
        height: calc(110px * 0.65); } }
    @media screen and (min-width: 1680px) {
      .landing-header__logo img {
        height: calc(120px * 0.65); } }

.landing-header__logo-link {
  width: 100%; }

.landing-header--scrolled .landing-header__logo {
  width: 45%; }
  .landing-header--scrolled .landing-header__logo img {
    height: calc(80px * 0.47); }
    @media screen and (min-width: 1280px) {
      .landing-header--scrolled .landing-header__logo img {
        height: calc(110px * 0.47); } }
    @media screen and (min-width: 1680px) {
      .landing-header--scrolled .landing-header__logo img {
        height: calc(120px * 0.47); } }

.landing-header__logo,
.landing-header__links,
.landing-header__language-selector,
.landing-header__search,
.landing-header__menu {
  align-items: center;
  display: inline-flex;
  margin: auto 0; }

.landing-header__links-item:hover {
  color: #ee2537;
  color: var(--color-main); }

.landing-header__links-item--selected {
  color: #ee2537;
  color: var(--color-main); }

.landing-header__search {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .landing-header__search {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-header__search {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-header__search {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-header__search {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .landing-header__search {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .landing-header__search {
      padding-left: calc(80px / 2); } }

.landing-header__search--mobile {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end; }

.landing-header__search-icon {
  cursor: pointer;
  height: 32px;
  height: var(--iconography-search);
  width: 32px;
  width: var(--iconography-search); }

.landing-header__search-icon:hover {
  fill: #ee2537;
  fill: var(--color-main); }

.landing-header__menu {
  padding-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-header__menu {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-header__menu {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-header__menu {
      padding-right: calc(80px + 0px); } }

.landing-header__menu-icon {
  height: 32px;
  height: var(--iconography-search);
  width: 32px;
  width: var(--iconography-search); }

.landing-header__search-bar {
  background: #fff;
  background: var(--color-secondary);
  margin: auto 0;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  width: 100%; }

.landing-header__search-input {
  width: 70%; }

@media screen and (min-width: 768px) {
  .landing-header__logo {
    max-width: none;
    width: calc(1.2 / 3 * 100%); }
  .landing-header--scrolled .landing-header__logo {
    width: calc(0.9 / 3 * 100%); } }

@media screen and (min-width: 1024px) {
  .landing-header__logo {
    width: 25%; }
  .landing-header--scrolled .landing-header__logo {
    width: 20%; } }

@media screen and (min-width: 1280px) {
  .landing-header__logo {
    width: calc(2.5 / 9 * 100%); }
  .landing-header--scrolled .landing-header__logo {
    width: calc(2 / 9 * 100%); }
  .landing-header__links {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end; }
  .landing-header__links-item {
    cursor: pointer;
    padding: 0 16px;
    padding: 0 var(--space-between-xxsmall); }
  .landing-header__search-bar {
    margin: auto; }
  .landing-header__search-input {
    margin: 0 auto;
    width: 40%; } }

@media screen and (min-width: 1920px) {
  .landing-header__logo {
    width: calc(2 / 9 * 100%); }
  .landing-header--scrolled .landing-header__logo {
    width: calc(1.8 / 9 * 100%); }
  .landing-header__logo img {
    max-width: 312px;
    max-width: var(--header-logo-max-width); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.footer {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .footer {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .footer {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .footer {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .footer {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .footer {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .footer {
      padding-left: calc(80px + 0px); } }

.footer__top-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: calc(0.33rem * 8);
  gap: calc(0.33rem * 8);
  grid-gap: calc(var(--s) * 8);
  gap: calc(var(--s) * 8);
  padding-top: calc(0.33rem * 10);
  padding-top: calc(var(--s) * 10); }
  @media screen and (min-width: 992px) {
    .footer__top-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; } }

.footer__bottom-wrapper {
  margin-top: calc(0.33rem * 25);
  margin-top: calc(var(--s) * 25);
  display: flex;
  flex-direction: column-reverse;
  grid-gap: calc(0.33rem * 4);
  gap: calc(0.33rem * 4);
  grid-gap: calc(var(--s) * 4);
  gap: calc(var(--s) * 4);
  padding-bottom: calc(0.33rem * 5);
  padding-bottom: calc(var(--s) * 5); }
  @media screen and (min-width: 992px) {
    .footer__bottom-wrapper {
      flex-direction: row;
      justify-content: space-between; } }

.footer__heading {
  margin-bottom: 0.33rem;
  margin-bottom: var(--s); }

.footer__address {
  text-align: left;
  display: flex;
  flex-direction: column;
  grid-gap: calc(0.33rem * 3);
  gap: calc(0.33rem * 3);
  grid-gap: calc(var(--s) * 3);
  gap: calc(var(--s) * 3);
  line-height: 1.75em; }

.footer__logo {
  max-width: calc(0.33rem * 26);
  max-width: calc(var(--s) * 26); }

.footer__location {
  margin-bottom: calc(0.33rem * 6);
  margin-bottom: calc(var(--s) * 6); }

.footer__phone {
  display: flex;
  flex-direction: column;
  grid-gap: calc(0.33rem * 3);
  gap: calc(0.33rem * 3);
  grid-gap: calc(var(--s) * 3);
  gap: calc(var(--s) * 3); }
  .footer__phone a {
    font-weight: 700;
    text-decoration: underline;
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }
    .footer__phone a:hover {
      opacity: 0.5; }

.footer__collaborators {
  display: flex;
  grid-gap: calc(0.33rem * 5);
  gap: calc(0.33rem * 5);
  grid-gap: calc(var(--s) * 5);
  gap: calc(var(--s) * 5);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center; }

.footer__collaborator--brta,
.footer__collaborator--earto {
  max-width: calc(0.33rem * 17);
  max-width: calc(var(--s) * 17); }

.footer__collaborator--euskampus {
  max-width: calc(0.33rem * 23);
  max-width: calc(var(--s) * 23); }

.footer__copyright {
  color: #868686;
  color: var(--landing-color-neutral-500); }

.footer__legal-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-row-gap: calc(0.33rem * 2);
  row-gap: calc(0.33rem * 2);
  grid-row-gap: calc(var(--s) * 2);
  row-gap: calc(var(--s) * 2); }
  .footer__legal-links > * {
    display: flex;
    height: 1.5em; }
    @media screen and (min-width: 992px) {
      .footer__legal-links > * {
        height: calc(0.33rem * 4);
        height: calc(var(--s) * 4); } }
  .footer__legal-links > * + *::before {
    display: block;
    content: '';
    width: 1px;
    height: 1.5em;
    background-color: #868686;
    background-color: var(--landing-color-neutral-500); }
  .footer__legal-links .footer__legal-link {
    color: #868686;
    color: var(--landing-color-neutral-500);
    white-space: nowrap;
    padding-left: calc(0.33rem * 2);
    padding-left: calc(var(--s) * 2);
    padding-right: calc(0.33rem * 2);
    padding-right: calc(var(--s) * 2);
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }
    .footer__legal-links .footer__legal-link:hover {
      color: #fafafa;
      color: var(--landing-color-light); }
  .footer__legal-links .footer__legal-link--first {
    padding-left: 0; }
  .footer__legal-links .footer__legal-link--last {
    padding-right: 0; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.body {
  overflow: hidden;
  position: relative; }

.body--header-padding {
  padding-top: calc(80px + 0px); }
  @media screen and (min-width: 1280px) {
    .body--header-padding {
      padding-top: calc(110px + 0px); } }
  @media screen and (min-width: 1680px) {
    .body--header-padding {
      padding-top: calc(120px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.related-tags {
  margin-bottom: calc(48px + 0px);
  margin-top: 32px;
  margin-top: var(--space-between-small);
  min-height: 88px;
  min-height: var(--dimension-table-link);
  position: relative; }
  @media screen and (min-width: 1024px) {
    .related-tags {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-tags {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-tags {
      margin-bottom: calc(80px + 0px); } }

.related-tags--with-border::before {
  background-color: #27251f;
  background-color: var(--color-black);
  content: '';
  height: 2px;
  height: var(--dimension-border);
  left: 0;
  position: absolute;
  top: calc(32px * -1);
  top: calc(var(--space-between-small) * -1);
  width: 100%; }

.related-tags__grid-item {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.related-tags .related-tags__title {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.related-tags__name {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.related-tags__item {
  text-decoration: underline; }

.related-tags__item:hover {
  color: #ee2537;
  color: var(--color-main);
  cursor: pointer; }

.related-tags--disabled .related-tags__item {
  text-decoration: none; }

.related-tags--disabled .related-tags__item:hover {
  color: #27251f;
  color: var(--color-black);
  cursor: default; }

.title-text-component__content {
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-xsmall);
}

.title-text-component__title {
  display: block;
}

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.generic-preview {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .generic-preview {
      margin-bottom: calc(48px + 0px); } }

.generic-preview a {
  flex-grow: 1; }

.generic-preview__content {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  background: #fff;
  background: var(--color-secondary);
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  flex-grow: 1;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 16px;
  padding-top: var(--space-between-xxsmall);
  position: relative;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing);
  z-index: 25;
  z-index: var(--z-index-card-content); }
  @media screen and (min-width: 768px) {
    .generic-preview__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .generic-preview__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .generic-preview__content {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .generic-preview__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .generic-preview__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .generic-preview__content {
      padding-left: calc(80px / 2); } }

.generic-preview .generic-preview__content {
  transition: 0s background; }

.generic-preview__content::after {
  background: #ee2537;
  background: var(--color-main);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out, -webkit-transform var(--transition-timing-default) ease-in-out;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.generic-preview:hover .generic-preview__content::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.generic-preview:hover .generic-preview__content {
  color: #fff;
  color: var(--color-secondary);
  transition-delay: 0.1s; }

.generic-preview:hover .title-text-component__title {
  color: #fff;
  color: var(--color-secondary);
  transition-delay: 0.1s; }

.generic-preview__content .title-text-component__title {
  color: #ee2537;
  color: var(--color-main);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.generic-preview__date {
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 16px;
  right: var(--space-between-xxsmall);
  text-shadow: 1px 1px 2px #000;
  top: 8px;
  top: var(--space-between-xxxsmall); }

.generic-preview__date .typography:first-child {
  margin: auto; }

.generic-preview__date .typography:last-child {
  text-transform: capitalize; }

.generic-preview .generic-preview__picture .picture__image {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.generic-preview:hover .generic-preview__picture .picture__image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform: var(--previews-zoom-transform);
          transform: var(--previews-zoom-transform);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.generic-preview__wrapper {
  height: 100%;
  position: relative; }

.generic-preview:hover .generic-preview__content {
  border-bottom: none; }

@media screen and (min-width: 768px) {
  .generic-preview--horizontal {
    flex-direction: row;
    height: 100%; }
  .generic-preview--horizontal .generic-preview__picture {
    flex-shrink: 0;
    width: 30%; }
  .generic-preview--bigger-image .generic-preview__picture {
    flex-shrink: 0;
    width: 35%; }
  .generic-preview__picture-image {
    height: 100%; }
  .generic-preview__content {
    padding-right: calc(16px / 4);
    padding-left: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .generic-preview__content {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .generic-preview__content {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .generic-preview__content {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .generic-preview__content {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .generic-preview__content {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .generic-preview__content {
      padding-left: calc(80px / 4); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.expert-module {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1024px) {
    .expert-module {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .expert-module {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .expert-module {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert-module {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert-module {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .expert-module {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert-module {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert-module {
      padding-right: calc(80px + 0px); } }

.expert-module__name {
  display: block;
  margin-top: 8px;
  margin-top: var(--space-between-xxxsmall); }

.expert-module__button {
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.expert-module__image {
  margin: 0 auto; }

.expert-module .expert-module__image .picture__image {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.expert-module:hover .expert-module__image .picture__image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform: var(--previews-zoom-transform);
          transform: var(--previews-zoom-transform);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.expert-module__button {
  display: block;
  width: 100%; }

@media screen and (min-width: 1024px) {
  .expert-module {
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px);
    margin-top: 0;
    max-width: 100%; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .expert-module {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .expert-module {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .expert-module {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .expert-module {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .expert-module {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .expert-module {
      padding-right: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.catalogue-download {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-left: calc(16px * 3);
  padding-right: calc(16px * 3);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  text-align: center; }
  @media screen and (min-width: 1024px) {
    .catalogue-download {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .catalogue-download {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .catalogue-download {
      padding-left: calc(32px * 3); } }
  @media screen and (min-width: 1440px) {
    .catalogue-download {
      padding-left: calc(48px * 3); } }
  @media screen and (min-width: 1680px) {
    .catalogue-download {
      padding-left: calc(80px * 3); } }
  @media screen and (min-width: 768px) {
    .catalogue-download {
      padding-right: calc(32px * 3); } }
  @media screen and (min-width: 1440px) {
    .catalogue-download {
      padding-right: calc(48px * 3); } }
  @media screen and (min-width: 1680px) {
    .catalogue-download {
      padding-right: calc(80px * 3); } }

.catalogue-download__image {
  margin: 0 auto 8px;
  margin: 0 auto var(--space-between-xxxsmall); }

.catalogue-download__button {
  display: block;
  margin-top: 8px;
  margin-top: var(--space-between-xxxsmall); }

@media screen and (min-width: 768px) {
  .catalogue-download {
    padding-left: calc(16px * 2);
    padding-right: calc(16px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .catalogue-download {
      padding-left: calc(32px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .catalogue-download {
      padding-left: calc(48px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .catalogue-download {
      padding-left: calc(80px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .catalogue-download {
      padding-right: calc(32px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .catalogue-download {
      padding-right: calc(48px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .catalogue-download {
      padding-right: calc(80px * 2); } }

@media screen and (min-width: 768px) {
  .catalogue-download__image {
    width: 50%; } }

@media screen and (min-width: 1024px) {
  .catalogue-download {
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px);
    max-width: 100%; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .catalogue-download {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .catalogue-download {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .catalogue-download {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .catalogue-download {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .catalogue-download {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .catalogue-download {
      padding-right: calc(80px + 0px); } }

@media screen and (min-width: 1024px) {
  .catalogue-download__image {
    width: 100%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.title-text-button {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1024px) {
    .title-text-button {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .title-text-button {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .title-text-button {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .title-text-button {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .title-text-button {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .title-text-button {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .title-text-button {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .title-text-button {
      padding-right: calc(80px + 0px); } }

.title-text-button__title,
.title-text-button__text {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.title-text-button__button {
  display: block;
  width: 100%; }

@media screen and (min-width: 1024px) {
  .title-text-button {
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px);
    margin-top: 0;
    max-width: 100%; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .title-text-button {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .title-text-button {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .title-text-button {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .title-text-button {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .title-text-button {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .title-text-button {
      padding-right: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.table-cell {
  padding-right: calc(16px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-top: 32px;
  padding-top: var(--space-between-small); }
  @media screen and (min-width: 768px) {
    .table-cell {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .table-cell {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .table-cell {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .table-cell {
      padding-bottom: calc(48px + 0px); } }

.table-cell__link {
  text-decoration: underline; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.data-table {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .data-table {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .data-table {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .data-table {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .data-table {
      margin-bottom: calc(120px + 0px); } }

.data-table__column {
  width: 100%; }

.data-table__table {
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall);
  width: 100%; }

.data-table__table-header {
  color: #ee2537;
  color: var(--color-main);
  display: flex;
  margin: 24px 0;
  margin: var(--space-between-xsmall) 0; }

.data-table__table-content {
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black); }

.data-table__table-content:last-child {
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black); }

.data-table__table-content:nth-child(odd) {
  background: #f7f6f5;
  background: var(--color-desert-storm); }

.data-table .table-cell,
.data-table__table-th {
  padding-left: calc(16px / 8); }
  @media screen and (min-width: 768px) {
    .data-table .table-cell,
    .data-table__table-th {
      padding-left: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .data-table .table-cell,
    .data-table__table-th {
      padding-left: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .data-table .table-cell,
    .data-table__table-th {
      padding-left: calc(80px / 8); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.video {
  position: relative; }

.video::before {
  content: '';
  display: block;
  padding-top: calc(9 / 16 * 100%);
  padding-top: calc(var(--aspect-ratio-16-9) * 100%); }

.video__player {
  left: 0;
  position: absolute;
  top: 0; }

.video-component {
  display: block;
  height: auto;
  position: relative;
  width: 100%;
}

.video-component .video::before {
  padding-top: 0;
}

.video-component .video {
  position: static;
  position: initial;
}

.video-component__image,
.video-component iframe {
  height: 100%;
  object-fit: cover;
}

.video-component iframe {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-component__icon {
  border: var(--dimension-border) solid var(--color-secondary);
  border-radius: 50%;
  cursor: pointer;
  fill: var(--color-secondary);
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 100px;
}

@media screen and (min-width: 768px) {
  .video-component {
    height: 100%;
  }
}

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative; }

.slider__carousel {
  outline: none;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  position: relative; }

.slider .is-fullscreen {
  padding-bottom: 0; }

.slider__card {
  height: auto;
  max-width: 100vw; }
  .slider__card > * {
    margin-right: calc(16px / 4);
    display: block; }
    @media screen and (min-width: 768px) {
      .slider__card > * {
        margin-right: calc(32px / 4); } }
    @media screen and (min-width: 1440px) {
      .slider__card > * {
        margin-right: calc(48px / 4); } }
    @media screen and (min-width: 1680px) {
      .slider__card > * {
        margin-right: calc(80px / 4); } }

.slider__card--no-margin {
  margin-right: 0; }

.body .flickity-enabled.is-fullscreen {
  overflow: hidden; }

.slider .flickity-enabled.is-fullscreen {
  background: #27251f;
  background: var(--color-black);
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20; }

.slider .flickity-enabled.is-fullscreen .slider__card {
  height: 100vh; }

.slider .flickity-enabled.is-fullscreen .video-component {
  margin: auto;
  width: auto; }

.slider .flickity-enabled.is-fullscreen .video-component iframe {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.slider .flickity-viewport {
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: height 0.2s; }

.slider .is-fullscreen .flickity-viewport {
  height: 100vh; }

.flickity-fullscreen-button-view,
.flickity-fullscreen-button-exit {
  display: none; }

.slider__carousel-nav .slider__card {
  width: 50%; }

.slider__buttons {
  width: calc(56px * 2);
  display: flex;
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall);
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1440px) {
    .slider__buttons {
      width: calc(64px * 2); } }

.slider__buttons .button {
  height: calc(56px + 0px);
  width: calc(56px + 0px); }
  @media screen and (min-width: 1440px) {
    .slider__buttons .button {
      height: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .slider__buttons .button {
      width: calc(64px + 0px); } }

.slider__buttons .button:first-child {
  border-right: none; }

.slider__fullscreen {
  top: calc(48px * -1.5);
  width: calc(56px + 0px);
  right: calc(16px + 0px);
  position: absolute;
  z-index: 9; }
  @media screen and (min-width: 1280px) {
    .slider__fullscreen {
      top: calc(64px * -1.5); } }
  @media screen and (min-width: 1440px) {
    .slider__fullscreen {
      width: calc(64px + 0px); } }
  @media screen and (min-width: 768px) {
    .slider__fullscreen {
      right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .slider__fullscreen {
      right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .slider__fullscreen {
      right: calc(80px + 0px); } }

.slider__fullscreen svg {
  display: flex; }

.slider .flickity-enabled.is-fullscreen .slider__fullscreen {
  right: 50px;
  top: calc(80px + 20px); }
  @media screen and (min-width: 1280px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(110px + 20px); } }
  @media screen and (min-width: 1680px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(120px + 20px); } }

.slider__progress {
  background: #27251f;
  background: var(--color-black); }

.slider__button-previous-icon {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.slider__button-next-icon {
  fill: #ee2537;
  fill: var(--color-main);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

@media screen and (min-width: 768px) {
  .slider__carousel {
    flex-grow: 1;
    order: 2; }
  .slider__card {
    height: auto;
    max-width: 100vw; }
    .slider__card > * {
      margin-right: calc(16px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 768px) {
      .slider__card > * {
        margin-right: calc(32px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1440px) {
      .slider__card > * {
        margin-right: calc(48px + 0px); } }
    @media screen and (min-width: 768px) and (min-width: 1680px) {
      .slider__card > * {
        margin-right: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .slider .flickity-enabled.is-fullscreen .slider__card {
    margin-right: 0;
    max-height: 100vh;
    max-width: 100vw; }
  .slider__carousel-nav .slider__card {
    width: 20%; }
  .slider__buttons {
    margin-top: 0; } }

@media screen and (min-width: 1280px) {
  .slider__fullscreen {
    top: calc(48px * -1); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .slider__fullscreen {
      top: calc(64px * -1); } }

@media screen and (min-width: 1280px) {
  .slider__carousel {
    padding-bottom: 0; }
  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 100px; }
  .slider--with-horizontal-cards .slider__card {
    height: 180px;
    height: var(--dimension-horizontal-cards); }
  .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
    right: 50px;
    top: calc(80px + 10px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(110px + 10px); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .slider .flickity-enabled.is-fullscreen .slider__fullscreen {
      top: calc(120px + 10px); } }

@media screen and (min-width: 1440px) {
  .slider {
    flex-direction: row; }
  .slider__buttons {
    height: calc(56px * 2);
    margin-right: calc(16px + 0px);
    width: calc(56px + 0px);
    flex-direction: column; } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .slider__buttons {
      height: calc(64px * 2); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .slider__buttons {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .slider__buttons {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1440px) and (min-width: 1680px) {
    .slider__buttons {
      margin-right: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .slider__buttons {
      width: calc(64px + 0px); } }

@media screen and (min-width: 1440px) {
  .slider__buttons .button:first-child {
    border-bottom: none;
    border-right: 2px solid #ee2537;
    border-right: var(--dimension-border) solid var(--color-main); }
  .slider__buttons .button--disabled:first-child {
    border-right: 2px solid rgba(37, 37, 31, 0.5);
    border-right: var(--dimension-border) solid var(--color-black-faded); }
  .slider__buttons--inverted .button:first-child {
    border-bottom: none;
    border-right: 2px solid #fff;
    border-right: var(--dimension-border) solid var(--color-secondary); }
  .slider__buttons--inverted .button--disabled:first-child {
    border-right: 2px solid rgba(37, 37, 31, 0.5);
    border-right: var(--dimension-border) solid var(--color-black-faded); }
  .slider__button-previous-icon {
    -webkit-transform: rotate(270deg) translateY(-15%);
            transform: rotate(270deg) translateY(-15%); }
  .slider__button-next-icon {
    -webkit-transform: rotate(90deg) translateY(-15%);
            transform: rotate(90deg) translateY(-15%); }
  .slider__fullscreen {
    display: none;
    right: 0;
    top: 0;
    border: 1px solid #ee2537;
    border: 1px solid var(--color-main); }
    .slider__fullscreen svg {
      display: flex;
      fill: #ee2537;
      fill: var(--color-main); }
  .slider__card:hover .slider__fullscreen {
    display: flex; } }

@media screen and (min-width: 1920px) {
  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 50px; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.media-gallery {
  margin-top: calc(48px + 0px);
  padding-top: calc(48px + 0px);
  padding-bottom: calc(48px + 0px);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  background: #27251f;
  background: var(--color-black); }
  @media screen and (min-width: 1024px) {
    .media-gallery {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .media-gallery {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .media-gallery {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .media-gallery {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .media-gallery {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .media-gallery {
      padding-top: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .media-gallery {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .media-gallery {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .media-gallery {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .media-gallery {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .media-gallery {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .media-gallery {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .media-gallery {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .media-gallery {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .media-gallery {
      padding-right: calc(80px + 0px); } }

.media-gallery__item,
.media-gallery .is-fullscreen .media-gallery__item {
  height: auto;
  left: 50%;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.media-gallery__title {
  color: #fff;
  color: var(--color-secondary);
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.media-gallery .slider__card {
  height: calc(400px + 0px); }
  @media screen and (min-width: 768px) {
    .media-gallery .slider__card {
      height: calc(600px + 0px); } }

.media-gallery .slider__card .picture {
  height: calc(100% - 50px);
  height: calc(100% - var(--dimension-gallery-caption)); }

.media-gallery .slider__card .picture__picture,
.media-gallery .slider__card .video-component__image {
  height: 100%; }

.media-gallery .slider__card .picture img {
  max-height: calc(400px - 50px);
  max-height: calc(400px - var(--dimension-gallery-caption));
  display: block;
  height: auto;
  margin: 0 auto;
  width: auto; }
  @media screen and (min-width: 768px) {
    .media-gallery .slider__card .picture img {
      max-height: calc(600px - 50px);
      max-height: calc(600px - var(--dimension-gallery-caption)); } }

.media-gallery__item-caption {
  color: #fff;
  color: var(--color-secondary);
  margin-top: 8px;
  margin-top: var(--space-between-xxxsmall);
  text-align: center; }

.media-gallery .slider .is-fullscreen .slider__card {
  top: 0; }

.media-gallery .is-fullscreen .slider__card .video-component__image,
.media-gallery .is-fullscreen .slider__card .picture {
  height: auto;
  width: auto; }

.media-gallery .is-fullscreen .slider__card .video-component .picture__picture,
.media-gallery .is-fullscreen .slider__card .picture__picture {
  height: auto;
  max-width: 100%; }

.media-gallery .is-fullscreen .media-gallery__item-caption {
  display: inline-block;
  left: 50%;
  position: relative;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.media-gallery .is-fullscreen iframe {
  height: 100%;
  width: 100%; }

@media screen and (min-width: 768px) {
  .media-gallery__item {
    height: 100%;
    width: 100%; }
  .media-gallery .slider__card .video-component {
    height: calc(100% - 50px);
    height: calc(100% - var(--dimension-gallery-caption)); }
  .media-gallery .slider__card .picture img {
    height: 100%; } }

@media screen and (min-width: 1024px) {
  .media-gallery .is-fullscreen .media-gallery__item,
  .media-gallery .is-fullscreen .slider__card .picture img {
    max-height: 90vh;
    max-width: 90vw; }
  .media-gallery .is-fullscreen .slider__card .picture,
  .media-gallery .is-fullscreen .slider__card .picture__picture,
  .media-gallery .is-fullscreen .slider__card .picture__picture img {
    height: auto;
    width: auto; }
  .media-gallery .is-fullscreen iframe,
  .media-gallery .is-fullscreen .slider__card .video-component img {
    height: 100%;
    width: 100%; } }

@media screen and (min-width: 1280px) {
  .media-gallery .slider__card {
    height: auto; }
  .media-gallery .slider__card .picture {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
  .media-gallery .slider__card .picture img {
    height: calc(400px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .media-gallery .slider__card .picture img {
      height: calc(600px + 0px); } }

@media screen and (min-width: 1280px) {
  .media-gallery .slider__card .picture {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
  .media-gallery__item-caption {
    text-align: left; }
  .media-gallery__item {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content; }
  .media-gallery .is-fullscreen .media-gallery__item {
    width: 100%; }
  .media-gallery__item {
    height: auto;
    left: 0;
    top: 0;
    -webkit-transform: none;
            transform: none;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content; } }

@media screen and (min-width: 1024px) {
  .media-gallery {
    padding-right: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.agenda__content-text {
  padding-right: calc(16px / 8);
  padding-left: calc(16px / 8);
  align-items: center;
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black);
  display: flex;
  height: 100%;
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: 8px;
  padding-top: var(--space-between-xxxsmall);
  position: relative;
  transition: 0.3s;
  transition: var(--transition-timing-default);
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 768px) {
    .agenda__content-text {
      padding-right: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .agenda__content-text {
      padding-right: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .agenda__content-text {
      padding-right: calc(80px / 8); } }
  @media screen and (min-width: 768px) {
    .agenda__content-text {
      padding-left: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .agenda__content-text {
      padding-left: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .agenda__content-text {
      padding-left: calc(80px / 8); } }

.agenda__content-text:last-child {
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black); }

.agenda__content-text::after {
  background: #ee2537;
  background: var(--color-main);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out, -webkit-transform var(--transition-timing-default) ease-in-out;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.agenda__content-text:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.agenda__content-text:hover .agenda__content-number,
.agenda__content-text:hover .agenda__content-date-details,
.agenda__content-text:hover .agenda__content-link {
  color: #fff;
  color: var(--color-secondary); }

.agenda__content-date {
  margin-right: calc(16px / 4);
  align-items: center;
  color: #ee2537;
  color: var(--color-main);
  display: flex;
  flex-direction: column;
  width: calc((1 / 4) * 100%); }
  @media screen and (min-width: 768px) {
    .agenda__content-date {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .agenda__content-date {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .agenda__content-date {
      margin-right: calc(80px / 4); } }

.agenda__content-date-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.agenda__content-link {
  color: #27251f;
  color: var(--color-black);
  display: flex;
  flex: 1;
  text-align: left; }

.agenda__content-event {
  margin-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .agenda__content-event {
      margin-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .agenda__content-event {
      margin-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .agenda__content-event {
      margin-left: calc(80px / 2); } }

.agenda__content-link .typography {
  width: 100%; }

@media screen and (min-width: 768px) {
  .agenda__content-date {
    flex-direction: row;
    margin-right: 0;
    width: calc((1 / 8) * 100%); }
  .agenda__content-number {
    margin-right: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .agenda__content-number {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .agenda__content-number {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .agenda__content-number {
      margin-right: calc(80px / 4); } }

@media screen and (min-width: 1680px) {
  .agenda__content-event {
    margin-left: calc(16px / 4); } }
  @media screen and (min-width: 1680px) and (min-width: 768px) {
    .agenda__content-event {
      margin-left: calc(32px / 4); } }
  @media screen and (min-width: 1680px) and (min-width: 1440px) {
    .agenda__content-event {
      margin-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) and (min-width: 1680px) {
    .agenda__content-event {
      margin-left: calc(80px / 4); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.related-agenda {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  flex-direction: column;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .related-agenda {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .related-agenda {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .related-agenda {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .related-agenda {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .related-agenda {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .related-agenda {
      padding-left: calc(80px / 2); } }

.related-agenda__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.related-agenda .agenda__content-date {
  flex-direction: column; }

.related-agenda .agenda__content-number {
  margin-right: 0; }

.related-agenda__content {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.related-content-cards {
  margin-top: calc(48px + 0px);
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  flex-direction: column;
  width: 100%; }
  @media screen and (min-width: 1280px) {
    .related-content-cards {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 768px) {
    .related-content-cards {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-content-cards {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .related-content-cards {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-content-cards {
      padding-left: calc(80px + 0px); } }

.related-content-cards__wrapper {
  display: flex; }

.related-content-cards__card:first-child {
  margin-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .related-content-cards__card:first-child {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards__card:first-child {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-content-cards__card:first-child {
      margin-right: calc(80px + 0px); } }

.related-content-cards__cards .grid__item {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .related-content-cards__cards .grid__item {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-content-cards__cards .grid__item {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards__cards .grid__item {
      margin-bottom: calc(80px + 0px); } }

.related-content-cards--background {
  padding-top: calc(48px + 0px);
  padding-bottom: calc(48px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm); }
  @media screen and (min-width: 1024px) {
    .related-content-cards--background {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-content-cards--background {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards--background {
      padding-top: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .related-content-cards--background {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-content-cards--background {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-content-cards--background {
      padding-bottom: calc(80px + 0px); } }

.related-content-cards__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.related-content-cards__subitle {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.related-content-cards__column {
  width: 50%; }

@media screen and (min-width: 1024px) {
  .related-content-cards--with-button {
    padding-right: 0; }
  .related-content-cards {
    padding-right: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.related-news__cards {
  display: flex; }

.related-news__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.related-news .card-image {
  width: 100%; }

.related-news .card-image:not(:last-child) {
  margin-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .related-news .card-image:not(:last-child) {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-news .card-image:not(:last-child) {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-news .card-image:not(:last-child) {
      margin-right: calc(80px + 0px); } }

@media screen and (min-width: 1280px) {
  .related-news {
    padding-left: 0;
    padding-right: 0; }
  .related-news .card-image {
    width: 100%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.news-agenda-module {
  margin-bottom: calc(48px + 0px);
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .news-agenda-module {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .news-agenda-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-agenda-module {
      margin-bottom: calc(80px + 0px); } }

.news-agenda-module__related-news {
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  margin-top: calc(48px + 0px);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .news-agenda-module__related-news {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-agenda-module__related-news {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .news-agenda-module__related-news {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .news-agenda-module__related-news {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-agenda-module__related-news {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .news-agenda-module__related-news {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .news-agenda-module__related-news {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .news-agenda-module__related-news {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .news-agenda-module__related-news {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-agenda-module__related-news {
      margin-top: calc(120px + 0px); } }

.news-agenda-module__related-events {
  width: 100%; }

.news-agenda-module .related-agenda {
  margin-top: 0; }

@media screen and (min-width: 1280px) {
  .news-agenda-module {
    flex-direction: row; }
  .news-agenda-module__related-news {
    flex-grow: 1;
    margin-top: 0;
    padding-right: 0;
    width: calc(8 / 12 * 100%); }
  .news-agenda-module__related-news:not(.news-agenda-module__related-news--padding) {
    padding-left: 0; }
  .news-agenda-module__related-events {
    flex-grow: 1;
    max-width: 50%;
    width: calc(4 / 12 * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cookies-list {
  display: block; }

.cookies-list .CookieDeclarationType {
  border: 0; }

.cookies-list .CookieDeclarationType:not(:last-of-type) {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .cookies-list .CookieDeclarationType:not(:last-of-type) {
      margin-bottom: calc(48px + 0px); } }

.cookies-list .CookieDeclarationTypeHeader {
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-size: 24px;
  font-size: var(--font-size-24);
  font-style: normal;
  font-weight: 300;
  font-weight: var(--font-weight-light);
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall);
  text-transform: uppercase; }

.cookies-list .CookieDeclarationTableHeader {
  border-bottom: 2px solid #ee2537;
  border-bottom: var(--dimension-border) solid var(--color-main);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-size: 20px;
  font-size: var(--font-size-20);
  line-height: 40px;
  line-height: var(--line-height-for-20);
  font-style: normal;
  font-weight: 300;
  font-weight: var(--font-weight-light); }

.cookies-list .CookieDeclarationTableCell {
  border-bottom: 2px solid #ee2537;
  border-bottom: var(--dimension-border) solid var(--color-main);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-style: normal;
  font-weight: 300;
  font-weight: var(--font-weight-light);
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18);
  padding-bottom: 24px;
  padding-bottom: var(--space-between-xsmall);
  padding-top: 24px;
  padding-top: var(--space-between-xsmall); }

.cookies-list .CookieDeclarationTypeDescription {
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default);
  font-style: normal;
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 24px;
  line-height: var(--line-height-for-18);
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-card {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .cms-card {
      margin-bottom: calc(48px + 0px); } }

.cms-card__content {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  background: #fff;
  background: var(--color-secondary);
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  flex-grow: 1;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 16px;
  padding-top: var(--space-between-xxsmall);
  position: relative; }
  @media screen and (min-width: 768px) {
    .cms-card__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .cms-card__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .cms-card__content {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .cms-card__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .cms-card__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .cms-card__content {
      padding-left: calc(80px / 2); } }

.cms-card__content .title-text-component__title {
  color: #ee2537;
  color: var(--color-main);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.cms-card__picture {
  border: 2px solid #f7f6f5;
  border: var(--dimension-border) solid var(--color-desert-storm); }

.cms-card--horizontal .cms-card__picture {
  border: none; }

.cms-card .cms-card__picture .picture__image {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.cms-card__wrapper {
  height: 100%;
  position: relative; }

.cms-card__content-social {
  margin-top: 8px;
  margin-top: var(--space-between-xxxsmall); }

.cms-card__content-social .iconography:first-child {
  margin-right: calc(16px / 4); }
  @media screen and (min-width: 768px) {
    .cms-card__content-social .iconography:first-child {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .cms-card__content-social .iconography:first-child {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .cms-card__content-social .iconography:first-child {
      margin-right: calc(80px / 4); } }

.cms-card__content-social-icon:hover svg {
  fill: #ee2537;
  fill: var(--color-main); }

.cms-card__content-link {
  color: #ee2537;
  color: var(--color-main); }

.cms-card--horizontal .cms-card__content-link {
  color: #27251f;
  color: var(--color-black);
  text-decoration: underline; }

.cms-card--horizontal .cms-card__content {
  padding-bottom: 0; }

.cms-card--reverse .cms-card__picture {
  order: 2; }

@media screen and (min-width: 768px) {
  .cms-card--horizontal {
    flex-direction: row;
    min-height: 229px; }
  .cms-card--horizontal .cms-card__picture {
    flex-shrink: 0;
    width: 45%; }
  .cms-card__picture-image {
    height: 100%; }
  .cms-card__content {
    padding-right: calc(16px / 4);
    padding-left: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .cms-card__content {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .cms-card__content {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .cms-card__content {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .cms-card__content {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .cms-card__content {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .cms-card__content {
      padding-left: calc(80px / 4); } }

@media screen and (min-width: 1024px) {
  .cms-card--horizontal {
    height: 100%;
    min-height: 197px; } }

@media screen and (min-width: 1280px) {
  .cms-card--horizontal {
    min-height: 221px; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.featured-link {
  cursor: pointer;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }

.featured-link__wrapper::after {
  background: #ee2537;
  background: var(--color-main);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out;
  transition: transform var(--transition-timing-default) ease-in-out, -webkit-transform var(--transition-timing-default) ease-in-out;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.featured-link:hover .featured-link__wrapper::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.featured-link__column {
  width: 100%; }

.featured-link__wrapper {
  align-items: center;
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black);
  display: flex;
  height: 88px;
  height: var(--dimension-featured-link);
  position: relative;
  transition: all 0.2s ease-in-out;
  transition: all var(--transition-timing-faster) ease-in-out;
  z-index: 1;
  z-index: var(--base-z-index); }

.featured-link__title {
  flex: 1; }

.featured-link:hover,
.featured-link:hover .featured-link__icon {
  color: #fff;
  color: var(--color-secondary);
  fill: #fff;
  fill: var(--color-secondary); }

.feature-link__title--uppercase {
  text-transform: uppercase; }

@media screen and (min-width: 768px) {
  .featured-link__wrapper {
    padding-right: calc(16px / 8);
    padding-left: calc(16px / 8); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .featured-link__wrapper {
      padding-right: calc(32px / 8); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .featured-link__wrapper {
      padding-right: calc(48px / 8); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .featured-link__wrapper {
      padding-right: calc(80px / 8); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .featured-link__wrapper {
      padding-left: calc(32px / 8); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .featured-link__wrapper {
      padding-left: calc(48px / 8); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .featured-link__wrapper {
      padding-left: calc(80px / 8); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-cta-text {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1024px) {
    .cms-cta-text {
      margin-bottom: calc(48px + 0px); } }

.cms-cta-text__link {
  display: block;
  margin-bottom: 0;
  margin-bottom: var(--space-between-xxxxsmall);
  text-decoration: underline; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-accordion__wrapper {
  width: 100%; }

.cms-accordion__column {
  width: 100%; }

.cms-accordion__title {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .cms-accordion__title {
      margin-bottom: calc(48px + 0px); } }

.cms-accordion__item {
  position: relative; }

.cms-accordion__item:hover .cms-accordion__item-title {
  background-color: #ee2537;
  background-color: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.cms-accordion__item:hover .cms-accordion__toggle-icon {
  fill: #fff;
  fill: var(--color-secondary); }

.cms-accordion__item:hover .cms-accordion__item-title::before,
.cms-accordion__item:hover:last-child .cms-accordion__item-title::after {
  background-color: #ee2537;
  background-color: var(--color-main); }

.cms-accordion__item:hover + .cms-accordion__item .cms-accordion__item-title::before {
  background-color: #ee2537;
  background-color: var(--color-main); }

.cms-accordion__item:last-child .cms-accordion__item-title::after {
  background-color: #27251f;
  background-color: var(--color-black);
  bottom: 0;
  content: '';
  height: 2px;
  height: var(--dimension-border);
  left: 0;
  position: absolute;
  width: 100%; }

.cms-accordion__item .cms-accordion__item-title::before {
  background-color: #27251f;
  background-color: var(--color-black);
  content: '';
  height: 2px;
  height: var(--dimension-border);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.cms-accordion__item-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: 24px 8px;
  padding: var(--space-between-xsmall) var(--space-between-xxxsmall);
  position: relative; }

.cms-accordion__item-title-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.cms-accordion__item-title-button:focus {
  outline: none; }

.cms-accordion__toggle-icon {
  margin-left: calc(16px + 0px);
  height: 24px;
  height: var(--iconography-size-default);
  -webkit-transform: rotate(45deg) scale(0.8);
          transform: rotate(45deg) scale(0.8);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transition: -webkit-transform var(--transition-timing-default);
  transition: transform var(--transition-timing-default);
  transition: transform var(--transition-timing-default), -webkit-transform var(--transition-timing-default);
  width: 24px;
  width: var(--iconography-size-default); }
  @media screen and (min-width: 768px) {
    .cms-accordion__toggle-icon {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .cms-accordion__toggle-icon {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .cms-accordion__toggle-icon {
      margin-left: calc(80px + 0px); } }

[aria-expanded='true'] .cms-accordion__toggle-icon,
[aria-selected='true'] .cms-accordion__toggle-icon {
  -webkit-transform: rotate(0) scale(1);
          transform: rotate(0) scale(1);
  will-change: transform; }

.cms-accordion__body {
  color: #27251f;
  color: var(--color-black);
  display: block;
  position: relative;
  opacity: 0;
  overflow: hidden;
  max-height: 0; }

.cms-accordion__body--expanded {
  -webkit-animation: cms-accordion-fade-in 0.3s ease-in forwards;
          animation: cms-accordion-fade-in 0.3s ease-in forwards;
  -webkit-animation: cms-accordion-fade-in var(--transition-timing-default) ease-in forwards;
          animation: cms-accordion-fade-in var(--transition-timing-default) ease-in forwards;
  max-height: unset; }

.cms-accordion__body-wrapper {
  padding: 24px 8px;
  padding: var(--space-between-xsmall) var(--space-between-xxxsmall); }

.cms-accordion__body p:last-of-type {
  margin-bottom: 0; }

.cms-accordion__item--selected .cms-accordion__item-title {
  background: #ee2537;
  background: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.cms-accordion__item--selected .cms-accordion__toggle-icon {
  fill: #fff;
  fill: var(--color-secondary); }

.cms-accordion__item--selected .cms-accordion__item-title::before,
.cms-accordion__item--selected .cms-accordion__item-title::after {
  background-color: #ee2537;
  background-color: var(--color-main); }

.cms-accordion__item--selected:hover + .cms-accordion__item .cms-accordion__item-title::before {
  background-color: #27251f;
  background-color: var(--color-black); }

.cms-accordion .grid__title {
  color: #ee2537;
  color: var(--color-main); }

@-webkit-keyframes cms-accordion-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes cms-accordion-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.cms-accordion__body .content dd:not(:last-child),
.cms-accordion__body .content ul:not(:last-child),
.cms-accordion__body .content ol:not(:last-child),
.cms-accordion__body .content p:not(:last-child) {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.divider {
  border: 1px solid var(--color-black);
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-video {
  margin: auto;
  max-width: 1280px;
  width: 100%; }

.cms-video__wrapper {
  display: block;
  height: 100%;
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.cms-video__wrapper iframe {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.content-grid__item {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .content-grid__item {
      margin-bottom: calc(48px + 0px); } }

.content-grid__title {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.content-grid-list {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-flow: row wrap;
  max-width: 1920px;
  max-width: var(--body-max-width); }
  @media screen and (min-width: 1024px) {
    .content-grid-list {
      margin-bottom: calc(48px + 0px); } }

.content-grid-list__item {
  padding-left: calc(16px * 2);
  list-style-position: inside;
  list-style-type: disc;
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall);
  max-width: 100%;
  text-indent: -1.3em; }
  @media screen and (min-width: 768px) {
    .content-grid-list__item {
      padding-left: calc(32px * 2); } }
  @media screen and (min-width: 1440px) {
    .content-grid-list__item {
      padding-left: calc(48px * 2); } }
  @media screen and (min-width: 1680px) {
    .content-grid-list__item {
      padding-left: calc(80px * 2); } }

@media screen and (min-width: 768px) {
  .content-grid-list__item {
    padding-left: calc(16px * 1.5); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .content-grid-list__item {
      padding-left: calc(32px * 1.5); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .content-grid-list__item {
      padding-left: calc(48px * 1.5); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .content-grid-list__item {
      padding-left: calc(80px * 1.5); } }

@media screen and (min-width: 1680px) {
  .content-grid-list__item {
    padding-left: calc(16px + 0px); } }
  @media screen and (min-width: 1680px) and (min-width: 768px) {
    .content-grid-list__item {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1680px) and (min-width: 1440px) {
    .content-grid-list__item {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) and (min-width: 1680px) {
    .content-grid-list__item {
      padding-left: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-content__item:first-child > .cms-content__title {
  margin-top: 0; }

.cms-content__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.cms-content__item {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.share-buttons {
  top: calc(56px - 2px);
  top: calc(56px - var(--dimension-border));
  display: flex;
  flex-direction: row;
  position: absolute; }
  @media screen and (min-width: 1440px) {
    .share-buttons {
      top: calc(64px - 2px);
      top: calc(64px - var(--dimension-border)); } }

.share-buttons__item {
  justify-content: center;
  padding: 0; }

.share-buttons > a:nth-child(3) > .share-buttons__item,
.share-buttons > a:nth-child(4) > .share-buttons__item,
.share-buttons > .share-buttons__item:nth-child(3),
.share-buttons > .share-buttons__item:nth-child(4) {
  border-right: none; }

.share-buttons > a:nth-child(2) > .share-buttons__item,
.share-buttons > .share-buttons__item:nth-child(2),
.share-buttons > a:nth-child(3) > .share-buttons__item,
.share-buttons > .share-buttons__item:nth-child(3) {
  border-left: none; }

@media screen and (min-width: 768px) {
  .share-buttons {
    position: relative;
    top: 0; }
  .share-buttons a:first-child .share-buttons__item {
    border-left: none; } }

@media screen and (min-width: 1024px) {
  .share-buttons {
    display: flex;
    flex-direction: column; }
  .share-buttons .share-buttons__item:not(:last-child) {
    border-bottom: none; }
  .share-buttons a:first-child .share-buttons__item {
    border-left: 2px solid #ee2537;
    border-left: var(--dimension-border) solid var(--color-main); }
  .share-buttons a:not(:last-child) .share-buttons__item {
    border-bottom: none; }
  .share-buttons > a:nth-child(1) > .share-buttons__item,
  .share-buttons > a:nth-child(3) > .share-buttons__item,
  .share-buttons > a:nth-child(4) > .share-buttons__item,
  .share-buttons > .share-buttons__item:nth-child(1),
  .share-buttons > .share-buttons__item:nth-child(3),
  .share-buttons > .share-buttons__item:nth-child(4) {
    border-right: 2px solid #ee2537;
    border-right: var(--dimension-border) solid var(--color-main); }
  .share-buttons > a:nth-child(2) > .share-buttons__item,
  .share-buttons > .share-buttons__item:nth-child(2),
  .share-buttons > a:nth-child(3) > .share-buttons__item,
  .share-buttons > .share-buttons__item:nth-child(3) {
    border-left: 2px solid #ee2537;
    border-left: var(--dimension-border) solid var(--color-main); }
  .share-buttons__item {
    border-top: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.print-button__button {
  height: calc(56px + 0px);
  max-width: calc(56px + 0px);
  width: calc(56px + 0px);
  border-left: none;
  justify-content: center; }
  @media screen and (min-width: 1440px) {
    .print-button__button {
      height: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .print-button__button {
      max-width: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .print-button__button {
      width: calc(64px + 0px); } }

.print-button__button svg {
  align-items: center;
  display: flex;
  fill: #ee2537;
  fill: var(--color-main); }

@media screen and (min-width: 768px) {
  .print-button__button--open-download {
    border-bottom: 2px solid #ee2537;
    border-bottom: var(--dimension-border) solid var(--color-main); } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .print-button__button--open-download {
    border-left: none; } }

@media screen and (min-width: 1024px) {
  .print-button__button {
    border-left: 2px solid #ee2537;
    border-left: var(--dimension-border) solid var(--color-main);
    border-top: none; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.social-share-print {
  margin-bottom: calc(48px + 0px);
  padding-right: calc(16px + 0px);
  display: flex; }
  @media screen and (min-width: 1280px) {
    .social-share-print {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 768px) {
    .social-share-print {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .social-share-print {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .social-share-print {
      padding-right: calc(80px + 0px); } }

.social-share-print--open {
  margin-bottom: 96px; }
  @media screen and (min-width: 768px) {
    .social-share-print--open {
      margin-bottom: 0; } }

.social-share-print__column {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%; }

.social-share-print .button {
  height: calc(56px + 0px);
  max-width: calc(56px + 0px);
  width: calc(56px + 0px);
  justify-content: center; }
  @media screen and (min-width: 1440px) {
    .social-share-print .button {
      height: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .social-share-print .button {
      max-width: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .social-share-print .button {
      width: calc(64px + 0px); } }

.social-share-print .button:hover svg {
  fill: #fff;
  fill: var(--color-secondary); }

.social-share-print__button--open.button {
  background: #ee2537;
  background: var(--color-main); }

.social-share-print__button--open.button:hover {
  background-color: #ee2537;
  background-color: var(--color-main); }

.social-share-print svg {
  align-items: center;
  display: flex;
  fill: #ee2537;
  fill: var(--color-main); }

.social-share-print__button--open svg {
  fill: #fff;
  fill: var(--color-secondary); }

.social-share-print--white .share-buttons > div {
  background-color: #fff;
  background-color: var(--color-secondary); }

.social-share-print--white .button:hover {
  background-color: #ee2537;
  background-color: var(--color-main); }

@media screen and (min-width: 768px) {
  .social-share-print {
    margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1280px) {
    .social-share-print {
      margin-bottom: calc(64px + 0px); } }

@media screen and (min-width: 1024px) {
  .social-share-print__column {
    display: block; }
  .social-share-print__button:first-child {
    border-right: 2px solid #ee2537;
    border-right: var(--dimension-border) solid var(--color-main); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.main-layout {
  margin-bottom: calc(32px + 0px);
  flex-direction: row; }
  @media screen and (min-width: 1024px) {
    .main-layout {
      margin-bottom: calc(48px + 0px); } }

.main-layout-top-column {
  width: 100%; }

.main-layout__main {
  display: flex;
  flex-direction: column;
  width: 100%; }

.main-layout__main-content {
  width: 100%; }

.main-layout__main-share {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  width: auto; }

.main-layout__aside {
  display: flex;
  flex-direction: column;
  width: 100%; }

.main-layout__aside > div {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .main-layout__aside > div {
      margin-bottom: calc(48px + 0px); } }

@media screen and (min-width: 1024px) {
  .main-layout {
    flex-direction: row; }
  .main-layout__main {
    flex-direction: row;
    width: calc(9 / 12 * 100%); }
  .main-layout__main-share {
    margin-right: calc(16px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .main-layout__main-share {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .main-layout__main-share {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .main-layout__main-share {
      margin-right: calc(80px + 0px); } }

@media screen and (min-width: 1024px) {
  .main-layout__aside {
    flex-direction: column;
    width: calc(3 / 12 * 100%); }
  .main-layout__aside > div:last-child {
    margin-bottom: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.hero-category-title {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .hero-category-title {
      margin-bottom: calc(64px + 0px); } }

.hero-category-title__category {
  color: #ee2537;
  color: var(--color-main);
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.hero-category-title__title {
  color: #fff;
  color: var(--color-secondary);
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.hero-category-title__position {
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.hero-category-title__button {
  margin-top: calc(32px + 0px);
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .hero-category-title__button {
      margin-top: calc(48px + 0px); } }
  .hero-category-title__button .hero-category-title__button-button {
    width: 100%; }

.hero-category-title--inverted .hero-category-title__title {
  color: var(--color-black);
  color: var(--color-hero-category-inverted-title, var(--color-black)); }

.ambit .generic-hero .hero-category-title__title:after {
  color: #ff5000;
  color: var(--color-orange-decoration);
  content: ':';
  margin-left: 8px;
  margin-left: 8px;
  margin-left: var(--space-between-xxxsmall);
  position: relative;
  top: 0; }

.hero-category-title__extra {
  margin-top: 24px;
  margin-top: var(--space-between-xsmall); }

.hero-category-title.hero-category-title--full-width {
  width: 100%; }

.hero-category-title--Blog {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }
  .hero-category-title--Blog .hero-category-title__date {
    display: block;
    margin-top: 24px;
    margin-top: var(--space-between-xsmall); }
  .hero-category-title--Blog .hero-category-title__extra {
    margin-top: 8px;
    margin-top: var(--space-between-xxxsmall); }

@media screen and (min-width: 768px) {
  .hero-category-title__button {
    width: calc(3 / 6 * 100%); } }

.breadcrumb-item {
  margin-right: 2px;
}

.breadcrumb-item:hover {
  color: var(--color-main);
}

.breadcrumb-item:last-child {
  margin-right: 4px;
}
:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

:root {
  --dimension-breadcrumbs-border-height: 1px; }

.breadcrumbs {
  margin-bottom: calc(32px + 0px);
  color: #fff;
  color: var(--color-secondary);
  position: relative; }
  @media screen and (min-width: 1024px) {
    .breadcrumbs {
      margin-bottom: calc(48px + 0px); } }

.breadcrumbs__breadcrumb-items {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 8px 0; }

.breadcrumbs__breadcrumb-items .active {
  pointer-events: none; }

@media screen and (min-width: 1280px) {
  .breadcrumbs {
    margin-bottom: 16px;
    margin-bottom: var(--space-between-xxsmall); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.hero {
  max-height: 900px;
  margin-bottom: calc(48px + 0px);
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  @media screen and (min-width: 1024px) {
    .hero {
      max-height: 1000px; } }
  @media screen and (min-width: 768px) {
    .hero {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .hero {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .hero {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .hero {
      margin-bottom: calc(120px + 0px); } }

.hero--vertical {
  max-height: none; }

.hero--inverted {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .hero--inverted {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .hero--inverted {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .hero--inverted {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .hero--inverted {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .hero--inverted {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .hero--inverted {
      padding-left: calc(80px + 0px); } }

.hero--inverted .hero__column-content {
  padding-left: 0; }

.hero--inverted,
.hero--inverted .breadcrumbs {
  color: #27251f;
  color: var(--color-black); }

.hero__column {
  width: 100%; }

.hero__column-picture {
  max-height: 900px;
  height: 100%; }
  @media screen and (min-width: 1024px) {
    .hero__column-picture {
      max-height: 1000px; } }

.hero__column-content {
  padding-top: calc(32px + 0px);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  background: #27251f;
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1024px) {
    .hero__column-content {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .hero__column-content {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .hero__column-content {
      padding-right: calc(80px + 0px); } }

.hero--inverted .hero__column-content {
  background: #fff;
  background: var(--color-secondary);
  padding-top: 0; }

.hero__common-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between; }

.hero .related-tags {
  margin-bottom: 0;
  margin-top: 0; }

.hero .related-tags__grid {
  margin-left: calc(16px / -2); }
  @media screen and (min-width: 768px) {
    .hero .related-tags__grid {
      margin-left: calc(32px / -2); } }
  @media screen and (min-width: 1440px) {
    .hero .related-tags__grid {
      margin-left: calc(48px / -2); } }
  @media screen and (min-width: 1680px) {
    .hero .related-tags__grid {
      margin-left: calc(80px / -2); } }

.hero__picture,
.hero__image {
  height: 100%; }

.hero--vertical .hero__common-content-main {
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.hero__common-content-main .button {
  width: 100%; }

.hero .parallax-banner {
  flex-grow: 1;
  height: auto;
  min-height: 35vh;
  overflow: hidden; }

.hero--vertical .parallax-banner {
  min-height: 35vh; }

.hero--blog {
  --color-hero-category-inverted-title: var(--color-grey); }

.hero--blog.hero--vertical .parallax-banner {
  min-height: 50vh; }

.hero--blog.hero--vertical .hero__column-content {
  padding-bottom: 0; }

@media screen and (min-width: 1024px) {
  .hero {
    flex-direction: row;
    position: relative; }
  .hero__column {
    width: 50%; }
  .hero__column-picture {
    height: auto; }
  .hero--vertical {
    flex-direction: column; }
  .hero--vertical .hero__column-content {
    padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1280px) {
    .hero--vertical .hero__column-content {
      padding-bottom: calc(64px + 0px); } }

@media screen and (min-width: 1024px) {
  .hero--vertical .hero__column {
    width: 100%; }
  .hero--vertical .hero__common-content {
    flex-direction: row; }
  .hero--vertical .hero__common-content-main {
    margin-top: 0;
    width: 25%; }
  .hero--vertical .hero__common-content-main {
    display: flex;
    justify-content: flex-end;
    margin: auto 0; }
  .hero--vertical .hero-category-title {
    width: 50%; }
  .hero--vertical .hero-category-title.hero-category-title--inverted {
    width: 100%; }
  .hero .parallax-banner {
    height: 100%; }
  .hero--blog.hero--vertical .parallax-banner {
    min-height: 768px; } }

@media screen and (min-width: 1920px) {
  .hero {
    min-height: 50vh; }
  .hero--blog.hero--vertical .parallax-banner {
    min-height: 768px; }
  .hero--vertical .hero__column-content {
    padding-bottom: calc(48px + 0px);
    padding-top: calc(48px + 0px);
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1280px) {
    .hero--vertical .hero__column-content {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1280px) {
    .hero--vertical .hero__column-content {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .hero--vertical .hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .hero--vertical .hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1680px) {
    .hero--vertical .hero__column-content {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .hero--vertical .hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .hero--vertical .hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1680px) {
    .hero--vertical .hero__column-content {
      padding-right: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.business-opportunity__technologies,
.business-opportunity__featured-description {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .business-opportunity__technologies,
    .business-opportunity__featured-description {
      margin-bottom: calc(48px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.fixed-button {
  align-items: center;
  background-color: #ee2537;
  background-color: var(--color-main);
  border: 2px solid #ee2537;
  border: var(--dimension-border) solid var(--color-main);
  border-radius: 50%;
  bottom: 20px;
  box-sizing: border-box;
  color: #fff;
  color: var(--color-secondary);
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: 8px;
  padding-top: var(--space-between-xxxsmall);
  position: fixed;
  right: 20px;
  transition: 0.3s;
  transition: var(--transition-timing-default);
  width: 56px;
  z-index: 10;
  z-index: var(--z-index-picture); }

.fixed-button:hover {
  color: #fff;
  color: var(--color-secondary); }

.fixed-button::after {
  background: #fff;
  background: var(--color-secondary);
  border-radius: 50%;
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform var(--transition-timing-faster) ease-in-out;
  transition: transform var(--transition-timing-faster) ease-in-out;
  transition: transform var(--transition-timing-faster) ease-in-out, -webkit-transform var(--transition-timing-faster) ease-in-out;
  width: 100%;
  z-index: 1;
  z-index: var(--base-z-index); }

.fixed-button:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.fixed-button svg {
  fill: #fff;
  fill: var(--color-secondary); }

.fixed-button .iconography {
  z-index: 10;
  z-index: var(--z-index-picture); }

.fixed-button:hover svg {
  fill: #ee2537;
  fill: var(--color-main); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.paginator {
  margin-bottom: calc(32px + 0px);
  color: #ee2537;
  color: var(--color-main);
  display: inline-flex; }
  @media screen and (min-width: 1024px) {
    .paginator {
      margin-bottom: calc(48px + 0px); } }

.paginator__item {
  height: calc(56px + 0px);
  width: calc(56px + 0px);
  align-items: center;
  background: none;
  border-bottom: 2px solid #ee2537;
  border-bottom: var(--dimension-border) solid var(--color-main);
  border-right: 2px solid #ee2537;
  border-right: var(--dimension-border) solid var(--color-main);
  border-top: 2px solid #ee2537;
  border-top: var(--dimension-border) solid var(--color-main);
  cursor: pointer;
  font-size: 20px;
  font-size: var(--font-size-20);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  justify-content: center;
  position: relative; }
  @media screen and (min-width: 1440px) {
    .paginator__item {
      height: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .paginator__item {
      width: calc(64px + 0px); } }

.paginator__item:hover {
  background: rgba(238, 37, 55, 0.5);
  background: var(--color-main-faded);
  color: #fff;
  color: var(--color-secondary); }

.paginator__item:hover a {
  color: #fff;
  color: var(--color-secondary); }

.paginator__item a {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center; }

.paginator__item:first-child {
  border-left: 2px solid #ee2537;
  border-left: var(--dimension-border) solid var(--color-main);
  border-right: 0; }

.paginator__item:nth-child(2) {
  border-left: 2px solid #ee2537;
  border-left: var(--dimension-border) solid var(--color-main); }

.paginator__item--disabled {
  cursor: not-allowed;
  opacity: 0.4; }

.paginator .break {
  width: calc(56px + 0px);
  border-bottom: 2px solid #ee2537;
  border-bottom: var(--dimension-border) solid var(--color-main);
  border-right: 2px solid #ee2537;
  border-right: var(--dimension-border) solid var(--color-main);
  border-top: 2px solid #ee2537;
  border-top: var(--dimension-border) solid var(--color-main);
  padding: 16px;
  padding: var(--space-between-xxsmall);
  text-align: center; }
  @media screen and (min-width: 1440px) {
    .paginator .break {
      width: calc(64px + 0px); } }

.paginator__item--active {
  background-color: #ee2537;
  background-color: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

@media screen and (min-width: 1024px) {
  .paginator {
    margin-bottom: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.generic-hero {
  max-height: 900px;
  margin-bottom: calc(64px + 0px);
  background-color: #27251f;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1024px) {
    .generic-hero {
      max-height: 1000px; } }
  @media screen and (min-width: 1024px) {
    .generic-hero {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-hero {
      margin-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-hero {
      margin-bottom: calc(160px + 0px); } }

.generic-hero--with-background::after {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  background-image: var(--linear-gradient-black);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.generic-hero--red-gradient::after {
  background-image: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  background-image: var(--linear-gradient);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%; }

.generic-hero--red-gradient .hero-category-title__category {
  color: #fff;
  color: var(--color-secondary); }

.generic-hero--gradient {
  margin-bottom: calc(64px * 3);
  overflow: visible; }
  @media screen and (min-width: 1024px) {
    .generic-hero--gradient {
      margin-bottom: calc(72px * 3); } }
  @media screen and (min-width: 1280px) {
    .generic-hero--gradient {
      margin-bottom: calc(120px * 3); } }
  @media screen and (min-width: 1440px) {
    .generic-hero--gradient {
      margin-bottom: calc(160px * 3); } }

.generic-hero--purple-dark-blue {
  background: linear-gradient(90deg, #7e2574, #203244);
  background: var(--gradient-purple-dark-blue); }

.generic-hero--fuchsia-pink {
  background: linear-gradient(90deg, #c0004e, #7e2574);
  background: var(--gradient-fuchsia-pink); }

.generic-hero--garnet-salmon {
  background: linear-gradient(90deg, #a6093d, #dc8699);
  background: var(--gradient-garnet-salmon); }

.generic-hero--garnet-dark-blue {
  background: linear-gradient(
    90deg,
    #a6093d,
    #203244);
  background: var(--gradient-garnet-dark-blue); }

.generic-hero--light-blue-purple {
  background: linear-gradient(90deg, #6ba4b8, #7e2574);
  background: var(--gradient-light-blue-purple); }

.generic-hero--light-pink-purple {
  background: linear-gradient(90deg, #ffb1bb, #7e2574);
  background: var(--gradient-light-pink-purple); }

.generic-hero--yellow-orange {
  background: linear-gradient(
    90deg,
    #fecd00,
    #c75b12);
  background: var(--gradient-yellow-orange); }

.generic-hero--orange-red {
  background: linear-gradient(90deg, #c75b12, #c0004e);
  background: var(--gradient-orange-red); }

.generic-hero--turquoise-green {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #00685e);
  background: var(--gradient-turquoise-green); }

.generic-hero--light-blue-dark-blue {
  background: linear-gradient(
    90deg,
    #6ba4b8,
    #203244);
  background: var(--gradient-light-blue-dark-blue); }

.generic-hero--turquoise-dark-blue {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.generic-hero--turquoise-dark-blue {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.generic-hero--turquoise-yellow {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #fecd00);
  background: var(--gradient-turquoise-yellow); }

.generic-hero--light-yellow-yellow {
  background: linear-gradient(
    90deg,
    #f8e08e,
    #fecd00);
  background: var(--gradient-light-yellow-yellow); }

.generic-hero--salmon-turquoise {
  background: linear-gradient(90deg, #ff8d6d, #87c9c4);
  background: var(--gradient-salmon-turquoise); }

.generic-hero--yellow {
  background: #fecd00;
  background: var(--gradient-yellow); }

.generic-hero--red {
  background: #b7003e;
  background: var(--gradient-red); }

.generic-hero--fuchsia {
  background: #b0f;
  background: var(--gradient-fuchsia); }

.generic-hero--dark-blue {
  background: #0000f7;
  background: var(--gradient-dark-blue); }

.generic-hero--light-blue {
  background: #09f;
  background: var(--gradient-light-blue); }

.generic-hero--light-green {
  background: #00db92;
  background: var(--gradient-light-green); }

.generic-hero__background {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  z-index: -5;
  z-index: var(--z-index-negative); }

.generic-hero__column {
  justify-content: center;
  position: relative;
  width: 100%; }

.generic-hero__column-content {
  padding-top: calc(32px + 0px);
  padding-right: calc(16px + 0px);
  padding-bottom: calc(120px + 0px);
  padding-left: calc(16px + 0px);
  display: flex;
  flex-direction: column;
  z-index: 99999;
  z-index: var(--z-index-header); }
  @media screen and (min-width: 1024px) {
    .generic-hero__column-content {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .generic-hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .generic-hero__column-content {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-hero__column-content {
      padding-bottom: calc(240px + 0px); } }
  @media screen and (min-width: 768px) {
    .generic-hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .generic-hero__column-content {
      padding-left: calc(80px + 0px); } }

.generic-hero__picture .picture {
  width: 450px;
  max-width: 100%;
  position: absolute;
  top: -80px; }
  @media screen and (min-width: 768px) {
    .generic-hero__picture .picture {
      width: 850px; } }
  .generic-hero__picture .picture .picture__image {
    width: 90%; }

.ambit-hero--with-background {
  margin-bottom: calc(64px * 3);
  overflow: visible; }
  @media screen and (min-width: 1024px) {
    .ambit-hero--with-background {
      margin-bottom: calc(72px * 3); } }
  @media screen and (min-width: 1280px) {
    .ambit-hero--with-background {
      margin-bottom: calc(120px * 3); } }
  @media screen and (min-width: 1440px) {
    .ambit-hero--with-background {
      margin-bottom: calc(160px * 3); } }

.ambit-hero__background {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -5;
  z-index: var(--z-index-negative); }

.ambit-hero-text--white .breadcrumbs,
.ambit-hero-text--white .hero-category-title__category,
.ambit-hero-text--white .hero-category-title__title {
  color: #fff;
  color: var(--color-secondary); }

.ambit-hero-text--black .breadcrumbs,
.ambit-hero-text--black .hero-category-title__category,
.ambit-hero-text--black .hero-category-title__title {
  color: #27251f;
  color: var(--color-black); }

@media screen and (min-width: 768px) {
  .generic-hero {
    margin-bottom: calc(64px + 0px);
    flex-direction: row; } }
  @media screen and (min-width: 768px) and (min-width: 1024px) {
    .generic-hero {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1280px) {
    .generic-hero {
      margin-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .generic-hero {
      margin-bottom: calc(160px + 0px); } }

@media screen and (min-width: 768px) {
  .generic-hero__column {
    width: 50%; }
  .generic-hero__column-content {
    padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1280px) {
    .generic-hero__column-content {
      padding-bottom: calc(64px + 0px); } }

@media screen and (min-width: 768px) {
  .generic-hero__picture .picture {
    top: 32px;
    top: var(--space-between-small); } }

@media screen and (min-width: 1280px) {
  .generic-hero {
    margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .generic-hero {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .generic-hero {
      margin-bottom: calc(72px + 0px); } }

@media screen and (min-width: 1280px) {
  .generic-hero--gradient,
  .ambit-hero--with-background {
    margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .generic-hero--gradient,
    .ambit-hero--with-background {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .generic-hero--gradient,
    .ambit-hero--with-background {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .generic-hero--gradient,
    .ambit-hero--with-background {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .generic-hero--gradient,
    .ambit-hero--with-background {
      margin-bottom: calc(120px + 0px); } }

@media screen and (min-width: 1280px) {
  .generic-hero__column-content {
    padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .generic-hero__column-content {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .generic-hero__column-content {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .generic-hero__column-content {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .generic-hero__column-content {
      padding-bottom: calc(120px + 0px); } }

@media screen and (min-width: 1280px) {
  .generic-hero__picture .picture {
    top: 24px;
    top: var(--space-between-xsmall); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.generic-container__content {
  display: flex;
  flex-direction: column; }

.generic-container__grid {
  margin-left: 0;
  margin-right: 0; }

.generic-container .paginator {
  padding-right: calc(16px / 2);
  margin-top: calc(48px + 0px);
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .generic-container .paginator {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .generic-container .paginator {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .generic-container .paginator {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 1024px) {
    .generic-container .paginator {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-container .paginator {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-container .paginator {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .generic-container .paginator {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .generic-container .paginator {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-container .paginator {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-container .paginator {
      margin-bottom: calc(120px + 0px); } }

.generic-container__non-content {
  margin-bottom: calc(48px + 0px);
  display: block; }
  @media screen and (min-width: 768px) {
    .generic-container__non-content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .generic-container__non-content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-container__non-content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-container__non-content {
      margin-bottom: calc(120px + 0px); } }

@media screen and (min-width: 1024px) {
  .generic-container__content {
    padding-left: calc(16px / 2);
    padding-right: calc(16px / 2);
    flex-direction: row;
    padding-bottom: 0; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .generic-container__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .generic-container__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .generic-container__content {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .generic-container__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .generic-container__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .generic-container__content {
      padding-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
  .generic-container__content-main {
    width: 100%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.agenda__content {
  display: flex;
  flex-direction: column; }

.agenda__content-aside {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .agenda__content-aside {
      margin-bottom: calc(64px + 0px); } }

.agenda .generic-preview__wrapper {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .agenda .generic-preview__wrapper {
      margin-bottom: calc(48px + 0px); } }

.agenda .generic-preview__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.agenda .generic-preview__content .title-text-component__content {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall);
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.agenda__content-item-data {
  display: flex;
  flex-direction: column; }

.agenda__content-item-data-left,
.agenda__content-item-data-place {
  display: flex;
  position: relative; }

.agenda__content-item-data-left {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.agenda__content-item-data-type {
  color: #ee2537;
  color: var(--color-main);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.generic-preview:hover .agenda__content-item-data-type {
  color: #fff;
  color: var(--color-secondary); }

.generic-preview:hover .agenda__content-item-data-place-icon {
  fill: #fff;
  fill: var(--color-secondary); }

.agenda__content-item-data-place .iconography {
  margin-right: calc(16px / 8); }
  @media screen and (min-width: 768px) {
    .agenda__content-item-data-place .iconography {
      margin-right: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .agenda__content-item-data-place .iconography {
      margin-right: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .agenda__content-item-data-place .iconography {
      margin-right: calc(80px / 8); } }

.agenda__content-aside-filters,
.agenda__content-main {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .agenda__content-aside-filters,
    .agenda__content-main {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .agenda__content-aside-filters,
    .agenda__content-main {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .agenda__content-aside-filters,
    .agenda__content-main {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .agenda__content-aside-filters,
    .agenda__content-main {
      margin-bottom: calc(120px + 0px); } }

.agenda__non-content {
  margin-bottom: calc(48px + 0px);
  display: block; }
  @media screen and (min-width: 768px) {
    .agenda__non-content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .agenda__non-content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .agenda__non-content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .agenda__non-content {
      margin-bottom: calc(120px + 0px); } }

@media screen and (min-width: 768px) {
  .agenda__content-item-data {
    flex-direction: row; }
  .agenda__content-item-data-left {
    margin-bottom: 0; }
  .agenda__content-item-data-left,
  .agenda__content-item-data-place {
    width: 50%; }
  .agenda__content-item-data-place {
    align-items: flex-end; }
  .agenda .generic-preview__wrapper {
    height: 180px;
    height: var(--dimension-horizontal-cards); } }

@media screen and (min-width: 1024px) {
  .agenda__content {
    padding-left: calc(16px / 2);
    padding-right: calc(16px / 2);
    flex-direction: row;
    padding-bottom: 0; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .agenda__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .agenda__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .agenda__content {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .agenda__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .agenda__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .agenda__content {
      padding-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
  .agenda__content-aside {
    margin-bottom: 0;
    width: calc(2 / 12 * 100%); }
  .agenda__content-main {
    width: calc(9 / 12 * 100%); }
  .agenda__content-aside-search {
    width: 100%; } }

@media screen and (min-width: 1920px) {
  .agenda .generic-preview__wrapper {
    height: 300px;
    height: var(--dimension-agenda-cards); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.logos-grid__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.logos-grid__grid .picture {
  max-width: 55%; }

.logos-grid__item-description {
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: 8px;
  padding-top: var(--space-between-xxxsmall); }

.logos-grid__item-link {
  cursor: pointer;
  text-decoration: underline; }

.logos-grid__show-button {
  align-items: center;
  color: #ee2537;
  color: var(--color-main);
  cursor: pointer;
  display: flex;
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.logos-grid__icon {
  margin-left: calc(16px / 4);
  fill: #ee2537;
  fill: var(--color-main); }
  @media screen and (min-width: 768px) {
    .logos-grid__icon {
      margin-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .logos-grid__icon {
      margin-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .logos-grid__icon {
      margin-left: calc(80px / 4); } }

.logos-grid__item-image {
  width: 64px; }

.logos-grid--with-no-description .logos-grid__grid .grid__item {
  align-items: center;
  display: inline-flex;
  justify-content: center; }

.logos-grid--with-background {
  padding-left: calc(16px / 2);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  margin-top: 32px;
  margin-top: var(--space-between-small);
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small); }
  @media screen and (min-width: 768px) {
    .logos-grid--with-background {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .logos-grid--with-background {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .logos-grid--with-background {
      padding-left: calc(80px / 2); } }
  .logos-grid--with-background .logos-grid__item-image {
    align-items: flex-end;
    display: flex;
    height: 100px; }

.logos-grid--with-background .logos-grid__grid {
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 32px;
  padding-top: var(--space-between-small); }

.logos-grid--with-background .grid__item {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.logos-grid--with-background .logos-grid__show-button {
  margin-top: 24px;
  margin-top: var(--space-between-xsmall); }

.logos-grid__show-button:hover {
  text-decoration: underline; }

@media screen and (min-width: 1280px) {
  .logos-grid__grid .picture {
    max-width: 100%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.event {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .event {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .event {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event {
      margin-bottom: calc(80px + 0px); } }

.event__event-info {
  margin-left: calc(16px + 0px);
  margin-right: calc(16px + 0px);
  margin-bottom: calc(32px + 0px);
  margin-top: calc(32px + 0px);
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black);
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  padding: var(--space-between-small) 0; }
  @media screen and (min-width: 768px) {
    .event__event-info {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event__event-info {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .event__event-info {
      margin-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .event__event-info {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event__event-info {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .event__event-info {
      margin-right: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .event__event-info {
      margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .event__event-info {
      margin-top: calc(48px + 0px); } }

.event__event-info-item {
  margin-right: calc(16px / 4);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }
  @media screen and (min-width: 768px) {
    .event__event-info-item {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .event__event-info-item {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .event__event-info-item {
      margin-right: calc(80px / 4); } }

.event__event-info-item-label {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.event__event-info-item-link {
  text-decoration: underline; }

.event__agenda {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  margin-top: calc(48px + 0px);
  margin-bottom: calc(48px + 0px);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .event__agenda {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event__agenda {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .event__agenda {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .event__agenda {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event__agenda {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .event__agenda {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .event__agenda {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .event__agenda {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event__agenda {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .event__agenda {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .event__agenda {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .event__agenda {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .event__agenda {
      margin-bottom: calc(120px + 0px); } }

.event__agenda-title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.event__agenda .event__agenda-header--selected .featured-link__wrapper {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .event__agenda .event__agenda-header--selected .featured-link__wrapper {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .event__agenda .event__agenda-header--selected .featured-link__wrapper {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .event__agenda .event__agenda-header--selected .featured-link__wrapper {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .event__agenda .event__agenda-header--selected .featured-link__wrapper {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .event__agenda .event__agenda-header--selected .featured-link__wrapper {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .event__agenda .event__agenda-header--selected .featured-link__wrapper {
      padding-left: calc(80px / 2); } }

.event__agenda-header:not(:last-child) .featured-link__wrapper {
  border-bottom: 0; }

.event__agenda-header--selected {
  background: #ee2537;
  background: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.event__agenda-header--selected .iconography {
  fill: #fff;
  fill: var(--color-secondary);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.event__agenda-header .featured-link__column {
  padding-left: 0;
  padding-right: 0; }

.event__agenda-table {
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black);
  width: 100%; }

.event__agenda-table-header {
  padding-right: calc(16px / 8);
  padding-left: calc(16px / 8);
  color: #ee2537;
  color: var(--color-main);
  padding-bottom: 16px;
  padding-bottom: var(--space-between-xxsmall);
  padding-top: 16px;
  padding-top: var(--space-between-xxsmall);
  text-align: left; }
  @media screen and (min-width: 768px) {
    .event__agenda-table-header {
      padding-right: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .event__agenda-table-header {
      padding-right: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .event__agenda-table-header {
      padding-right: calc(80px / 8); } }
  @media screen and (min-width: 768px) {
    .event__agenda-table-header {
      padding-left: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .event__agenda-table-header {
      padding-left: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .event__agenda-table-header {
      padding-left: calc(80px / 8); } }

.event__agenda-table-cell {
  padding-right: calc(16px / 8);
  padding-left: calc(16px / 8);
  padding-bottom: 24px;
  padding-bottom: var(--space-between-xsmall);
  padding-top: 24px;
  padding-top: var(--space-between-xsmall);
  text-transform: none;
  text-transform: initial; }
  @media screen and (min-width: 768px) {
    .event__agenda-table-cell {
      padding-right: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .event__agenda-table-cell {
      padding-right: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .event__agenda-table-cell {
      padding-right: calc(80px / 8); } }
  @media screen and (min-width: 768px) {
    .event__agenda-table-cell {
      padding-left: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .event__agenda-table-cell {
      padding-left: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .event__agenda-table-cell {
      padding-left: calc(80px / 8); } }

.event__agenda-table-row {
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black); }

.event__agenda-table-row:nth-child(odd) {
  background: #f7f6f5;
  background: var(--color-desert-storm); }

.event__agenda-table-cell {
  width: calc(1 / 3 * 100%); }

@media screen and (min-width: 768px) {
  .event__event-info-item:nth-child(even) {
    margin-left: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .event__event-info-item:nth-child(even) {
      margin-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .event__event-info-item:nth-child(even) {
      margin-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .event__event-info-item:nth-child(even) {
      margin-left: calc(80px / 4); } }

@media screen and (min-width: 768px) {
  .event__agenda-table-cell:first-child {
    width: 30%; }
  .event__agenda-table-cell:nth-child(2) {
    width: 35%; }
  .event__agenda-table-cell:nth-child(3) {
    width: 35%; } }

@media screen and (min-width: 1280px) {
  .event__agenda-table-cell:first-child {
    width: 15%; }
  .event__agenda-table-cell:nth-child(2) {
    width: 45%; }
  .event__agenda-table-cell:nth-child(3) {
    width: 40%; } }

@media screen and (min-width: 1920px) {
  .event__event-info {
    margin-left: auto;
    margin-right: auto; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.featured-preview .featured-preview__item {
  padding-bottom: calc(32px + 0px);
  padding-top: calc(32px + 0px);
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  flex-direction: column;
  margin-left: 0;
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .featured-preview .featured-preview__item {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .featured-preview .featured-preview__item {
      padding-top: calc(48px + 0px); } }

.featured-preview .featured-preview__item:first-child {
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black); }

.featured-preview__image {
  padding-left: 0;
  width: 100%; }

.featured-preview__content {
  width: 100%; }

.featured-preview__content-cta {
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.featured-preview__item .featured-preview-card__picture-image--link .picture__image {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.featured-preview__item:hover .featured-preview-card__picture-image--link .picture__image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform: var(--previews-zoom-transform);
          transform: var(--previews-zoom-transform);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

@media screen and (min-width: 768px) {
  .featured-preview-card__picture-image {
    height: 100%; }
  .featured-preview .featured-preview__item {
    flex-direction: row; }
  .featured-preview__image,
  .featured-preview__content {
    width: 50%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.featured-preview {
  padding-bottom: calc(32px + 0px);
  margin-top: calc(32px + 0px);
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .featured-preview {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .featured-preview {
      margin-top: calc(48px + 0px); } }

.featured-preview .featured-preview__title {
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  display: block;
  width: 100%; }

.featured-preview__show-button {
  margin-bottom: calc(48px + 0px);
  align-items: center;
  color: #ee2537;
  color: var(--color-main);
  cursor: pointer;
  display: flex; }
  @media screen and (min-width: 1280px) {
    .featured-preview__show-button {
      margin-bottom: calc(64px + 0px); } }

.featured-preview__show-button:hover {
  text-decoration: underline; }

.featured-preview__icon {
  margin-left: calc(16px / 8);
  fill: #ee2537;
  fill: var(--color-main); }
  @media screen and (min-width: 768px) {
    .featured-preview__icon {
      margin-left: calc(32px / 8); } }
  @media screen and (min-width: 1440px) {
    .featured-preview__icon {
      margin-left: calc(48px / 8); } }
  @media screen and (min-width: 1680px) {
    .featured-preview__icon {
      margin-left: calc(80px / 8); } }

.featured-preview__picture-image {
  height: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.infrastructure__main-excerpt {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .infrastructure__main-excerpt {
      margin-bottom: calc(64px + 0px); } }

.infrastructure .news-agenda-module__related-news .related-content-cards {
  margin-top: calc(48px + 0px);
  padding: 0; }
  @media screen and (min-width: 768px) {
    .infrastructure .news-agenda-module__related-news .related-content-cards {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .infrastructure .news-agenda-module__related-news .related-content-cards {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .infrastructure .news-agenda-module__related-news .related-content-cards {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .infrastructure .news-agenda-module__related-news .related-content-cards {
      margin-top: calc(120px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-story-preview {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .inspiring-story-preview {
      margin-bottom: calc(48px + 0px); } }

.inspiring-story-preview__content {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  background: #27251f;
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-bottom: var(--space-between-xsmall);
  padding-top: 24px;
  padding-top: var(--space-between-xsmall);
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 768px) {
    .inspiring-story-preview__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-preview__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-preview__content {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .inspiring-story-preview__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-preview__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-preview__content {
      padding-left: calc(80px / 2); } }

.inspiring-story-preview__content::after {
  background: #ee2537;
  background: var(--color-main);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform var(--transition-timing-faster) ease-in-out;
  transition: transform var(--transition-timing-faster) ease-in-out;
  transition: transform var(--transition-timing-faster) ease-in-out, -webkit-transform var(--transition-timing-faster) ease-in-out;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.inspiring-story-preview:hover .inspiring-story-preview__content::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.inspiring-story-preview:hover .inspiring-story-preview__content {
  color: #fff;
  color: var(--color-secondary);
  transition-delay: 0.1s; }

.inspiring-story-preview:hover .title-text-component__title {
  color: #fff;
  color: var(--color-secondary);
  transition-delay: 0.1s; }

.inspiring-story-preview__content .title-text-component__title {
  color: #ee2537;
  color: var(--color-main);
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall); }

.inspiring-story-preview__content .title-text-component__content {
  color: #fff;
  color: var(--color-secondary); }

.inspiring-story-preview__logo {
  margin-top: calc(32px + 0px);
  align-items: flex-end;
  display: flex;
  height: 50px;
  height: var(--inspiring-stories-logo);
  justify-content: flex-end; }
  @media screen and (min-width: 1024px) {
    .inspiring-story-preview__logo {
      margin-top: calc(48px + 0px); } }

.inspiring-story-preview__logo-image {
  max-width: 50%; }

.inspiring-story-preview .inspiring-story-preview__picture-image .picture__image {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }

.inspiring-story-preview:hover .inspiring-story-preview__picture-image .picture__image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform: var(--previews-zoom-transform);
          transform: var(--previews-zoom-transform);
  transition: -webkit-transform 1s ease-out;
  transition: transform 1s ease-out;
  transition: transform 1s ease-out, -webkit-transform 1s ease-out; }

@media screen and (min-width: 768px) {
  .inspiring-story-preview {
    flex-direction: row; }
  .inspiring-story-preview__content {
    padding-right: calc(16px / 4);
    padding-left: calc(16px / 4);
    background: #27251f;
    background: var(--color-black);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 55%;
    width: 55%; } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .inspiring-story-preview__content {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .inspiring-story-preview__content {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .inspiring-story-preview__content {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .inspiring-story-preview__content {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .inspiring-story-preview__content {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .inspiring-story-preview__content {
      padding-left: calc(80px / 4); } }

@media screen and (min-width: 768px) {
  .inspiring-story-preview__picture {
    min-width: 45%;
    width: 45%; }
  .inspiring-story-preview__picture-image {
    height: 100%; }
  .inspiring-story-preview__logo {
    margin-top: 32px;
    margin-top: var(--space-between-small);
    width: 100%; }
  .inspiring-story-preview__logo-image {
    max-width: none;
    width: 50%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-story-hero-quote {
  padding-top: calc(48px + 0px);
  padding-bottom: calc(48px + 0px);
  background: #27251f;
  background: var(--color-black);
  color: #fff;
  color: var(--color-secondary); }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero-quote {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero-quote {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story-hero-quote {
      padding-top: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero-quote {
      padding-top: calc(120px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero-quote {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero-quote {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story-hero-quote {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero-quote {
      padding-bottom: calc(120px + 0px); } }

.inspiring-story-hero-quote__row {
  flex-direction: column-reverse;
  justify-content: space-between; }

.inspiring-story-hero-quote__logo-picture {
  margin-bottom: calc(32px + 0px);
  max-width: 210px; }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero-quote__logo-picture {
      margin-bottom: calc(48px + 0px); } }

.inspiring-story-hero-quote__author,
.inspiring-story-hero-quote__position {
  display: flex;
  justify-content: flex-end;
  text-align: right; }

.inspiring-story-hero-quote__author {
  margin-top: 32px;
  margin-top: var(--space-between-small); }

@media screen and (min-width: 1024px) {
  .inspiring-story-hero-quote__row {
    flex-direction: row; }
  .inspiring-story-hero-quote__logo-picture {
    margin: auto;
    max-width: 300px; }
  .inspiring-story-hero-quote__text {
    width: calc(8 / 12 * 100%); }
  .inspiring-story-hero-quote__logo {
    width: calc(4 / 12 * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-story-hero {
  margin-bottom: calc(48px + 0px);
  color: #fff;
  color: var(--color-secondary);
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story-hero {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero {
      margin-bottom: calc(120px + 0px); } }

.inspiring-story-hero .inspiring-story-hero__tags {
  margin-top: calc(64px + 0px);
  margin-bottom: calc(48px + 0px);
  margin-bottom: 0; }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-top: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-top: calc(160px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-bottom: calc(80px + 0px); } }

.inspiring-story-hero .related-tags__grid {
  margin-left: calc(16px / -2); }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero .related-tags__grid {
      margin-left: calc(32px / -2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero .related-tags__grid {
      margin-left: calc(48px / -2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero .related-tags__grid {
      margin-left: calc(80px / -2); } }

.inspiring-story-hero__image {
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  background: var(--linear-gradient-black);
  overflow: hidden;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 32px;
  padding-top: var(--space-between-small); }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero__image {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__image {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero__image {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero__image {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__image {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero__image {
      padding-right: calc(80px + 0px); } }

.inspiring-story-hero__picture {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -5; }

.inspiring-story-hero__category {
  color: #ee2537;
  color: var(--color-main); }

.inspiring-story-hero__modal .modal-close {
  top: calc(16px + 0px);
  right: calc(16px + 0px);
  position: absolute; }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero__modal .modal-close {
      top: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__modal .modal-close {
      top: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero__modal .modal-close {
      top: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero__modal .modal-close {
      right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__modal .modal-close {
      right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero__modal .modal-close {
      right: calc(80px + 0px); } }

.inspiring-story-hero__modal-video {
  display: block;
  height: 100%;
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.inspiring-story-hero__modal-video iframe {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%; }

.inspiring-story-hero__image .breadcrumbs {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.inspiring-story-hero__title {
  color: #fff;
  color: var(--color-secondary); }

.inspiring-story-hero__video {
  width: calc(56px + 0px);
  margin-top: calc(32px + 0px);
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  padding-bottom: 8px;
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: 8px;
  padding-top: var(--space-between-xxxsmall); }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__video {
      width: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-story-hero__video {
      margin-top: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero__video {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__video {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero__video {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .inspiring-story-hero__video {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story-hero__video {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-story-hero__video {
      padding-left: calc(80px / 2); } }

.inspiring-story-hero__video .iconography {
  fill: #fff;
  fill: var(--color-secondary); }

.inspiring-story-hero__video:hover .iconography {
  fill: #27251f;
  fill: var(--color-black); }

@media screen and (min-width: 768px) {
  .inspiring-story-hero__video {
    width: calc(3 / 12 * 100%); } }

@media screen and (min-width: 1024px) {
  .inspiring-story-hero__category {
    margin-top: 24px;
    margin-top: var(--space-between-xsmall); }
  .inspiring-story-hero .inspiring-story-hero__tags {
    margin-top: calc(120px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .inspiring-story-hero .inspiring-story-hero__tags {
      margin-top: calc(240px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-story .main-layout {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .inspiring-story .main-layout {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story .main-layout {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story .main-layout {
      margin-bottom: calc(80px + 0px); } }

.inspiring-story .inspiring-stories__related-stories {
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .inspiring-story .inspiring-stories__related-stories {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-story .inspiring-stories__related-stories {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-story .inspiring-stories__related-stories {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-story .inspiring-stories__related-stories {
      padding-bottom: calc(120px + 0px); } }

.inspiring-story .inspiring-story-preview {
  margin-bottom: 0; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.infrastructures {
  margin-bottom: calc(32px + 0px);
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  padding-bottom: calc(48px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  padding-top: 24px;
  padding-top: var(--space-between-xsmall); }
  @media screen and (min-width: 1024px) {
    .infrastructures {
      margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .infrastructures {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .infrastructures {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .infrastructures {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .infrastructures {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .infrastructures {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .infrastructures {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) {
    .infrastructures {
      padding-bottom: calc(64px + 0px); } }

.infrastructures__title {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.infrastructures__item {
  display: flex;
  width: 100%; }

.infrastructures__item:not(:last-child) {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.infrastructures__link,
.infrastructure__page-text {
  margin-bottom: 8px;
  margin-bottom: var(--space-between-xxxsmall);
  text-decoration: underline; }

.infrastructures__icon {
  margin-right: calc(16px / 4); }
  @media screen and (min-width: 768px) {
    .infrastructures__icon {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .infrastructures__icon {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .infrastructures__icon {
      margin-right: calc(80px / 4); } }

.infrastructures__icon svg {
  fill: #27251f;
  fill: var(--color-black); }

@media screen and (min-width: 1280px) {
  .infrastructures {
    padding-right: calc(16px / 2);
    padding-left: calc(16px / 2);
    padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .infrastructures {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .infrastructures {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .infrastructures {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .infrastructures {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .infrastructures {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .infrastructures {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .infrastructures {
      padding-bottom: calc(48px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.lab-service__main-excerpt {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .lab-service__main-excerpt {
      margin-bottom: calc(64px + 0px); } }

.lab-service .news-agenda-module {
  margin-top: calc(48px + 0px);
  padding-bottom: 0; }
  @media screen and (min-width: 768px) {
    .lab-service .news-agenda-module {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .lab-service .news-agenda-module {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .lab-service .news-agenda-module {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .lab-service .news-agenda-module {
      margin-top: calc(120px + 0px); } }

.lab-service .news-agenda-module__related-news .related-content-cards {
  margin-top: 0;
  padding: 0; }

.lab-service .related-content-cards {
  padding-top: calc(48px + 0px);
  margin-top: 0; }
  @media screen and (min-width: 1024px) {
    .lab-service .related-content-cards {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .lab-service .related-content-cards {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .lab-service .related-content-cards {
      padding-top: calc(80px + 0px); } }

.lab-service .media-gallery {
  margin-top: 0; }

@media screen and (min-width: 1280px) {
  .lab-service .related-content-cards {
    margin-top: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .lab-service .related-content-cards {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .lab-service .related-content-cards {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .lab-service .related-content-cards {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .lab-service .related-content-cards {
      margin-top: calc(120px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.press-release .hero,
.press-release__download {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .press-release .hero,
    .press-release__download {
      margin-bottom: calc(48px + 0px); } }

.press-release .related-tags {
  margin-bottom: 0; }

.press-release .related-content-cards {
  margin-top: 0; }

.press-release__related-press-releases {
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .press-release__related-press-releases {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .press-release__related-press-releases {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .press-release__related-press-releases {
      padding-bottom: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.related-areas {
  padding-bottom: calc(48px + 0px);
  margin-top: calc(48px + 0px);
  padding-top: calc(48px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm); }
  @media screen and (min-width: 768px) {
    .related-areas {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .related-areas {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-areas {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-areas {
      padding-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 768px) {
    .related-areas {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .related-areas {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-areas {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-areas {
      margin-top: calc(120px + 0px); } }
  @media screen and (min-width: 1280px) {
    .related-areas {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-areas {
      padding-top: calc(72px + 0px); } }

.related-areas__grid-item:not(:last-child) .featured-link__wrapper {
  border-bottom: none; }

.related-areas .related-areas__title {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .related-areas .related-areas__title {
      margin-bottom: calc(48px + 0px); } }

.related-areas--purple-dark-blue {
  --color-main: var(--gradient-purple-dark-blue); }

.related-areas--fuchsia-pink {
  --color-main: var(--gradient-fuchsia-pink); }

.related-areas--garnet-salmon {
  --color-main: var(--gradient-garnet-salmon); }

.related-areas--salmon-turquoise {
  --color-main: var(--gradient-salmon-turquoise); }

.related-areas--garnet-dark-blue {
  --color-main: var(--gradient-garnet-dark-blue); }

.related-areas--light-pink-purple {
  --color-main: var(--gradient-light-pink-purple); }

.related-areas--yellow-orange {
  --color-main: var(--gradient-yellow-orange); }

.related-areas--orange-red {
  --color-main: var(--gradient-orange-red); }

.related-areas--turquoise-green {
  --color-main: var(--gradient-turquoise-green); }

.related-areas--light-blue-dark-blue {
  --color-main: var(--gradient-light-blue-dark-blue); }

.related-areas--turquoise-dark-blue {
  --color-main: var(--gradient-turquoise-dark-blue); }

.related-areas--turquoise-yellow {
  --color-main: var(--gradient-turquoise-yellow); }

.related-areas--light-yellow-yellow {
  --color-main: var(--gradient-light-yellow-yellow); }

.related-areas--yellow {
  --color-main: var(--gradient-yellow); }

.related-areas--red {
  --color-main: var(--gradient-red); }

.related-areas--fuchsia {
  --color-main: var(--gradient-fuchsia); }

.related-areas--dark-blue {
  --color-main: var(--gradient-dark-blue); }

.related-areas--light-blue {
  --color-main: var(--gradient-light-blue); }

.related-areas--light-green {
  --color-main: var(--gradient-light-green); }

@media screen and (min-width: 768px) {
  .related-areas__grid-item .featured-link__wrapper {
    border-bottom: none; }
  .related-areas__grid-item:last-child .featured-link__wrapper,
  .related-areas__grid-item:nth-last-child(2) .featured-link__wrapper {
    border-bottom: 2px solid #27251f;
    border-bottom: var(--dimension-border) solid var(--color-black); } }

@media screen and (min-width: 768px) {
  .related-areas__item {
    padding-right: calc(16px / 4);
    padding-left: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .related-areas__item {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .related-areas__item {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .related-areas__item {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .related-areas__item {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .related-areas__item {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .related-areas__item {
      padding-left: calc(80px / 4); } }

@media screen and (min-width: 768px) {
  .related-areas__grid-item:nth-last-child(2) .related-areas__item {
    border-bottom: 2px solid #27251f;
    border-bottom: var(--dimension-border) solid var(--color-black); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.related-services .slider__card > * {
  margin-right: 0; }

.related-services__slider-item {
  display: flex;
  flex-direction: column; }

.related-services .slider__buttons {
  margin-right: calc(16px / 2);
  padding-top: calc(32px + 0px); }
  @media screen and (min-width: 768px) {
    .related-services .slider__buttons {
      margin-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .related-services .slider__buttons {
      margin-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .related-services .slider__buttons {
      margin-right: calc(80px / 2); } }
  @media screen and (min-width: 1024px) {
    .related-services .slider__buttons {
      padding-top: calc(48px + 0px); } }

.related-services__title {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 32px;
  padding-top: var(--space-between-small); }
  @media screen and (min-width: 768px) {
    .related-services__title {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-services__title {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-services__title {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .related-services__title {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-services__title {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-services__title {
      padding-left: calc(80px + 0px); } }

.related-services__slider {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  background: #27251f;
  background: var(--color-black); }
  @media screen and (min-width: 768px) {
    .related-services__slider {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-services__slider {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-services__slider {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .related-services__slider {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-services__slider {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-services__slider {
      padding-left: calc(80px + 0px); } }

.related-services__content {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .related-services__content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-services__content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-services__content {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .related-services__content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .related-services__content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .related-services__content {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .related-services__content {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .related-services__content {
      padding-bottom: calc(48px + 0px); } }

.related-services__image {
  width: 100%; }

.related-services__button {
  margin-top: calc(32px + 0px);
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .related-services__button {
      margin-top: calc(48px + 0px); } }

.related-services__text {
  color: #fff;
  color: var(--color-secondary); }

@media screen and (min-width: 1024px) {
  .related-services__slider {
    padding-left: 0; }
  .related-services__slider .slider__buttons {
    margin-left: calc(16px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .related-services__slider .slider__buttons {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .related-services__slider .slider__buttons {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .related-services__slider .slider__buttons {
      margin-left: calc(80px + 0px); } }

@media screen and (min-width: 1024px) {
  .related-services__slider-item {
    flex-direction: row; }
  .related-services__image {
    width: 50%; }
  .related-services__content {
    width: 50%; }
  .related-services__slider {
    padding-right: 0; }
  .related-services__button {
    width: 33%; } }

@media screen and (min-width: 1440px) {
  .related-services__content {
    padding-left: calc(16px / 2); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .related-services__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .related-services__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1440px) and (min-width: 1680px) {
    .related-services__content {
      padding-left: calc(80px / 2); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.ambit__technologies {
  margin-top: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .ambit__technologies {
      margin-top: calc(64px + 0px); } }

.ambit__top {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .ambit__top {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .ambit__top {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .ambit__top {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .ambit__top {
      margin-bottom: calc(120px + 0px); } }

.ambit__technologies-cards {
  margin-top: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .ambit__technologies-cards {
      margin-top: calc(48px + 0px); } }

.ambit__main-content {
  margin-top: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .ambit__main-content {
      margin-top: calc(48px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.expert-social {
  margin-bottom: calc(32px + 0px);
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  padding-bottom: calc(48px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  padding-top: 24px;
  padding-top: var(--space-between-xsmall); }
  @media screen and (min-width: 1024px) {
    .expert-social {
      margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .expert-social {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert-social {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert-social {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .expert-social {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert-social {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert-social {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) {
    .expert-social {
      padding-bottom: calc(64px + 0px); } }

.expert-social__title {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.expert-social__linkedin,
.expert-social__research-gate {
  display: flex; }

.expert-social__linkedin {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.expert-social__linkedin:hover a,
.expert-social__linkedin:hover svg,
.expert-social__research-gate:hover a,
.expert-social__research-gate:hover svg {
  color: #ee2537;
  color: var(--color-main);
  fill: #ee2537;
  fill: var(--color-main); }

.expert-social__link {
  text-decoration: underline; }

.expert-social__icon {
  margin-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .expert-social__icon {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert-social__icon {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert-social__icon {
      margin-right: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .expert-social__icon {
    margin-right: calc(16px / 2); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .expert-social__icon {
      margin-right: calc(32px / 2); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .expert-social__icon {
      margin-right: calc(48px / 2); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .expert-social__icon {
      margin-right: calc(80px / 2); } }

@media screen and (min-width: 1280px) {
  .expert-social {
    padding-right: calc(16px / 2);
    padding-left: calc(16px / 2);
    padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .expert-social {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .expert-social {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .expert-social {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .expert-social {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .expert-social {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .expert-social {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .expert-social {
      padding-bottom: calc(48px + 0px); } }

@media screen and (min-width: 1440px) {
  .expert-social__icon {
    margin-right: calc(16px / 4); } }
  @media screen and (min-width: 1440px) and (min-width: 768px) {
    .expert-social__icon {
      margin-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) and (min-width: 1440px) {
    .expert-social__icon {
      margin-right: calc(48px / 4); } }
  @media screen and (min-width: 1440px) and (min-width: 1680px) {
    .expert-social__icon {
      margin-right: calc(80px / 4); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.expert__hero-main-content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

.expert__hero-main-content > div {
  flex: 1; }

.expert__content {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .expert__content {
      margin-bottom: calc(64px + 0px); } }

.expert .related-content-cards {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  margin-top: 0; }
  @media screen and (min-width: 768px) {
    .expert .related-content-cards {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert .related-content-cards {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert .related-content-cards {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .expert .related-content-cards {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .expert .related-content-cards {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .expert .related-content-cards {
      padding-left: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .expert__hero-main-content {
    flex-direction: row;
    justify-content: space-between; } }

@media screen and (min-width: 1280px) {
  .expert .related-content-cards {
    margin-bottom: calc(48px + 0px);
    padding-top: calc(16px + 0px);
    padding-bottom: calc(16px + 0px);
    padding-right: calc(48px + 0px);
    padding-left: calc(48px + 0px);
    margin-top: 0; } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .expert .related-content-cards {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .expert .related-content-cards {
      padding-top: calc(32px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .expert .related-content-cards {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .expert .related-content-cards {
      padding-top: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 768px) {
    .expert .related-content-cards {
      padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .expert .related-content-cards {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1680px) {
    .expert .related-content-cards {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .expert .related-content-cards {
      padding-right: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .expert .related-content-cards {
      padding-left: calc(64px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.technology__content,
.technology__main-excerpt {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .technology__content,
    .technology__main-excerpt {
      margin-bottom: calc(48px + 0px); } }

.technology .main-layout {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .technology .main-layout {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .technology .main-layout {
      margin-bottom: calc(72px + 0px); } }

.technology .news-agenda-module {
  margin-top: calc(32px + 0px);
  padding-bottom: 0; }
  @media screen and (min-width: 1024px) {
    .technology .news-agenda-module {
      margin-top: calc(48px + 0px); } }

.technology .news-agenda-module__related-news {
  margin-top: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .technology .news-agenda-module__related-news {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .technology .news-agenda-module__related-news {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .technology .news-agenda-module__related-news {
      margin-top: calc(80px + 0px); } }

.technology .related-news .related-content-cards {
  margin-top: 0;
  padding: 0; }

.technology .technology__related-technologies {
  padding-bottom: calc(64px + 0px); }
  @media screen and (min-width: 1024px) {
    .technology .technology__related-technologies {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .technology .technology__related-technologies {
      padding-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .technology .technology__related-technologies {
      padding-bottom: calc(160px + 0px); } }
  .technology .technology__related-technologies .generic-preview {
    margin-bottom: 0; }

@media screen and (min-width: 1280px) {
  .technology .news-agenda-module {
    margin-top: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .technology .news-agenda-module {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .technology .news-agenda-module {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .technology .news-agenda-module {
      margin-top: calc(80px + 0px); } }

@media screen and (min-width: 1280px) {
  .technology .news-agenda-module__related-news,
  .technology__technologies .related-content-cards {
    margin-top: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.news__main-excerpt {
  font-style: italic;
  font-weight: bold; }

.news__main-excerpt {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .news__main-excerpt {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news__main-excerpt {
      margin-bottom: calc(72px + 0px); } }

.news .news-agenda-module {
  margin-bottom: 0; }
  .news .news-agenda-module .generic-preview {
    margin-bottom: 0; }

.news .news-agenda-module {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .news .news-agenda-module {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .news .news-agenda-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .news .news-agenda-module {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news .news-agenda-module {
      margin-bottom: calc(120px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.talent-module {
  margin-bottom: calc(64px + 0px);
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  max-height: 850px;
  max-height: var(--horizontal-talent-module-max-height);
  text-align: center; }
  @media screen and (min-width: 1024px) {
    .talent-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .talent-module {
      margin-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .talent-module {
      margin-bottom: calc(160px + 0px); } }

.talent-module__column-content {
  padding-top: calc(64px + 0px);
  padding-bottom: calc(48px + 0px);
  background: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  background: var(--linear-gradient);
  display: flex;
  justify-content: center; }
  @media screen and (min-width: 1024px) {
    .talent-module__column-content {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .talent-module__column-content {
      padding-top: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .talent-module__column-content {
      padding-top: calc(160px + 0px); } }
  @media screen and (min-width: 768px) {
    .talent-module__column-content {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .talent-module__column-content {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .talent-module__column-content {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .talent-module__column-content {
      padding-bottom: calc(120px + 0px); } }

.talent-module__content {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .talent-module__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .talent-module__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .talent-module__content {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .talent-module__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .talent-module__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .talent-module__content {
      padding-left: calc(80px / 2); } }

.talent-module__content-desc,
.talent-module__content-header,
.talent-module__content-text {
  width: 100%; }

.talent-module__content-header {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .talent-module__content-header {
      margin-bottom: calc(48px + 0px); } }

.talent-module__content-category {
  justify-content: center;
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.talent-module__content-text {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }

.talent-module__picture,
.talent-module__image {
  height: 100%; }

.talent-module__content-button {
  margin-top: calc(48px + 0px);
  width: 100%; }
  @media screen and (min-width: 1280px) {
    .talent-module__content-button {
      margin-top: calc(64px + 0px); } }

.talent-module__content-button .button {
  width: 100%; }

@media screen and (min-width: 768px) {
  .talent-module__content-text {
    width: calc(4 / 6 * 100%); } }

@media screen and (min-width: 1024px) {
  .talent-module {
    flex-direction: row; }
  .talent-module--vertical {
    flex-direction: column; }
  .talent-module__column {
    width: 50%; }
  .talent-module__content-button {
    width: calc(3 / 4 * 100%); } }

@media screen and (min-width: 1600px) {
  .talent-module__content-text {
    width: calc(4 / 6 * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.contact-module__content {
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .contact-module__content {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .contact-module__content {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .contact-module__content {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .contact-module__content {
      padding-bottom: calc(120px + 0px); } }

.contact-module__content-category {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.contact-module__content-text {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small);
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.contact-module__buttons .contact-module__button {
  display: block;
  width: 100%; }

.contact-module__buttons .contact-module__button:not(:last-child) {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.contact-module__buttons .button {
  width: 100%; }

@media screen and (min-width: 1024px) {
  .contact-module__content {
    width: calc(8 / 12 * 100%); }
  .contact-module__buttons {
    display: flex; }
  .contact-module__buttons .contact-module__button:not(:last-child) {
    margin-bottom: 0; }
  .contact-module__buttons .contact-module__button {
    margin-right: calc(16px + 0px);
    max-width: calc(3 / 8 * 100%); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .contact-module__buttons .contact-module__button {
      margin-right: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .contact-module__buttons .contact-module__button {
      margin-right: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .contact-module__buttons .contact-module__button {
      margin-right: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.services-module {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .services-module {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .services-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .services-module {
      margin-bottom: calc(80px + 0px); } }

.services-module__header {
  width: calc(11 / 12 * 100%); }

.services-module__items {
  width: 100%; }

.services-module__header-category {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.services-module__buttons {
  margin-top: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall);
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .services-module__buttons {
      margin-top: calc(48px + 0px); } }

.services-module__buttons--selected {
  background: #ee2537;
  background: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.services-module__content {
  width: 100%; }

.services-module__content > .services-module__content-wrapper {
  flex-direction: column-reverse;
  margin: 0;
  max-width: 100%; }

.services-module__content-text {
  margin: auto 0;
  padding: 0; }

.services-module__content-text-title {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall);
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.services-module__content-text-text {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.services-module__buttons .button:first-child {
  border-bottom: 0; }

.services-module__buttons .button:last-child {
  border-top: 0; }

.services-module__content-picture {
  width: 100%; }

@media screen and (min-width: 768px) {
  .services-module__header {
    width: calc(8 / 12 * 100%); } }

@media screen and (min-width: 1024px) {
  .services-module__content > .services-module__content-wrapper {
    flex-direction: row; }
  .service-module__button {
    margin-left: 0;
    margin-right: 0; }
  .services-module__buttons {
    margin-bottom: calc(32px + 0px);
    flex-direction: row; } }
  @media screen and (min-width: 1024px) and (min-width: 1024px) {
    .services-module__buttons {
      margin-bottom: calc(48px + 0px); } }

@media screen and (min-width: 1024px) {
  .services-module__buttons .button:first-child {
    border-bottom: 2px solid #ee2537;
    border-bottom: var(--dimension-border) solid var(--color-main);
    border-right: 0; }
  .services-module__buttons .button:last-child {
    border-left: 0;
    border-top: 2px solid #ee2537;
    border-top: var(--dimension-border) solid var(--color-main); }
  .services-module__content-wrapper {
    flex-direction: row; }
  .services-module__content-picture {
    padding-right: calc(16px / 2);
    width: calc(2 / 3 * 100%); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .services-module__content-picture {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .services-module__content-picture {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .services-module__content-picture {
      padding-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
  .services-module__content-text {
    padding-right: calc(16px / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(1 / 3 * 100%); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .services-module__content-text {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .services-module__content-text {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .services-module__content-text {
      padding-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
  .services-module__content-text-title {
    margin-top: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.technologies-module {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .technologies-module {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .technologies-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .technologies-module {
      margin-bottom: calc(80px + 0px); } }

.technologies-module__header {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.technologies-module__items {
  width: 100%; }

.technologies-module__header-category {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.technologies-module__items-wrapper {
  position: relative;
  transition: all 1s ease-in-out;
  transition-delay: 0.3s; }

.technologies-module__items .technologies-module__items-wrapper:not(:last-child) .featured-link__wrapper {
  border-bottom: none; }

.technologies-module__items-wrapper .featured-link__wrapper::after {
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  width: 100%;
  z-index: -1; }

.featured-link:hover .featured-link__wrapper::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.technologies-module__items-wrapper--active {
  width: 100%; }

.technologies-module__items-wrapper--active .featured-link__wrapper {
  padding-right: calc(16px / 4);
  padding-left: calc(16px / 4);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .technologies-module__items-wrapper--active .featured-link__wrapper {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .technologies-module__items-wrapper--active .featured-link__wrapper {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .technologies-module__items-wrapper--active .featured-link__wrapper {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) {
    .technologies-module__items-wrapper--active .featured-link__wrapper {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .technologies-module__items-wrapper--active .featured-link__wrapper {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .technologies-module__items-wrapper--active .featured-link__wrapper {
      padding-left: calc(80px / 4); } }

.technologies-module__items-wrapper--active .featured-link__wrapper::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.technologies-module__items-wrapper--active .featured-link {
  color: #fff;
  color: var(--color-secondary); }

.technologies-module__items-wrapper--active .featured-link__icon {
  fill: #fff;
  fill: var(--color-secondary);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.technologies-module__items-technologies {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.9s; }

.technologies-module__items-technologies--active {
  max-height: 4000px;
  overflow: visible; }

.technologies-module__items-grid {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  color: #fff;
  color: var(--color-secondary);
  flex-flow: row wrap; }
  @media screen and (min-width: 1024px) {
    .technologies-module__items-grid {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .technologies-module__items-grid {
      padding-bottom: calc(48px + 0px); } }

.technologies-module__items-technologies--purple-dark-blue,
.technologies-module__items-wrapper--purple-dark-blue .featured-link__wrapper::after {
  background: linear-gradient(90deg, #7e2574, #203244);
  background: var(--gradient-purple-dark-blue); }

.technologies-module__items-technologies--fuchsia-pink,
.technologies-module__items-wrapper--fuchsia-pink .featured-link__wrapper::after {
  background: linear-gradient(90deg, #c0004e, #7e2574);
  background: var(--gradient-fuchsia-pink); }

.technologies-module__items-technologies--garnet-salmon,
.technologies-module__items-wrapper--garnet-salmon .featured-link__wrapper::after {
  background: linear-gradient(90deg, #a6093d, #dc8699);
  background: var(--gradient-garnet-salmon); }

.technologies-module__items-technologies--salmon-turquoise,
.technologies-module__items-wrapper--salmon-turquoise .featured-link__wrapper::after {
  background: linear-gradient(90deg, #ff8d6d, #87c9c4);
  background: var(--gradient-salmon-turquoise); }

.technologies-module__items-technologies--garnet-dark-blue,
.technologies-module__items-wrapper--garnet-dark-blue .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #a6093d,
    #203244);
  background: var(--gradient-garnet-dark-blue); }

.technologies-module__items-technologies--light-pink-purple,
.technologies-module__items-wrapper--light-pink-purple .featured-link__wrapper::after {
  background: linear-gradient(90deg, #ffb1bb, #7e2574);
  background: var(--gradient-light-pink-purple); }

.technologies-module__items-technologies--yellow-orange,
.technologies-module__items-wrapper--yellow-orange .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #fecd00,
    #c75b12);
  background: var(--gradient-yellow-orange); }

.technologies-module__items-technologies--orange-red,
.technologies-module__items-wrapper--orange-red .featured-link__wrapper::after {
  background: linear-gradient(90deg, #c75b12, #c0004e);
  background: var(--gradient-orange-red); }

.technologies-module__items-technologies--turquoise-green,
.technologies-module__items-wrapper--turquoise-green .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #00685e);
  background: var(--gradient-turquoise-green); }

.technologies-module__items-technologies--light-blue-dark-blue,
.technologies-module__items-wrapper--light-blue-dark-blue .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #6ba4b8,
    #203244);
  background: var(--gradient-light-blue-dark-blue); }

.technologies-module__items-technologies--turquoise-dark-blue,
.technologies-module__items-wrapper--turquoise-dark-blue .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.technologies-module__items-technologies--turquoise-yellow,
.technologies-module__items-wrapper--turquoise-yellow .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #fecd00);
  background: var(--gradient-turquoise-yellow); }

.technologies-module__items-technologies--light-yellow-yellow,
.technologies-module__items-wrapper--light-yellow-yellow .featured-link__wrapper::after {
  background: linear-gradient(
    90deg,
    #f8e08e,
    #fecd00);
  background: var(--gradient-light-yellow-yellow); }

.technologies-module__items-technologies--yellow,
.technologies-module__items-wrapper--yellow .featured-link__wrapper::after {
  background: #fecd00;
  background: var(--gradient-yellow); }

.technologies-module__items-technologies--red,
.technologies-module__items-wrapper--red .featured-link__wrapper::after {
  background: #b7003e;
  background: var(--gradient-red); }

.technologies-module__items-technologies--fuchsia,
.technologies-module__items-wrapper--fuchsia .featured-link__wrapper::after {
  background: #b0f;
  background: var(--gradient-fuchsia); }

.technologies-module__items-technologies--dark-blue,
.technologies-module__items-wrapper--dark-blue .featured-link__wrapper::after {
  background: #0000f7;
  background: var(--gradient-dark-blue); }

.technologies-module__items-technologies--light-blue,
.technologies-module__items-wrapper--light-blue .featured-link__wrapper::after {
  background: #09f;
  background: var(--gradient-light-blue); }

.technologies-module__items-technologies--light-green,
.technologies-module__items-wrapper--light-green .featured-link__wrapper::after {
  background: #00db92;
  background: var(--gradient-light-green); }

.technologies-module__items-wrapper--active .featured-link__wrapper {
  border-bottom: none; }

.technologies-moudle__items-ambit {
  padding-left: calc(16px / 4);
  color: #fff;
  color: var(--color-secondary); }
  @media screen and (min-width: 768px) {
    .technologies-moudle__items-ambit {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .technologies-moudle__items-ambit {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .technologies-moudle__items-ambit {
      padding-left: calc(80px / 4); } }
  .technologies-moudle__items-ambit .technologies-module__items-ambit-content {
    padding-bottom: 32px;
    padding-bottom: var(--space-between-small); }

@media screen and (min-width: 1024px) {
  .technologies-module__header {
    width: calc(8 / 12 * 100%); }
  .technologies-moudle__items-ambit {
    flex-shrink: 0;
    width: calc((7 / 12) * 100%); }
  .technologies-module__items-technologies {
    flex-direction: row; } }

@media screen and (min-width: 1280px) {
  .technologies-module__items-wrapper {
    width: calc((7 / 12) * 100%); }
  .technologies-module__items-wrapper--active {
    width: 100%; }
  .technologies-module__items-wrapper {
    position: relative; }
  .technologies-module__items-wrapper--active .featured-link__title {
    flex: 0.55;
    position: relative; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.agenda-module {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .agenda-module {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .agenda-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .agenda-module {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .agenda-module {
      margin-bottom: calc(120px + 0px); } }

.agenda-module__column-content {
  width: 100%; }

.agenda-module__link-button {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  margin-top: 32px;
  margin-top: var(--space-between-small); }
  @media screen and (min-width: 768px) {
    .agenda-module__link-button {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .agenda-module__link-button {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .agenda-module__link-button {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .agenda-module__link-button {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .agenda-module__link-button {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .agenda-module__link-button {
      padding-left: calc(80px / 2); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-blog-hero-card {
  position: relative; }

.inspiring-blog-hero-card__image {
  --aspect-ratio-24-19: 4/3;
  -webkit-filter: brightness(80%);
          filter: brightness(80%); }
  .inspiring-blog-hero-card__image .picture {
    z-index: -1; }

.inspiring-blog-hero-card__content {
  right: calc(16px + 0px);
  left: calc(16px + 0px);
  bottom: calc(48px + 0px);
  position: absolute; }
  @media screen and (min-width: 768px) {
    .inspiring-blog-hero-card__content {
      right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-hero-card__content {
      right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-hero-card__content {
      right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-hero-card__content {
      left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-hero-card__content {
      left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-hero-card__content {
      left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-hero-card__content {
      bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-blog-hero-card__content {
      bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-blog-hero-card__content {
      bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-hero-card__content {
      bottom: calc(120px + 0px); } }

.inspiring-blog-hero-card__content-title,
.inspiring-blog-hero-card__content-category,
.inspiring-blog-hero-card__content-author {
  color: #fff;
  color: var(--color-secondary); }

.inspiring-blog-hero-card__content-title {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall);
  text-shadow: 1px 1px 2px #000; }

.inspiring-blog-hero-card__content-author {
  text-shadow: 1px 1px 2px #000; }

.inspiring-blog-hero-card__content-author,
.inspiring-blog-hero-card__content-category {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.inspiring-blog-hero-card__content-button {
  font-weight: bold;
  max-width: 320px;
  width: 100%;
  z-index: 60; }

.inspiring-blog-hero-card__date {
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  right: var(--space-between-xsmall);
  text-shadow: 1px 1px 2px #000;
  top: 16px;
  top: var(--space-between-xxsmall); }
  .inspiring-blog-hero-card__date .typography:first-child {
    margin: auto; }
  .inspiring-blog-hero-card__date .typography:last-child {
    text-transform: capitalize; }

@media screen and (min-width: 768px) {
  .inspiring-blog-hero-card__image {
    --aspect-ratio-24-19: 19/24; } }

@media screen and (min-width: 1440px) {
  .inspiring-blog-hero-card__content-title {
    line-height: 64px;
    line-height: var(--line-height-for-64-smaller); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.blog-module {
  margin-bottom: calc(48px + 0px);
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index);
  /* This is 1920px plus the row gutter, so it keeps the margins until they are needed */ }
  @media screen and (min-width: 1280px) {
    .blog-module {
      margin-bottom: calc(64px + 0px); } }
  .blog-module .slider {
    padding-left: calc(16px + 0px);
    padding-bottom: calc(16px + 0px);
    padding-top: calc(16px + 0px); }
    @media screen and (min-width: 768px) {
      .blog-module .slider {
        padding-left: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .slider {
        padding-left: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .slider {
        padding-left: calc(80px + 0px); } }
    @media screen and (min-width: 768px) {
      .blog-module .slider {
        padding-bottom: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .slider {
        padding-bottom: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .slider {
        padding-bottom: calc(80px + 0px); } }
    @media screen and (min-width: 768px) {
      .blog-module .slider {
        padding-top: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .slider {
        padding-top: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .slider {
        padding-top: calc(80px + 0px); } }
  .blog-module .slider::after {
    background: #ff5A00;
    background: var(--color-orange-blog-home);
    content: '';
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    z-index: var(--before-after-z-index); }
  .blog-module .related-content-cards {
    padding-left: 0;
    padding-right: 0; }
  .blog-module .related-content-cards__subitle,
  .blog-module .related-content-cards__title {
    padding-right: calc(16px + 0px);
    padding-left: calc(16px + 0px); }
    @media screen and (min-width: 768px) {
      .blog-module .related-content-cards__subitle,
      .blog-module .related-content-cards__title {
        padding-right: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .related-content-cards__subitle,
      .blog-module .related-content-cards__title {
        padding-right: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .related-content-cards__subitle,
      .blog-module .related-content-cards__title {
        padding-right: calc(80px + 0px); } }
    @media screen and (min-width: 768px) {
      .blog-module .related-content-cards__subitle,
      .blog-module .related-content-cards__title {
        padding-left: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .related-content-cards__subitle,
      .blog-module .related-content-cards__title {
        padding-left: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .related-content-cards__subitle,
      .blog-module .related-content-cards__title {
        padding-left: calc(80px + 0px); } }
  .blog-module button.inspiring-blog-hero-card__content-button {
    margin-top: -80px;
    max-width: 100%;
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    transition: all 0.5s ease;
    width: 100%; }
  .blog-module .slider__card:hover button.inspiring-blog-hero-card__content-button {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    margin-top: 0; }
  .blog-module .inspiring-blog-hero-card__content-title {
    line-height: 32px;
    line-height: var(--line-height-for-20-smaller);
    font-size: 24px;
    font-size: var(--font-size-24); }
  .blog-module .related-content-cards .picture[class*="picture--ratio-"] img {
    object-fit: cover;
    object-position: center; }
  .blog-module .blog-module__link-button {
    padding-right: calc(16px + 0px);
    padding-left: calc(16px + 0px);
    position: relative;
    top: calc(24px * -1);
    top: calc(var(--space-between-xsmall) * -1); }
    @media screen and (min-width: 768px) {
      .blog-module .blog-module__link-button {
        padding-right: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .blog-module__link-button {
        padding-right: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .blog-module__link-button {
        padding-right: calc(80px + 0px); } }
    @media screen and (min-width: 768px) {
      .blog-module .blog-module__link-button {
        padding-left: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .blog-module .blog-module__link-button {
        padding-left: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .blog-module .blog-module__link-button {
        padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1280px) {
    .blog-module .slider::after {
      width: 65%; }
    .blog-module .inspiring-blog-hero-card__content {
      bottom: 32px;
      bottom: var(--space-between-small);
      left: 32px;
      left: var(--space-between-small);
      right: 32px;
      right: var(--space-between-small); } }
  @media screen and (min-width: 1440px) {
    .blog-module .slider::after {
      width: 70%; }
    .blog-module .inspiring-blog-hero-card__content-title {
      line-height: 40px;
      line-height: var(--line-height-for-20); } }
  @media screen and (min-width: 1680px) {
    .blog-module .inspiring-blog-hero-card__content-title {
      font-size: 32px;
      font-size: var(--font-size-32); } }
  @media screen and (min-width: 1920px) {
    .blog-module .related-content-cards {
      padding: 0; } }
  @media screen and (min-width: 2000px) {
    .blog-module {
      margin-left: auto;
      margin-right: auto; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.news-module {
  margin-bottom: calc(48px + 0px);
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1280px) {
    .news-module {
      margin-bottom: calc(64px + 0px); } }

.news-module .slider {
  padding-bottom: calc(16px + 0px);
  padding-top: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .news-module .slider {
      padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-module .slider {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .news-module .slider {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .news-module .slider {
      padding-top: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-module .slider {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .news-module .slider {
      padding-top: calc(80px + 0px); } }

.news-module .slider::after {
  background: #27251f;
  background: var(--color-black);
  content: '';
  height: 95%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.news-module .related-content-cards {
  padding-left: 0;
  padding-right: 0; }

.news-module .related-content-cards__subitle,
.news-module .related-content-cards__title {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .news-module .related-content-cards__subitle,
    .news-module .related-content-cards__title {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-module .related-content-cards__subitle,
    .news-module .related-content-cards__title {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .news-module .related-content-cards__subitle,
    .news-module .related-content-cards__title {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .news-module .related-content-cards__subitle,
    .news-module .related-content-cards__title {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .news-module .related-content-cards__subitle,
    .news-module .related-content-cards__title {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .news-module .related-content-cards__subitle,
    .news-module .related-content-cards__title {
      padding-left: calc(80px + 0px); } }

@media screen and (min-width: 1280px) {
  .news-module .slider::after {
    width: 75%; } }

@media screen and (min-width: 1440px) {
  .news-module .slider::after {
    width: 80%; } }

@media screen and (min-width: 1920px) {
  .news-module .related-content-cards {
    padding-right: calc(16px + 0px);
    padding-left: calc(16px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .news-module .related-content-cards {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .news-module .related-content-cards {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1680px) {
    .news-module .related-content-cards {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .news-module .related-content-cards {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .news-module .related-content-cards {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1680px) {
    .news-module .related-content-cards {
      padding-left: calc(80px + 0px); } }

@media screen and (min-width: 1920px) {
  .news-module .related-content-cards__subitle,
  .news-module .related-content-cards__title {
    padding-left: 0;
    padding-right: 0; } }

/* This is 1920px plus the row gutter, so it keeps the margins until they are needed */
@media screen and (min-width: 2000px) {
  .news-module {
    margin-left: auto;
    margin-right: auto; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-numbers__number {
  color: #ee2537;
  color: var(--color-main);
  font-size: 40px;
  font-size: var(--font-size-40);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 64px;
  line-height: var(--line-height-for-40); }

@media screen and (min-width: 1024px) {
  .cms-numbers__number {
    font-size: 96px;
    font-size: var(--font-size-96);
    line-height: 112px;
    line-height: var(--line-height-for-96); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.tecnalia-module {
  overflow: visible;
  position: relative; }

.tecnalia-module .slider {
  z-index: 1; }

.tecnalia-module .slider__buttons {
  margin-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .tecnalia-module .slider__buttons {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .tecnalia-module .slider__buttons {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .tecnalia-module .slider__buttons {
      margin-left: calc(80px + 0px); } }

.tecnalia-module__info {
  padding-left: calc(16px / 2);
  display: flex;
  margin-top: calc(32px * -1);
  margin-top: calc(var(--space-between-small) * -1); }
  @media screen and (min-width: 768px) {
    .tecnalia-module__info {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .tecnalia-module__info {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .tecnalia-module__info {
      padding-left: calc(80px / 2); } }

.tecnalia-module__info-numbers {
  padding-left: calc(16px / 2);
  padding-right: calc(16px + 0px);
  width: 100%;
  z-index: 2; }
  @media screen and (min-width: 768px) {
    .tecnalia-module__info-numbers {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .tecnalia-module__info-numbers {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .tecnalia-module__info-numbers {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .tecnalia-module__info-numbers {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .tecnalia-module__info-numbers {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .tecnalia-module__info-numbers {
      padding-right: calc(80px + 0px); } }

.tecnalia-module .cms-numbers__number {
  color: #ee2537;
  color: var(--color-main);
  font-size: 48px;
  font-size: var(--font-size-48);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  letter-spacing: -2px;
  line-height: var(--line-height-for-48); }

.tecnalia-module__info-link--mobile {
  padding-left: calc(16px + 0px);
  display: inline-block;
  margin-top: 8px;
  margin-top: var(--space-between-xxxsmall); }
  @media screen and (min-width: 768px) {
    .tecnalia-module__info-link--mobile {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .tecnalia-module__info-link--mobile {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .tecnalia-module__info-link--mobile {
      padding-left: calc(80px + 0px); } }

.tecnalia-module__slide {
  margin-right: 0; }

@media screen and (min-width: 768px) {
  .tecnalia-module .slider__buttons {
    top: calc(32px + 0px);
    left: calc(16px + 0px);
    margin-left: 0;
    position: absolute; } }
  @media screen and (min-width: 768px) and (min-width: 1024px) {
    .tecnalia-module .slider__buttons {
      top: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .tecnalia-module .slider__buttons {
      left: calc(32px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .tecnalia-module .slider__buttons {
      left: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .tecnalia-module .slider__buttons {
      left: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .tecnalia-module__info {
    margin-top: calc(48px * -1); } }
  @media screen and (min-width: 768px) and (min-width: 1280px) {
    .tecnalia-module__info {
      margin-top: calc(64px * -1); } }

@media screen and (min-width: 768px) {
  .tecnalia-module__info-numbers,
  .tecnalia-module__info-link {
    width: calc((4 / 12) * 100%); }
  .tecnalia-module .tecnalia-module__info-link {
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px);
    height: 24px;
    margin-top: auto; } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .tecnalia-module .tecnalia-module__info-link {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .tecnalia-module .tecnalia-module__info-link {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .tecnalia-module .tecnalia-module__info-link {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .tecnalia-module .tecnalia-module__info-link {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .tecnalia-module .tecnalia-module__info-link {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .tecnalia-module .tecnalia-module__info-link {
      padding-right: calc(80px + 0px); } }

@media screen and (min-width: 1024px) {
  .tecnalia-module .cms-numbers__number {
    font-size: 96px;
    font-size: var(--font-size-96);
    line-height: 112px;
    line-height: var(--line-height-for-96); } }

@media screen and (min-width: 1280px) {
  .tecnalia-module .cms-numbers__number {
    font-size: 120px;
    line-height: 144px; } }

@media screen and (min-width: 1920px) {
  .tecnalia-module__info {
    margin-top: calc(48px * -1); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .tecnalia-module__info {
      margin-top: calc(64px * -1); } }
  @media screen and (min-width: 1920px) and (min-width: 1024px) {
    .tecnalia-module__info {
      margin-top: calc(72px * -1); } }
  @media screen and (min-width: 1920px) and (min-width: 1280px) {
    .tecnalia-module__info {
      margin-top: calc(80px * -1); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .tecnalia-module__info {
      margin-top: calc(120px * -1); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.homepage-hero {
  margin-bottom: calc(64px + 0px);
  max-height: 90vh;
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .homepage-hero {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .homepage-hero {
      margin-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage-hero {
      margin-bottom: calc(160px + 0px); } }

.homepage-hero__video {
  height: 100%;
  overflow: hidden;
  width: 100%;
  z-index: -1; }

.homepage-hero__search {
  padding: calc(16px + 0px);
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .homepage-hero__search {
      padding: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage-hero__search {
      padding: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .homepage-hero__search {
      padding: calc(80px + 0px); } }

.homepage-hero____search-input {
  background: #fff;
  background: var(--color-secondary); }

.homepage-hero .input__input {
  border: none;
  margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .homepage-hero .search-box__input {
    width: 100%; }
  .homepage-hero__search {
    padding: 0;
    width: calc(6 / 12 * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.homepage__agenda-module,
.homepage__blog-module {
  width: 100%; }

.homepage__agenda-blog-module {
  margin: 0; }

.homepage .services-module,
.homepage .news-module,
.homepage .agenda-module {
  margin-bottom: calc(64px + 0px); }
  @media screen and (min-width: 1024px) {
    .homepage .services-module,
    .homepage .news-module,
    .homepage .agenda-module {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .homepage .services-module,
    .homepage .news-module,
    .homepage .agenda-module {
      margin-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage .services-module,
    .homepage .news-module,
    .homepage .agenda-module {
      margin-bottom: calc(160px + 0px); } }

.homepage .news-module .related-content-cards {
  margin-top: 0; }

.homepage > :last-child {
  padding-bottom: calc(48px + 0px);
  margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    .homepage > :last-child {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .homepage > :last-child {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .homepage > :last-child {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage > :last-child {
      padding-bottom: calc(120px + 0px); } }

.homepage__agenda-blog-module .slider {
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .homepage__agenda-blog-module .slider {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage__agenda-blog-module .slider {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .homepage__agenda-blog-module .slider {
      padding-left: calc(80px + 0px); } }

.homepage__agenda-blog-module .slider__card > * {
  margin-left: calc(16px + 0px);
  margin-right: 0; }
  @media screen and (min-width: 768px) {
    .homepage__agenda-blog-module .slider__card > * {
      margin-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage__agenda-blog-module .slider__card > * {
      margin-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .homepage__agenda-blog-module .slider__card > * {
      margin-left: calc(80px + 0px); } }

.homepage__agenda-blog-module .slider__buttons {
  margin-right: 0; }

.homepage__agenda-blog-module .related-content-cards {
  padding-left: 0;
  padding-right: 0; }

.homepage__agenda-blog-module .related-content-cards__subitle,
.homepage__agenda-blog-module .related-content-cards__title {
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .homepage__agenda-blog-module .related-content-cards__subitle,
    .homepage__agenda-blog-module .related-content-cards__title {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage__agenda-blog-module .related-content-cards__subitle,
    .homepage__agenda-blog-module .related-content-cards__title {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .homepage__agenda-blog-module .related-content-cards__subitle,
    .homepage__agenda-blog-module .related-content-cards__title {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .homepage__agenda-blog-module .related-content-cards__subitle,
    .homepage__agenda-blog-module .related-content-cards__title {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .homepage__agenda-blog-module .related-content-cards__subitle,
    .homepage__agenda-blog-module .related-content-cards__title {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .homepage__agenda-blog-module .related-content-cards__subitle,
    .homepage__agenda-blog-module .related-content-cards__title {
      padding-right: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .homepage__agenda-blog-module {
    --body-max-width: 100%; }
  .homepage__agenda-module {
    width: 50%; }
  .homepage__blog-module {
    width: 50%; } }

@media screen and (min-width: 1280px) {
  .homepage__agenda-module {
    width: 35%; }
  .homepage__blog-module {
    width: 65%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.search-content {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .search-content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .search-content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .search-content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-content {
      margin-bottom: calc(120px + 0px); } }

.search-content__preview {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .search-content__preview {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-content__preview {
      margin-bottom: calc(72px + 0px); } }

.search-content__header {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(48px + 0px);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  background: #27251f;
  background: var(--color-black); }
  @media screen and (min-width: 1024px) {
    .search-content__header {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .search-content__header {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .search-content__header {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .search-content__header {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-content__header {
      padding-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 768px) {
    .search-content__header {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-content__header {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .search-content__header {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .search-content__header {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-content__header {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .search-content__header {
      padding-right: calc(80px + 0px); } }

.search-content .search-box__input {
  width: 100%; }

.search-content_results,
.hits--no-hits {
  margin-top: calc(32px + 0px);
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }
  @media screen and (min-width: 1024px) {
    .search-content_results,
    .hits--no-hits {
      margin-top: calc(48px + 0px); } }

.search-content .ais-Pagination-list {
  margin-bottom: calc(32px + 0px);
  color: #ee2537;
  color: var(--color-main);
  display: inline-flex; }
  @media screen and (min-width: 1024px) {
    .search-content .ais-Pagination-list {
      margin-bottom: calc(48px + 0px); } }

.search-content .ais-Pagination-item {
  height: calc(56px + 0px);
  width: calc(56px + 0px);
  align-items: center;
  background: none;
  border-bottom: 2px solid #ee2537;
  border-bottom: var(--dimension-border) solid var(--color-main);
  border-right: 2px solid #ee2537;
  border-right: var(--dimension-border) solid var(--color-main);
  border-top: 2px solid #ee2537;
  border-top: var(--dimension-border) solid var(--color-main);
  cursor: pointer;
  font-size: 20px;
  font-size: var(--font-size-20);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  justify-content: center;
  position: relative; }
  @media screen and (min-width: 1440px) {
    .search-content .ais-Pagination-item {
      height: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .search-content .ais-Pagination-item {
      width: calc(64px + 0px); } }

.search-content .ais-Pagination-item a,
.search-content .ais-Pagination-item span {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center; }

.search-content .ais-Pagination-item:first-child {
  border-left: 2px solid #ee2537;
  border-left: var(--dimension-border) solid var(--color-main); }

.search-content .ais-Pagination-item:hover {
  background: rgba(238, 37, 55, 0.5);
  background: var(--color-main-faded);
  color: #fff;
  color: var(--color-secondary); }

.search-content .ais-Pagination-item:hover a {
  color: #fff;
  color: var(--color-secondary); }

.search-content .ais-Pagination-link--selected {
  background-color: #ee2537;
  background-color: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.search-content__highlighted ais-highlight-0000000000 {
  font-weight: 700;
  font-weight: var(--font-weight-bold); }

.search-content__search-box {
  padding-left: 0;
  padding-right: 0; }

.search-content__item {
  width: 100%; }

.search-content .ais-InstantSearch__root {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .search-content .ais-InstantSearch__root {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .search-content .ais-InstantSearch__root {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .search-content .ais-InstantSearch__root {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .search-content .ais-InstantSearch__root {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .search-content .ais-InstantSearch__root {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .search-content .ais-InstantSearch__root {
      padding-right: calc(80px / 2); } }

.search-content .ais-Pagination-list {
  width: 100%; }

.search-content .generic-preview__content {
  border-bottom: none; }

@media screen and (min-width: 1280px) {
  .search-content .search-box__input {
    margin: 0;
    width: 50%; }
  .search-content__item .generic-preview {
    width: calc(9 / 12 * 100%); } }

@media screen and (min-width: 1920px) {
  .search-content__item .generic-preview {
    width: calc(8 / 12 * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-50-50 {
  display: flex;
  flex-direction: column-reverse;
  width: 100%; }

.cms-50-50 a {
  color: #ee2537;
  color: var(--color-main); }

.cms-50-50__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.cms-50-50__content {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  position: relative; }
  @media screen and (min-width: 768px) {
    .cms-50-50__content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .cms-50-50__content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .cms-50-50__content {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .cms-50-50__content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .cms-50-50__content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .cms-50-50__content {
      padding-left: calc(80px + 0px); } }

.cms-50-50__content,
.cms-50-50__picture {
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 32px;
  padding-top: var(--space-between-small); }

.cms-50-50__content-text,
.cms-50-50__content-title {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .cms-50-50__content-text,
    .cms-50-50__content-title {
      margin-bottom: calc(48px + 0px); } }

@media screen and (min-width: 1024px) {
  .cms-50-50 {
    flex-direction: row; }
  .cms-50-50--reverse {
    flex-direction: row-reverse; }
  .cms-50-50__content,
  .cms-50-50__picture {
    width: 50%; }
  .cms-50-50__content-wrapper {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-related-certifications__item {
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  text-align: center; }
  @media screen and (min-width: 768px) {
    .cms-related-certifications__item {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .cms-related-certifications__item {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .cms-related-certifications__item {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .cms-related-certifications__item {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .cms-related-certifications__item {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .cms-related-certifications__item {
      padding-right: calc(80px / 2); } }

.cms-related-certifications .grid__title {
  margin-bottom: 0; }

.cms-related-certifications__item-wrapper {
  margin-top: calc(32px + 0px);
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-left: calc(16px / 4);
  padding-right: calc(16px / 4);
  background: #f7f6f5;
  background: var(--color-desert-storm); }
  @media screen and (min-width: 1024px) {
    .cms-related-certifications__item-wrapper {
      margin-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .cms-related-certifications__item-wrapper {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .cms-related-certifications__item-wrapper {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .cms-related-certifications__item-wrapper {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .cms-related-certifications__item-wrapper {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .cms-related-certifications__item-wrapper {
      padding-left: calc(80px / 4); } }
  @media screen and (min-width: 768px) {
    .cms-related-certifications__item-wrapper {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .cms-related-certifications__item-wrapper {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .cms-related-certifications__item-wrapper {
      padding-right: calc(80px / 4); } }

.cms-related-certifications__item-image .picture__picture img {
  width: auto; }

.cms-related-certifications__item-title {
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-featured-link-grid .grid__item:not(:last-child) .featured-link__wrapper {
  border-bottom: 0; }

.cms-form {
  margin-top: 48px;
  margin-bottom: 48px; }

.cms-form__form {
  margin-top: 28px; }

.cms-form__column {
  width: 100%; }

.cms-form__description {
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-xxxsmall); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.cms-grid__item {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .cms-grid__item {
      margin-bottom: calc(48px + 0px); } }

.cms-grid__item .featured-link {
  margin-bottom: calc(32px * -1); }
  @media screen and (min-width: 1024px) {
    .cms-grid__item .featured-link {
      margin-bottom: calc(48px * -1); } }

@media screen and (max-width: 767px) {
  .cms-grid__item:nth-child(n + 3) .featured-link__wrapper {
    border-top: none; } }

@media screen and (max-width: 1024px) {
  .cms-grid__item:nth-child(n + 4) .featured-link__wrapper {
    border-top: none; } }

.cms-grid--desktop-2 .cms-grid__item:nth-child(n + 4) .featured-link__wrapper {
  border-top: none; }

.cms-grid--desktop-3 .cms-grid__item:nth-child(n + 5) .featured-link__wrapper {
  border-top: none; }

.cms-grid--desktop-4 .grid-item:nth-child(n + 6) .featured-link__wrapper {
  border-top: none; }


:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.flexible-title-text__column {
  width: 100%;
  margin-top: 16px;
  margin-top: var(--space-between-xxsmall); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.page {
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .page {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .page {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .page {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .page {
      padding-bottom: calc(120px + 0px); } }

.page .generic-hero__column {
  margin-bottom: 0;
  padding-bottom: 0;
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.delegations-hero {
  max-height: 900px;
  margin-bottom: calc(48px + 0px);
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 50vh; }
  @media screen and (min-width: 1280px) {
    .delegations-hero {
      max-height: 1100px; } }
  @media screen and (min-width: 1024px) {
    .delegations-hero {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .delegations-hero {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .delegations-hero {
      margin-bottom: calc(80px + 0px); } }

.delegations-hero__column {
  width: 100%; }

.delegations-hero__column-picture {
  max-height: 900px; }
  @media screen and (min-width: 1024px) {
    .delegations-hero__column-picture {
      max-height: 1000px; } }

.delegations-hero__column-content {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1024px) {
    .delegations-hero__column-content {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .delegations-hero__column-content {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .delegations-hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .delegations-hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .delegations-hero__column-content {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .delegations-hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .delegations-hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .delegations-hero__column-content {
      padding-right: calc(80px + 0px); } }

.delegations-hero .delegations-hero__column-content {
  background: #27251f;
  background: var(--color-black); }

.delegations-hero--inverted .delegations-hero__column-content {
  background: #fff;
  background: var(--color-secondary);
  padding-top: 0; }

.delegations-hero__common-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between; }

.delegations-hero .related-tags {
  margin-bottom: 0; }

.delegations-hero .related-tags__grid {
  margin-left: calc(16px / -2); }
  @media screen and (min-width: 768px) {
    .delegations-hero .related-tags__grid {
      margin-left: calc(32px / -2); } }
  @media screen and (min-width: 1440px) {
    .delegations-hero .related-tags__grid {
      margin-left: calc(48px / -2); } }
  @media screen and (min-width: 1680px) {
    .delegations-hero .related-tags__grid {
      margin-left: calc(80px / -2); } }

.delegations-hero__picture,
.delegations-hero__image {
  height: 100%; }

.delegations-hero--vertical .delegations-hero__common-content-main {
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.delegations-hero__common-content-main .button {
  width: 100%; }

@media screen and (min-width: 1024px) {
  .delegations-hero--vertical .delegations-hero__column {
    width: 100%; }
  .delegations-hero--vertical .delegations-hero__common-content {
    flex-direction: row; }
  .delegations-hero--vertical .delegations-hero__common-content-main {
    margin-top: 0;
    width: 25%; }
  .delegations-hero--vertical .delegations-hero__common-content-main {
    display: flex;
    justify-content: flex-end;
    margin: auto 0; }
  .delegations-hero--vertical .delegations-hero-category-title {
    margin-bottom: 0;
    width: 50%; }
  .delegations-hero .parallax-banner {
    height: 100%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.delegations__central-column,
.delegations__offices-column,
.delegations__delegations-column {
  width: 100%; }

.delegations__central-title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.delegations__delegations-grid {
  margin-top: 32px;
  margin-top: var(--space-between-small); }

.delegations .featured-preview__item {
  padding-bottom: calc(32px + 0px);
  padding-top: calc(32px + 0px);
  border-bottom: 2px solid #27251f;
  border-bottom: var(--dimension-border) solid var(--color-black);
  flex-direction: column;
  margin-left: 0;
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .delegations .featured-preview__item {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .delegations .featured-preview__item {
      padding-top: calc(48px + 0px); } }

.delegations .featured-preview__item:first-child {
  border-top: 2px solid #27251f;
  border-top: var(--dimension-border) solid var(--color-black); }

.delegations .main-layout {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .delegations .main-layout {
      margin-bottom: calc(64px + 0px); } }

.delegations__offices {
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .delegations__offices {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .delegations__offices {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .delegations__offices {
      padding-bottom: calc(80px + 0px); } }

@media screen and (min-width: 768px) {
  .delegations .featured-preview__item {
    flex-direction: row; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.delegation__content {
  margin-bottom: calc(48px + 0px);
  margin-top: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .delegation__content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .delegation__content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .delegation__content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .delegation__content {
      margin-top: calc(48px + 0px); } }

.delegation__related-delegations {
  margin-top: 0; }

.delegation__map-wrapper {
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.anchor-menu {
  padding-top: calc(32px / 2);
  padding-bottom: calc(32px / 2);
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  color: #fff;
  color: var(--color-secondary);
  font-size: 16px;
  font-size: var(--font-size-16);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  position: relative;
  z-index: 999999;
  z-index: var(--z-index-menu-onepage); }
  @media screen and (min-width: 1024px) {
    .anchor-menu {
      padding-top: calc(48px / 2); } }
  @media screen and (min-width: 1024px) {
    .anchor-menu {
      padding-bottom: calc(48px / 2); } }
  @media screen and (min-width: 768px) {
    .anchor-menu {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .anchor-menu {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .anchor-menu {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .anchor-menu {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .anchor-menu {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .anchor-menu {
      padding-left: calc(80px / 2); } }

.anchor-menu--gradient {
  margin-bottom: calc(48px + 0px);
  overflow: visible; }
  @media screen and (min-width: 1280px) {
    .anchor-menu--gradient {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .anchor-menu--gradient {
      margin-bottom: calc(72px + 0px); } }

.anchor-menu--red {
  background: #ee2537;
  background: var(--color-main); }

.anchor-menu--purple-dark-blue {
  background: linear-gradient(90deg, #7e2574, #203244);
  background: var(--gradient-purple-dark-blue); }

.anchor-menu--fuchsia-pink {
  background: linear-gradient(90deg, #c0004e, #7e2574);
  background: var(--gradient-fuchsia-pink); }

.anchor-menu--garnet-salmon {
  background: linear-gradient(90deg, #a6093d, #dc8699);
  background: var(--gradient-garnet-salmon); }

.anchor-menu--garnet-dark-blue {
  background: linear-gradient(
    90deg,
    #a6093d,
    #203244);
  background: var(--gradient-garnet-dark-blue); }

.anchor-menu--light-blue-purple {
  background: linear-gradient(90deg, #6ba4b8, #7e2574);
  background: var(--gradient-light-blue-purple); }

.anchor-menu--light-pink-purple {
  background: linear-gradient(90deg, #ffb1bb, #7e2574);
  background: var(--gradient-light-pink-purple); }

.anchor-menu--yellow-orange {
  background: linear-gradient(
    90deg,
    #fecd00,
    #c75b12);
  background: var(--gradient-yellow-orange); }

.anchor-menu--orange-red {
  background: linear-gradient(90deg, #c75b12, #c0004e);
  background: var(--gradient-orange-red); }

.anchor-menu--turquoise-green {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #00685e);
  background: var(--gradient-turquoise-green); }

.anchor-menu--light-blue-dark-blue {
  background: linear-gradient(
    90deg,
    #6ba4b8,
    #203244);
  background: var(--gradient-light-blue-dark-blue); }

.anchor-menu--turquoise-dark-blue {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.anchor-menu--turquoise-dark-blue {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.anchor-menu--turquoise-yellow {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #fecd00);
  background: var(--gradient-turquoise-yellow); }

.anchor-menu--light-yellow-yellow {
  background: linear-gradient(
    90deg,
    #f8e08e,
    #fecd00);
  background: var(--gradient-light-yellow-yellow); }

.anchor-menu--salmon-turquoise {
  background: linear-gradient(90deg, #ff8d6d, #87c9c4);
  background: var(--gradient-salmon-turquoise); }

.anchor-menu--yellow {
  background: #fecd00;
  background: var(--gradient-yellow); }

.anchor-menu--red {
  background: #b7003e;
  background: var(--gradient-red); }

.anchor-menu--fuchsia {
  background: #b0f;
  background: var(--gradient-fuchsia); }

.anchor-menu--dark-blue {
  background: #0000f7;
  background: var(--gradient-dark-blue); }

.anchor-menu--light-blue {
  background: #09f;
  background: var(--gradient-light-blue); }

.anchor-menu--light-green {
  background: #00db92;
  background: var(--gradient-light-green); }

.anchor-menu--sticky {
  left: 0px;
  margin-bottom: 0;
  position: fixed;
  top: 0;
  width: 100%; }

.anchor-menu {
  font-weight: 400;
  font-weight: var(--font-weight-regular); }

.anchor-menu__wrapper {
  display: flex;
  flex-wrap: wrap; }

.anchor-menu__wrapper .column {
  width: calc((6 / 12) * 100%); }

@media screen and (min-width: 768px) {
  .anchor-menu__wrapper .column {
    width: calc((3 / 12) * 100%); } }

@media screen and (min-width: 1280px) {
  .anchor-menu__wrapper .column {
    width: calc((2 / 12) * 100%); }
  .anchor-menu {
    padding-top: calc(32px + 0px);
    padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .anchor-menu {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .anchor-menu {
      padding-bottom: calc(48px + 0px); } }

.anchor__item-selected {
  font-weight: 700;
  font-weight: var(--font-weight-bold); }

.anchor-menu__switch .iconography {
  fill: #fff;
  fill: var(--color-secondary); }

.anchor-menu__switch-icon {
  float: right; }

.anchor-menu--open .anchor-menu__switch-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.anchor-menu-mobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 20px; }

.anchor-menu-mobile .anchor-menu-mobile__column {
  margin-bottom: 8px; }

.anchor-menu--mobile .column {
  width: 100%; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.catalogue-download {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-left: calc(16px * 3);
  padding-right: calc(16px * 3);
  background: #f7f6f5;
  background: var(--color-desert-storm);
  text-align: center; }
  @media screen and (min-width: 1024px) {
    .catalogue-download {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .catalogue-download {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .catalogue-download {
      padding-left: calc(32px * 3); } }
  @media screen and (min-width: 1440px) {
    .catalogue-download {
      padding-left: calc(48px * 3); } }
  @media screen and (min-width: 1680px) {
    .catalogue-download {
      padding-left: calc(80px * 3); } }
  @media screen and (min-width: 768px) {
    .catalogue-download {
      padding-right: calc(32px * 3); } }
  @media screen and (min-width: 1440px) {
    .catalogue-download {
      padding-right: calc(48px * 3); } }
  @media screen and (min-width: 1680px) {
    .catalogue-download {
      padding-right: calc(80px * 3); } }

.catalogue-download__image {
  margin: 0 auto 8px;
  margin: 0 auto var(--space-between-xxxsmall); }

.catalogue-download__button {
  display: block;
  margin-top: 8px;
  margin-top: var(--space-between-xxxsmall); }

@media screen and (min-width: 768px) {
  .catalogue-download {
    padding-left: calc(16px * 2);
    padding-right: calc(16px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .catalogue-download {
      padding-left: calc(32px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .catalogue-download {
      padding-left: calc(48px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .catalogue-download {
      padding-left: calc(80px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .catalogue-download {
      padding-right: calc(32px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .catalogue-download {
      padding-right: calc(48px * 2); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .catalogue-download {
      padding-right: calc(80px * 2); } }

@media screen and (min-width: 768px) {
  .catalogue-download__image {
    width: 50%; } }

@media screen and (min-width: 1024px) {
  .catalogue-download {
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px);
    max-width: 100%; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .catalogue-download {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .catalogue-download {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .catalogue-download {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .catalogue-download {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .catalogue-download {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .catalogue-download {
      padding-right: calc(80px + 0px); } }

@media screen and (min-width: 1024px) {
  .catalogue-download__image {
    width: 100%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.onepage-hero {
  color: #fff;
  color: #fff;
  color: var(--color-secondary); }

.onepage-hero--red::after {
  background: #ee2537;
  background: var(--color-main); }

.onepage-hero--purple-dark-blue::after {
  background: linear-gradient(90deg, #7e2574, #203244);
  background: var(--gradient-purple-dark-blue); }

.onepage-hero--fuchsia-pink::after {
  background: linear-gradient(90deg, #c0004e, #7e2574);
  background: var(--gradient-fuchsia-pink); }

.onepage-hero--garnet-salmon::after {
  background: linear-gradient(90deg, #a6093d, #dc8699);
  background: var(--gradient-garnet-salmon); }

.onepage-hero--garnet-dark-blue::after {
  background: linear-gradient(
    90deg,
    #a6093d,
    #203244);
  background: var(--gradient-garnet-dark-blue); }

.onepage-hero--light-blue-purple::after {
  background: linear-gradient(90deg, #6ba4b8, #7e2574);
  background: var(--gradient-light-blue-purple); }

.onepage-hero--light-pink-purple::after {
  background: linear-gradient(90deg, #ffb1bb, #7e2574);
  background: var(--gradient-light-pink-purple); }

.onepage-hero--yellow-orange::after {
  background: linear-gradient(
    90deg,
    #fecd00,
    #c75b12);
  background: var(--gradient-yellow-orange); }

.onepage-hero--orange-red::after {
  background: linear-gradient(90deg, #c75b12, #c0004e);
  background: var(--gradient-orange-red); }

.onepage-hero--turquoise-green::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #00685e);
  background: var(--gradient-turquoise-green); }

.onepage-hero--light-blue-dark-blue::after {
  background: linear-gradient(
    90deg,
    #6ba4b8,
    #203244);
  background: var(--gradient-light-blue-dark-blue); }

.onepage-hero--turquoise-dark-blue::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.onepage-hero--turquoise-dark-blue::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #203244);
  background: var(--gradient-turquoise-dark-blue); }

.onepage-hero--turquoise-yellow::after {
  background: linear-gradient(
    90deg,
    #87c9c4,
    #fecd00);
  background: var(--gradient-turquoise-yellow); }

.onepage-hero--light-yellow-yellow::after {
  background: linear-gradient(
    90deg,
    #f8e08e,
    #fecd00);
  background: var(--gradient-light-yellow-yellow); }

.onepage-hero--salmon-turquoise::after {
  background: linear-gradient(90deg, #ff8d6d, #87c9c4);
  background: var(--gradient-salmon-turquoise); }

.onepage-hero--yellow {
  background: #fecd00;
  background: var(--gradient-yellow); }

.onepage-hero--red {
  background: #b7003e;
  background: var(--gradient-red); }

.onepage-hero--fuchsia {
  background: #b0f;
  background: var(--gradient-fuchsia); }

.onepage-hero--dark-blue {
  background: #0000f7;
  background: var(--gradient-dark-blue); }

.onepage-hero--light-blue {
  background: #09f;
  background: var(--gradient-light-blue); }

.onepage-hero--light-green {
  background: #00db92;
  background: var(--gradient-light-green); }

.onepage-hero__column {
  justify-content: center;
  position: relative;
  width: 100%; }

.onepage-hero--with-background::after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%; }

.onepage-hero {
  max-height: 900px;
  background-color: #27251f;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1280px) {
    .onepage-hero {
      max-height: 1100px; } }

.onepage-hero__background {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  z-index: -5;
  z-index: var(--z-index-negative); }

.onepage-hero__column-content {
  padding-top: calc(32px / 2);
  padding-right: calc(16px + 0px);
  padding-bottom: calc(32px / 2);
  padding-left: calc(16px + 0px);
  display: flex;
  flex-direction: column;
  z-index: 99999;
  z-index: var(--z-index-header); }
  @media screen and (min-width: 1024px) {
    .onepage-hero__column-content {
      padding-top: calc(48px / 2); } }
  @media screen and (min-width: 768px) {
    .onepage-hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .onepage-hero__column-content {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .onepage-hero__column-content {
      padding-bottom: calc(48px / 2); } }
  @media screen and (min-width: 768px) {
    .onepage-hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .onepage-hero__column-content {
      padding-left: calc(80px + 0px); } }

.onepage-hero__column-full {
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px);
  z-index: 99999;
  z-index: var(--z-index-header); }
  @media screen and (min-width: 768px) {
    .onepage-hero__column-full {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero__column-full {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .onepage-hero__column-full {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .onepage-hero__column-full {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero__column-full {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .onepage-hero__column-full {
      padding-right: calc(80px + 0px); } }

.onepage-hero__picture .picture {
  width: 450px;
  max-width: 100%;
  position: absolute;
  top: -80px; }
  @media screen and (min-width: 768px) {
    .onepage-hero__picture .picture {
      width: 850px; } }
  .onepage-hero__picture .picture .picture__image {
    width: 90%; }

@media screen and (min-width: 768px) {
  .onepage-hero__column {
    width: 50%; }
  .onepage-hero__picture .picture {
    top: 32px;
    top: var(--space-between-small); } }

@media screen and (min-width: 1280px) {
  .onepage-hero__picture .picture {
    top: 24px;
    top: var(--space-between-xsmall); } }

.onepage-hero .related-tags__grid {
  margin-left: calc(16px / -2); }
  @media screen and (min-width: 768px) {
    .onepage-hero .related-tags__grid {
      margin-left: calc(32px / -2); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero .related-tags__grid {
      margin-left: calc(48px / -2); } }
  @media screen and (min-width: 1680px) {
    .onepage-hero .related-tags__grid {
      margin-left: calc(80px / -2); } }

.onepage-hero__sectors {
  margin-top: calc(48px + 0px);
  margin-bottom: 0px; }
  @media screen and (min-width: 1024px) {
    .onepage-hero__sectors {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .onepage-hero__sectors {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero__sectors {
      margin-top: calc(80px + 0px); } }

.onepage-hero__technologies {
  margin-top: calc(64px + 0px);
  margin-bottom: calc(32px / 2); }
  @media screen and (min-width: 1024px) {
    .onepage-hero__technologies {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .onepage-hero__technologies {
      margin-top: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage-hero__technologies {
      margin-top: calc(160px + 0px); } }
  @media screen and (min-width: 1024px) {
    .onepage-hero__technologies {
      margin-bottom: calc(48px / 2); } }

@media screen and (min-width: 1920px) {
  .hero {
    min-height: 50vh; }
  .hero--vertical .hero__column-content {
    padding-bottom: calc(48px + 0px);
    padding-top: calc(48px + 0px);
    padding-left: calc(16px + 0px);
    padding-right: calc(16px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1280px) {
    .hero--vertical .hero__column-content {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1280px) {
    .hero--vertical .hero__column-content {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .hero--vertical .hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .hero--vertical .hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1680px) {
    .hero--vertical .hero__column-content {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 768px) {
    .hero--vertical .hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1440px) {
    .hero--vertical .hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1920px) and (min-width: 1680px) {
    .hero--vertical .hero__column-content {
      padding-right: calc(80px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.divider {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .divider {
      margin-bottom: calc(48px + 0px); } }

.publication__subcategories-title {
  padding-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .publication__subcategories-title {
      padding-bottom: calc(48px + 0px); } }

.publication__cards {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .publication__cards {
      margin-bottom: calc(48px + 0px); } }

.publication__info {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .publication__info {
      margin-bottom: calc(48px + 0px); } }

.main-layout__sticky {
  margin-top: 192px; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.onepage__top {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .onepage__top {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .onepage__top {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .onepage__top {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .onepage__top {
      margin-bottom: calc(120px + 0px); } }

.onepage__top-logo {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.onepage__top-logo img {
  width: auto; }

.onepage__top-subtitle {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.onepage__top-excerpt {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.opepage__content {
  margin-bottom: calc(48px + 0px);
  width: 100%; }
  @media screen and (min-width: 768px) {
    .opepage__content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .opepage__content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .opepage__content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .opepage__content {
      margin-bottom: calc(120px + 0px); } }

.opepage__content .picture[class*='picture--ratio-'] img {
  position: relative; }

.opepage__content .picture[class*='picture--ratio-'] picture::before {
  display: none; }

.opepage__content .cms-50-50__content-wrapper {
  top: 0;
  -webkit-transform: translateY(0%);
  transform: translateY(0%); }

.opepage__column {
  width: 100%; }

.main-layout__sticky {
  margin-top: 192px; }

.onepage .title-text-button__text a {
  color: #27251f;
  color: var(--color-black); }

.onepage .main-layout__aside .title-text-button {
  padding-left: calc(16px / 4);
  padding-right: calc(16px / 4);
  padding-top: calc(32px / 2);
  padding-bottom: calc(32px / 4); }
  @media screen and (min-width: 768px) {
    .onepage .main-layout__aside .title-text-button {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .onepage .main-layout__aside .title-text-button {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .onepage .main-layout__aside .title-text-button {
      padding-left: calc(80px / 4); } }
  @media screen and (min-width: 768px) {
    .onepage .main-layout__aside .title-text-button {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .onepage .main-layout__aside .title-text-button {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .onepage .main-layout__aside .title-text-button {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 1024px) {
    .onepage .main-layout__aside .title-text-button {
      padding-top: calc(48px / 2); } }
  @media screen and (min-width: 1024px) {
    .onepage .main-layout__aside .title-text-button {
      padding-bottom: calc(48px / 4); } }

.onepage .main-layout__aside .title-text-button p {
  margin-bottom: 0;
  text-decoration: underline; }

.onepage .main-layout__aside .title-text-button__title {
  margin-bottom: 24px;
  margin-bottom: var(--space-between-xsmall); }

.onepage .content-grid__title {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.onepage .main-layout__aside > * {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }

.opepage__content .related-tags--disabled .related-tags__item:hover {
  color: #27251f;
  color: var(--color-black); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: var(--font-size-16);
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.section__two-columns {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%; }
  @media screen and (min-width: 992px) {
    .section__two-columns {
      grid-template-columns: 1fr 1fr; } }

.section__content,
.section__left-column,
.section__right-column,
.section__single-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

.section {
  display: grid;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .section > * {
    grid-area: 1/-1; }

.section__content {
  padding-top: 40px;
  padding-bottom: 30px;
  z-index: 1; }
  @media screen and (min-width: 992px) {
    .section__content {
      padding-top: 50px;
      padding-bottom: 80px; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-title__wrapper {
  display: flex;
  flex-direction: column;
  padding-top: calc(0.33rem * 7);
  padding-top: calc(var(--s) * 7);
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-title__wrapper {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-title__wrapper {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-title__wrapper {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-title__wrapper {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-title__wrapper {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-title__wrapper {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 1200px) {
    .landing-title__wrapper {
      padding-top: calc(0.33rem * 24);
      padding-top: calc(var(--s) * 24);
      padding-bottom: calc(0.33rem * 24);
      padding-bottom: calc(var(--s) * 24); } }

.landing-title__title {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.landing-title__description > * + * {
  margin-top: calc(0.33rem * 3);
  margin-top: calc(var(--s) * 3); }

.landing-title__description a {
  text-decoration: underline;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }

.landing-title__description a:hover {
  opacity: 0.8;
  opacity: var(--landing-link-hover-opacity); }

.landing-title__description p {
  display: block; }

.landing-title__description ol {
  list-style: decimal; }

.landing-title__description ul {
  list-style: disc; }

.landing-title__description ol,
.landing-title__description ul {
  padding-left: 1.05em;
  padding-left: var(--landing-list-padding); }
  .landing-title__description ol > * + *,
  .landing-title__description ul > * + * {
    margin-top: calc(0.33rem * 2);
    margin-top: calc(var(--s) * 2); }

.landing-title__description strong {
  font-weight: 700;
  font-variation-settings: 'wght' 700; }

.landing-title__description em {
  font-style: italic; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-form__wrapper {
  margin-top: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-top: calc(0.33rem * 8);
  padding-top: calc(var(--s) * 8);
  padding-left: calc(16px + 0px);
  padding-right: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-form__wrapper {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-form__wrapper {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-form__wrapper {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-form__wrapper {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-form__wrapper {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-form__wrapper {
      padding-right: calc(80px + 0px); } }

.landing-form__header {
  margin-bottom: calc(0.33rem * 4);
  margin-bottom: calc(var(--s) * 4); }
  @media screen and (min-width: 768px) {
    .landing-form__header {
      margin-bottom: calc(0.33rem * 2);
      margin-bottom: calc(var(--s) * 2); } }
  @media screen and (min-width: 1200px) {
    .landing-form__header {
      margin-bottom: calc(0.33rem * 8);
      margin-bottom: calc(var(--s) * 8); } }

.landing-form__description > * + * {
  margin-top: calc(0.33rem * 3);
  margin-top: calc(var(--s) * 3); }

.landing-form__description a {
  text-decoration: underline;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }

.landing-form__description a:hover {
  opacity: 0.8;
  opacity: var(--landing-link-hover-opacity); }

.landing-form__description p {
  display: block; }

.landing-form__description ol {
  list-style: decimal; }

.landing-form__description ul {
  list-style: disc; }

.landing-form__description ol,
.landing-form__description ul {
  padding-left: 1.05em;
  padding-left: var(--landing-list-padding); }
  .landing-form__description ol > * + *,
  .landing-form__description ul > * + * {
    margin-top: calc(0.33rem * 2);
    margin-top: calc(var(--s) * 2); }

.landing-form__description strong {
  font-weight: 700;
  font-variation-settings: 'wght' 700; }

.landing-form__description em {
  font-style: italic; }

.landing-form__iframe--loading {
  display: none; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-accordion-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: calc(0.33rem * 10);
  padding-bottom: calc(var(--s) * 10);
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-accordion-block {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-accordion-block {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-accordion-block {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-accordion-block {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-accordion-block {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-accordion-block {
      padding-left: calc(80px + 0px); } }

.landing-accordion-block__description {
  margin-top: auto;
  margin-bottom: calc(0.33rem * 5);
  margin-bottom: calc(var(--s) * 5);
  max-width: 33em; }
  .landing-accordion-block__description > * + * {
    margin-top: calc(0.33rem * 3);
    margin-top: calc(var(--s) * 3); }
  .landing-accordion-block__description a {
    text-decoration: underline;
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }
  .landing-accordion-block__description a:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }
  .landing-accordion-block__description p {
    display: block; }
  .landing-accordion-block__description ol {
    list-style: decimal; }
  .landing-accordion-block__description ul {
    list-style: disc; }
  .landing-accordion-block__description ol,
  .landing-accordion-block__description ul {
    padding-left: 1.05em;
    padding-left: var(--landing-list-padding); }
    .landing-accordion-block__description ol > * + *,
    .landing-accordion-block__description ul > * + * {
      margin-top: calc(0.33rem * 2);
      margin-top: calc(var(--s) * 2); }
  .landing-accordion-block__description strong {
    font-weight: 700;
    font-variation-settings: 'wght' 700; }
  .landing-accordion-block__description em {
    font-style: italic; }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: var(--font-size-16);
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-accordion {
  display: flex;
  flex-direction: column;
  width: 100%; }

.landing-accordion__item + .landing-accordion__item .landing-accordion__header {
  border-top: 1px solid #252525;
  border-top: 1px solid var(--landing-color-neutral-800); }

.landing-accordion__header {
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: calc(0.33rem * 15);
  min-height: calc(var(--s) * 15);
  text-transform: uppercase;
  padding-top: 1.38em;
  padding-bottom: 1.38em;
  cursor: pointer; }

.landing-accordion__header-text {
  margin-right: calc(0.33rem * 3);
  margin-right: calc(var(--s) * 3);
  text-align: left; }

.landing-accordion__header .landing-icon {
  --size: 2em;
  margin-left: auto; }

.landing-accordion__animation-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 200ms ease;
  transition: grid-template-rows 200ms ease, -ms-grid-rows 200ms ease; }

.landing-accordion__item.landing-accordion__item--expanded .landing-accordion__animation-wrapper {
  grid-template-rows: 1fr; }

.landing-accordion__item.landing-accordion__item--expanded .landing-accordion__transform-wrapper {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  transition: visibility 0s linear, -webkit-transform 300ms ease;
  transition: transform 300ms ease, visibility 0s linear;
  transition: transform 300ms ease, visibility 0s linear, -webkit-transform 300ms ease; }

.landing-accordion__item.landing-accordion__item--expanded .landing-accordion__content {
  -webkit-animation: landing_accordion_fade_in 300ms 200ms forwards;
          animation: landing_accordion_fade_in 300ms 200ms forwards; }

.landing-accordion__animation {
  min-height: 0; }

.landing-accordion__transform-wrapper {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  visibility: hidden;
  transition: visibility 0s var(--transitionLength) var(--transitionTiming), -webkit-transform var(--transitionLength) var(--transitionTiming);
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming), -webkit-transform var(--transitionLength) var(--transitionTiming); }

.landing-accordion__content {
  padding: 0 4em 2.5em 1.5em;
  margin: 0em;
  opacity: 0; }
  .landing-accordion__content > * + * {
    margin-top: calc(0.33rem * 3);
    margin-top: calc(var(--s) * 3); }
  .landing-accordion__content a {
    text-decoration: underline;
    transition-property: all;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 300ms; }
  .landing-accordion__content a:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }
  .landing-accordion__content p {
    display: block; }
  .landing-accordion__content ol {
    list-style: decimal; }
  .landing-accordion__content ul {
    list-style: disc; }
  .landing-accordion__content ol,
  .landing-accordion__content ul {
    padding-left: 1.05em;
    padding-left: var(--landing-list-padding); }
    .landing-accordion__content ol > * + *,
    .landing-accordion__content ul > * + * {
      margin-top: calc(0.33rem * 2);
      margin-top: calc(var(--s) * 2); }
  .landing-accordion__content strong {
    font-weight: 700;
    font-variation-settings: 'wght' 700; }
  .landing-accordion__content em {
    font-style: italic; }

@-webkit-keyframes landing_accordion_fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes landing_accordion_fade_in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.image__top-wrapper {
  height: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.image__top-wrapper--contain {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .image__top-wrapper--contain {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .image__top-wrapper--contain {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .image__top-wrapper--contain {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .image__top-wrapper--contain {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .image__top-wrapper--contain {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .image__top-wrapper--contain {
      padding-left: calc(80px + 0px); } }

.image__top-wrapper--cover {
  height: 100%; }

.image__wrapper {
  display: grid;
  height: 100%; }
  .image__wrapper > * {
    grid-area: 1/-1; }

.image__wrapper--contain {
  margin-left: auto;
  margin-right: auto;
  height: 100vw; }
  @media screen and (min-width: 576px) {
    .image__wrapper--contain {
      height: 50vw; } }
  @media screen and (min-width: 992px) {
    .image__wrapper--contain {
      height: unset;
      max-width: 100%; } }
  @media screen and (min-width: 1200px) {
    .image__wrapper--contain {
      max-width: 60%; } }

.image__wrapper--tech-mesh {
  position: relative;
  padding-top: calc(0.33rem * 16);
  padding-top: calc(var(--s) * 16);
  margin-left: calc(0.33rem * 4);
  margin-left: calc(var(--s) * 4);
  padding-bottom: calc(0.33rem * 5);
  padding-bottom: calc(var(--s) * 5); }
  @media screen and (min-width: 1200px) {
    .image__wrapper--tech-mesh {
      padding-top: 0;
      padding-bottom: 0; } }

.image__tech-mesh {
  z-index: 1; }

.image__tech-mesh--top {
  position: relative;
  -webkit-transform: translate(20%, -30%);
          transform: translate(20%, -30%);
  width: 50%;
  max-height: calc(0.33rem * 20);
  max-height: calc(var(--s) * 20); }
  @media screen and (min-width: 992px) {
    .image__tech-mesh--top {
      top: 25%;
      -webkit-transform: translateX(-30%);
              transform: translateX(-30%);
      width: 30%;
      max-height: unset; } }

.image__tech-mesh--bottom {
  position: relative;
  top: 100%;
  left: 100%;
  -webkit-transform: translate(-110%, -60%);
          transform: translate(-110%, -60%);
  width: 60%;
  max-height: calc(0.33rem * 20);
  max-height: calc(var(--s) * 20); }
  @media screen and (min-width: 992px) {
    .image__tech-mesh--bottom {
      position: absolute;
      top: 100%;
      left: 100%;
      -webkit-transform: translate(-120%, -60%);
              transform: translate(-120%, -60%);
      width: 40%;
      max-height: unset; } }

.image--cover {
  height: 100vw;
  object-position: center; }
  @media screen and (min-width: 576px) {
    .image--cover {
      height: 50vw; } }
  @media screen and (min-width: 992px) {
    .image--cover {
      height: unset; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-gallery {
  max-width: 100vw;
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-gallery {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-gallery {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-gallery {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-gallery {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-gallery {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-gallery {
      padding-left: calc(80px + 0px); } }
  .landing-gallery__slider {
    margin-right: calc(16px * -1); }
    @media screen and (min-width: 768px) {
      .landing-gallery__slider {
        margin-right: calc(32px * -1); } }
    @media screen and (min-width: 1440px) {
      .landing-gallery__slider {
        margin-right: calc(48px * -1); } }
    @media screen and (min-width: 1680px) {
      .landing-gallery__slider {
        margin-right: calc(80px * -1); } }
  .landing-gallery .swiper-container {
    display: flex;
    flex-direction: column; }
    .landing-gallery .swiper-container .swiper-slide {
      width: auto; }
  .landing-gallery__item {
    display: flex;
    flex-direction: column;
    grid-row-gap: calc(0.33rem * 3);
    row-gap: calc(0.33rem * 3);
    grid-row-gap: calc(var(--s) * 3);
    row-gap: calc(var(--s) * 3); }
    .landing-gallery__item img {
      height: 100vw;
      width: auto; }
      @media screen and (min-width: 576px) {
        .landing-gallery__item img {
          height: 36vw; } }
    .landing-gallery__item-caption {
      color: #6e6e6e;
      color: var(--landing-color-neutral-600); }
  .landing-gallery__navigation {
    display: flex;
    grid-column-gap: 0.33rem;
    -webkit-column-gap: 0.33rem;
            column-gap: 0.33rem;
    grid-column-gap: var(--s);
    -webkit-column-gap: var(--s);
            column-gap: var(--s);
    margin-top: calc(0.33rem * 10);
    margin-top: calc(var(--s) * 10);
    margin-left: auto;
    margin-right: calc(16px + 0px); }
    @media screen and (min-width: 768px) {
      .landing-gallery__navigation {
        margin-right: calc(32px + 0px); } }
    @media screen and (min-width: 1440px) {
      .landing-gallery__navigation {
        margin-right: calc(48px + 0px); } }
    @media screen and (min-width: 1680px) {
      .landing-gallery__navigation {
        margin-right: calc(80px + 0px); } }
    .landing-gallery__navigation button {
      display: flex;
      cursor: pointer;
      background-color: transparent;
      border: 0.5px solid #252525;
      border: 0.5px solid var(--landing-color-neutral-800);
      border-radius: 1.25em;
      padding: 0.75em 2em; }
      .landing-gallery__navigation button[disabled] {
        opacity: 0.5;
        cursor: not-allowed; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-video__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-video__wrapper {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-video__wrapper {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-video__wrapper {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-video__wrapper {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-video__wrapper {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-video__wrapper {
      padding-left: calc(80px + 0px); } }

.landing-video__iframe {
  width: 100%;
  height: 50vw; }
  @media screen and (min-width: 576px) {
    .landing-video__iframe {
      height: 40vw; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-logos__top-wrapper {
  display: flex;
  flex-direction: column;
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-logos__top-wrapper {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-logos__top-wrapper {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-logos__top-wrapper {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-logos__top-wrapper {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-logos__top-wrapper {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-logos__top-wrapper {
      padding-left: calc(80px + 0px); } }

.landing-logos__wrapper {
  display: flex;
  flex-wrap: wrap;
  --flex-gap: calc(var(--s) * 4);
  grid-gap: var(--flex-gap);
  gap: var(--flex-gap);
  margin-top: calc(0.33rem * 8);
  margin-top: calc(var(--s) * 8); }
  @media screen and (min-width: 768px) {
    .landing-logos__wrapper {
      --flex-gap: calc(var(--s) * 8);
      flex-direction: row-reverse; } }

.landing-logos__logo-wrapper {
  flex-basis: calc((100% - 2 * var(--flex-gap)) / 3);
  min-width: 10em; }
  @media screen and (min-width: 768px) {
    .landing-logos__logo-wrapper {
      min-width: 17em; } }

.landing-logos__logo {
  width: 10em; }
  @media screen and (min-width: 768px) {
    .landing-logos__logo {
      width: 17em; } }

.landing-logos__logo-image {
  height: 3.2em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.landing-logos__logo-text {
  margin-top: calc(0.33rem * 3);
  margin-top: calc(var(--s) * 3);
  text-align: left;
  color: #868686;
  color: var(--landing-color-neutral-500); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing main section:first-child {
  padding-top: calc(0.33rem * 20);
  padding-top: calc(var(--s) * 20); }
  .landing main section:first-child .section__content {
    padding-top: 0; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-button-link {
  border-top: 1px solid #fafafa;
  border-top: 1px solid var(--landing-color-light);
  color: #fafafa;
  color: var(--landing-color-light);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.22em 1.22em;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-body);
  line-height: 1.375;
  line-height: var(--landing-lh-sec-body);
  letter-spacing: var(--landing-ls-sec-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary);
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }
  @media screen and (min-width: 768px) {
    .landing-button-link {
      padding: 1.22em 2.28em;
      font-size: calc(12 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-sm);
      line-height: 1.5;
      line-height: var(--landing-lh-sm);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-sm);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      border: 1px solid #ff5a00;
      border: 1px solid var(--landing-color-primary);
      border-radius: 5em; } }

.landing-button-link .landing-icon {
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  margin-left: auto;
  --color: var(--landing-color-light);
  --size: 3.05em; }
  @media screen and (min-width: 768px) {
    .landing-button-link .landing-icon {
      --size: 2.5em; } }

.landing-button-link__title {
  text-transform: uppercase;
  margin-right: 3em;
  font-weight: 700; }
  @media screen and (min-width: 768px) {
    .landing-button-link__title {
      font-weight: 400; } }

.landing-button-link:hover {
  background-color: #fff;
  background-color: var(--landing-color-white);
  color: #1a1a1a;
  color: var(--landing-color-text);
  border-top: 1px solid #ff5a00;
  border-top: 1px solid var(--landing-color-primary); }
  .landing-button-link:hover .landing-icon {
    --color: var(--landing-color-text); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-image-link {
  display: grid;
  border-radius: 0.33rem;
  border-radius: var(--s);
  aspect-ratio: 11/6;
  width: 100%;
  overflow: hidden;
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 768px) {
    .landing-image-link {
      width: 24em; } }
  .landing-image-link > * {
    grid-area: 1/-1; }

.landing-image-link__content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: calc(0.33rem * 4);
  padding: calc(var(--s) * 4); }

.landing-image-link .landing-icon {
  --color: var(--landing-color-light);
  --size: 2.3em;
  margin-left: auto; }

.landing-image-link__title {
  margin-top: auto;
  margin-bottom: 0 !important;
  color: #fafafa;
  color: var(--landing-color-light);
  font-weight: 400;
  text-transform: uppercase; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

@use 'sass:map';
@use './breakpoints-variables' as *;
@use 'sass:math' as math;
@use './breakpoints-variables' as *;
@use "sass:map";
@use './media-queries' as *;
@use 'sass:math';
/*
 * $property: all/margin-right/etc.
 * $easing: standard/emphasized
 * $acceleration: constant/decelerate/accelerate
 * $duration: short/medium/long/extra-long 1 to 4 eg: 'short-1' or 'extra-long-4'
*/
.fs--body-small {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }
  @media screen and (min-width: 768px) {
    .fs--body-small {
      font-size: 9.66px;
      line-height: 13.81px; } }
  @media screen and (min-width: 1200px) {
    .fs--body-small {
      font-size: 14px;
      line-height: 20px; } }

.fs-sec--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3);
  max-width: 16em; }
  @media screen and (min-width: 1200px) {
    .fs-sec--lg {
      margin-bottom: calc(0.33rem * 3);
      margin-bottom: calc(var(--s) * 3); } }

.fs--lg {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.fs--body {
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--lg {
  font-size: calc(21 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-lg);
  line-height: 1.33333;
  line-height: var(--landing-lh-lg);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-lg);
  font-variation-settings: "wght" 600;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 600);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--md {
  font-size: calc(16 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-md);
  line-height: 1.375;
  line-height: var(--landing-lh-md);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--sm {
  font-size: calc(12 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sm);
  line-height: 1.5;
  line-height: var(--landing-lh-sm);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sm);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--xs {
  font-size: calc(10 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-xs);
  line-height: 1.3;
  line-height: var(--landing-lh-xs);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-xs);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }

.fs--label {
  text-transform: uppercase;
  margin-bottom: 0.71em;
  font-size: calc(14 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-body);
  line-height: 1.42857;
  line-height: var(--landing-lh-body);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-body);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Open Sans';
  font-family: var(--landing-font-primary); }
  @media screen and (min-width: 576px) {
    .fs--label {
      font-size: calc(16 / 16 * calc(16 * 1px));
      font-size: var(--landing-fs-md);
      line-height: 1.375;
      line-height: var(--landing-lh-md);
      letter-spacing: 0%;
      letter-spacing: var(--landing-ls-md);
      font-variation-settings: "wght" 400;
      font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
      font-family: 'Open Sans';
      font-family: var(--landing-font-primary);
      margin-bottom: 1.66em; } }

.fs-sec--xl {
  font-size: calc(36 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-xl);
  line-height: 1.11111;
  line-height: var(--landing-lh-sec-xl);
  letter-spacing: 0%;
  letter-spacing: var(--landing-ls-sec-xl);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--lg {
  font-size: calc(26 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-lg);
  line-height: 1.23077;
  line-height: var(--landing-lh-sec-lg);
  letter-spacing: var(--landing-ls-sec-lg);
  font-variation-settings: "wght" 700;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 700);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs-sec--md {
  font-size: calc(18 / 16 * calc(16 * 1px));
  font-size: var(--landing-fs-sec-md);
  line-height: 1.44444;
  line-height: var(--landing-lh-sec-md);
  letter-spacing: var(--landing-ls-sec-md);
  font-variation-settings: "wght" 400;
  font-variation-settings: "wght" var(--heading-font-variation-settings, 400);
  font-family: 'Mark';
  font-family: var(--landing-font-secondary); }

.fs--light {
  font-variation-settings: 'wght' 300; }

.fs--regular {
  font-variation-settings: 'wght' 400; }

.fs--medium {
  font-variation-settings: 'wght' 500; }

.fs--semibold {
  font-variation-settings: 'wght' 600; }

.fs--bold {
  font-variation-settings: 'wght' 700; }

:root {
  --s: 0.33rem;
  --s2: calc(var(--s) * 2);
  --s3: calc(var(--s) * 3);
  --s4: calc(var(--s) * 4);
  --s5: calc(var(--s) * 5);
  --s6: calc(var(--s) * 6);
  --s7: calc(var(--s) * 7);
  --s8: calc(var(--s) * 8);
  --s9: calc(var(--s) * 9);
  --s10: calc(var(--s) * 10);
  --sem: 0.33em;
  --sem2: calc(var(--sem) * 2);
  --sem3: calc(var(--sem) * 3);
  --sem4: calc(var(--sem) * 4);
  --sem5: calc(var(--sem) * 5);
  --sem6: calc(var(--sem) * 6);
  --sem7: calc(var(--sem) * 7);
  --sem8: calc(var(--sem) * 8);
  --sem9: calc(var(--sem) * 9);
  --sem10: calc(var(--sem) * 10);
  --gap: 0.99rem;
  --gutter: 0.99rem;
  --safe-area: calc(var(--s) * 3);
  --cols: 12; }
  @media screen and (min-width: 992px) {
    :root {
      --safe-area: calc(var(--s) * 12); } }

:root {
  --landing-html-font-size-v: 16;
  --landing-html-font-size: calc(var(--landing-html-font-size-v) * 1px);
  --landing-safe-area: calc(var(--s) * 16.7);
  --landing-color-primary: #ff5a00;
  --landing-color-white: #fff;
  --landing-color-light: #fafafa;
  --landing-color-neutral: #efefef;
  --landing-color-neutral-500: #868686;
  --landing-color-neutral-600: #6e6e6e;
  --landing-color-neutral-800: #252525;
  --landing-color-neutral-900: #1a1a1a;
  --landing-color-text: var(--landing-color-neutral-900);
  --landing-list-padding: 1.05em;
  --landing-link-hover-opacity: 0.8;
  --landing-font-primary: 'Open Sans';
  --landing-font-secondary: 'Mark';
  --landing-fs-xs-v: 10;
  --landing-fs-xs: calc(var(--landing-fs-xs-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-xs: 1.3;
  --landing-ls-xs: 0%;
  --landing-fs-sm-v: 12;
  --landing-fs-sm: calc(var(--landing-fs-sm-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sm: 1.5;
  --landing-ls-sm: 0%;
  --landing-fs-body-v: 14;
  --landing-fs-body: calc(var(--landing-fs-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-body: 1.42857;
  --landing-ls-body: 0%;
  --landing-fs-md-v: 16;
  --landing-fs-md: calc(var(--landing-fs-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-md: 1.375;
  --landing-ls-md: 0%;
  --landing-fs-lg-v: 21;
  --landing-fs-lg: calc(var(--landing-fs-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-lg: 1.33333;
  --landing-ls-lg: 0%;
  --landing-fs-sec-body-v: 16;
  --landing-fs-sec-body: calc(var(--landing-fs-sec-body-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-body: 1.375;
  --landing-ls-body: 0%;
  --landing-fs-sec-md-v: 18;
  --landing-fs-sec-md: calc(var(--landing-fs-sec-md-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-md: 1.44444;
  --landing-ls-md: 0%;
  --landing-fs-sec-lg-v: 26;
  --landing-fs-sec-lg: calc(var(--landing-fs-sec-lg-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-lg: 1.23077;
  --landing-ls-lg: 0%;
  --landing-fs-sec-xl-v: 36;
  --landing-fs-sec-xl: calc(var(--landing-fs-sec-xl-v) / var(--landing-html-font-size-v) * var(--landing-html-font-size));
  --landing-lh-sec-xl: 1.11111;
  --landing-ls-sec-xl: 0%; }
  @media screen and (min-width: 576px) {
    :root {
      --landing-html-font-size: clamp(14px, 1vw, 100vw); } }
  @media screen and (min-width: 1200px) {
    :root {
      --landing-html-font-size: clamp(16px, 1vw, 100vw); } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sm-v: 14;
      --landing-lh-sm: 1.42857; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-body-v: 16;
      --landing-lh-body: 1.375; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-md-v: 18;
      --landing-lh-md: 1.33333; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-lg-v: 28;
      --landing-lh-lg: 1.35714; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-body-v: 18;
      --landing-lh-sec-body: 1.44444; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-md-v: 22;
      --landing-lh-sec-md: 1.45455; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-lg-v: 34;
      --landing-lh-sec-lg: 1.20588; } }
  @media screen and (min-width: 576px) {
    :root {
      --landing-fs-sec-xl-v: 50;
      --landing-lh-sec-xl: 1.08; } }

@use './wrappers-variables' as wv;
@use './media-queries' as mq;
.landing-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5a00;
  background-color: var(--landing-color-primary);
  color: #fff;
  color: var(--landing-color-white);
  padding: 0.75em 1.25em;
  border-radius: 4em;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms;
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 24px;
  line-height: var(--line-height-for-16);
  font-variation-settings: 'wght' 600; }
  .landing-button:hover {
    opacity: 0.8;
    opacity: var(--landing-link-hover-opacity); }

.landing-thank-you {
  display: grid; }
  @media screen and (min-width: 768px) {
    .landing-thank-you {
      min-height: calc(100vh - 504px); } }
  @media screen and (min-width: 1200px) {
    .landing-thank-you {
      min-height: 100vh; } }
  .landing-thank-you > * {
    grid-area: 1/-1; }

.landing-thank-you__background {
  min-width: 100vw;
  min-height: 100%;
  background-image: url("/images/circles-background.svg");
  background-repeat: no-repeat;
  background-size: cover; }

.landing-thank-you__background--circles {
  background-image: url("/images/circles-background-mobile.svg"); }
  @media screen and (min-width: 992px) {
    .landing-thank-you__background--circles {
      background-image: url("/images/circles-background.svg"); } }

.landing-thank-you__background--tech-mesh {
  background: url("/images/tech-mesh-background-mobile.svg"); }
  @media screen and (min-width: 992px) {
    .landing-thank-you__background--tech-mesh {
      background: url("/images/tech-mesh-background.svg"); } }

.landing-thank-you__content {
  padding-top: calc(0.33rem * 20);
  padding-top: calc(var(--s) * 20);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  grid-gap: calc(0.33rem * 4);
  gap: calc(0.33rem * 4);
  grid-gap: calc(var(--s) * 4);
  gap: calc(var(--s) * 4); }
  @media screen and (min-width: 768px) {
    .landing-thank-you__content {
      padding-top: calc(0.33rem * 20);
      padding-top: calc(var(--s) * 20);
      padding-bottom: calc(0.33rem * 11);
      padding-bottom: calc(var(--s) * 11);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; } }

.landing-thank-you__header {
  max-width: 90vw;
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px); }
  @media screen and (min-width: 768px) {
    .landing-thank-you__header {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-thank-you__header {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-thank-you__header {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-thank-you__header {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-thank-you__header {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-thank-you__header {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-thank-you__header {
      padding-right: 0;
      max-width: 40vw; } }

.landing-thank-you__title {
  margin-bottom: calc(0.33rem * 3);
  margin-bottom: calc(var(--s) * 3); }

.landing-thank-you__description > * + * {
  margin-top: calc(0.33rem * 3);
  margin-top: calc(var(--s) * 3); }

.landing-thank-you__description a {
  text-decoration: underline;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }

.landing-thank-you__description a:hover {
  opacity: 0.8;
  opacity: var(--landing-link-hover-opacity); }

.landing-thank-you__description p {
  display: block; }

.landing-thank-you__description ol {
  list-style: decimal; }

.landing-thank-you__description ul {
  list-style: disc; }

.landing-thank-you__description ol,
.landing-thank-you__description ul {
  padding-left: 1.05em;
  padding-left: var(--landing-list-padding); }
  .landing-thank-you__description ol > * + *,
  .landing-thank-you__description ul > * + * {
    margin-top: calc(0.33rem * 2);
    margin-top: calc(var(--s) * 2); }

.landing-thank-you__description strong {
  font-weight: 700;
  font-variation-settings: 'wght' 700; }

.landing-thank-you__description em {
  font-style: italic; }

.landing-thank-you__back-button {
  display: flex;
  align-items: center;
  margin-top: calc(0.33rem * 14);
  margin-top: calc(var(--s) * 14);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition-property: all;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transition-duration: 300ms; }

.landing-thank-you__back-button:hover {
  opacity: 0.5; }

.landing-thank-you__back-button .landing-icon {
  --size: 2em;
  margin-right: calc(0.33rem * 3);
  margin-right: calc(var(--s) * 3); }

.landing-thank-you__links {
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  display: flex;
  flex-direction: column;
  grid-gap: calc(0.33rem * 4);
  gap: calc(0.33rem * 4);
  grid-gap: calc(var(--s) * 4);
  gap: calc(var(--s) * 4); }
  @media screen and (min-width: 768px) {
    .landing-thank-you__links {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-thank-you__links {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-thank-you__links {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-thank-you__links {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .landing-thank-you__links {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .landing-thank-you__links {
      padding-left: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .landing-thank-you__links {
      margin-left: auto; } }

.landing-thank-you__links--none {
  margin-left: auto;
  padding-bottom: calc(0.33rem * 4);
  padding-bottom: calc(var(--s) * 4);
  margin-top: calc(0.33rem * 14);
  margin-top: calc(var(--s) * 14); }
  @media screen and (min-width: 768px) {
    .landing-thank-you__links--none {
      padding-bottom: unset;
      margin-top: auto; } }
  .landing-thank-you__links--none .landing-icon {
    -webkit-transform: rotate(180);
            transform: rotate(180);
    --size: calc(var(--s) * 10);
    --color: var(--landing-color-primary);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
    @media screen and (min-width: 768px) {
      .landing-thank-you__links--none .landing-icon {
        -webkit-transform: unset;
                transform: unset; } }

.landing-thank-you__links--image {
  margin-top: calc(0.33rem * 12);
  margin-top: calc(var(--s) * 12);
  padding-bottom: calc(0.33rem * 4);
  padding-bottom: calc(var(--s) * 4); }
  @media screen and (min-width: 768px) {
    .landing-thank-you__links--image {
      margin-top: auto;
      padding-bottom: unset; } }

.landing-thank-you__links--button {
  padding: 0;
  grid-gap: 0;
  gap: 0;
  margin-top: calc(0.33rem * 14);
  margin-top: calc(var(--s) * 14); }
  @media screen and (min-width: 768px) {
    .landing-thank-you__links--button {
      padding-right: calc(16px + 0px);
      margin-top: 0;
      grid-gap: calc(0.33rem * 4);
      gap: calc(0.33rem * 4);
      grid-gap: calc(var(--s) * 4);
      gap: calc(var(--s) * 4);
      width: 50%; } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .landing-thank-you__links--button {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .landing-thank-you__links--button {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .landing-thank-you__links--button {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 992px) {
    .landing-thank-you__links--button {
      width: 40%; } }

.project__hero-main-content {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-between-xxsmall);
}

.project__hero-main-content > div {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .project__hero-main-content {
    flex-direction: row;
    justify-content: space-between;
  }
}

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-blog-post-preview {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1024px) {
    .inspiring-blog-post-preview {
      margin-bottom: calc(48px + 0px); } }
  .inspiring-blog-post-preview a {
    flex-grow: 1; }

.inspiring-blog-post-preview__content {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2);
  background: #fff;
  background: var(--color-secondary);
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small);
  padding-top: 16px;
  padding-top: var(--space-between-xxsmall);
  position: relative;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing);
  z-index: 25;
  z-index: var(--z-index-card-content); }
  @media screen and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(80px / 2); } }
  .inspiring-blog-post-preview__content::after {
    background: #ee2537;
    background: var(--color-main);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform var(--transition-timing-default) ease-in-out;
    transition: transform var(--transition-timing-default) ease-in-out;
    transition: transform var(--transition-timing-default) ease-in-out, -webkit-transform var(--transition-timing-default) ease-in-out;
    width: 100%;
    z-index: -1;
    z-index: var(--before-after-z-index); }
  .inspiring-blog-post-preview__content .title-text-component__title {
    color: #ee2537;
    color: var(--color-main);
    margin-bottom: 8px;
    margin-bottom: var(--space-between-xxxsmall); }

.inspiring-blog-post-preview__date {
  color: #fff;
  color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  right: var(--space-between-xsmall);
  text-shadow: 1px 1px 2px #000;
  top: 16px;
  top: var(--space-between-xxsmall); }
  .inspiring-blog-post-preview__date .typography:first-child {
    margin: auto; }
  .inspiring-blog-post-preview__date .typography:last-child {
    text-transform: capitalize; }

.inspiring-blog-post-preview .inspiring-blog-post-preview__content {
  transition: 0s background; }

.inspiring-blog-post-preview .inspiring-blog-post-preview__picture {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: var(--generic-transform-timing); }
  .inspiring-blog-post-preview .inspiring-blog-post-preview__picture .picture__image {
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: var(--generic-transform-timing); }

.inspiring-blog-post-preview__wrapper {
  height: 100%;
  position: relative; }

.inspiring-blog-post-preview__bottom {
  display: flex;
  flex-direction: row;
  width: 100%; }

.inspiring-blog-post-preview__title,
.inspiring-blog-post-preview__excerpt,
.inspiring-blog-post-preview__category {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.inspiring-blog-post-preview__category {
  text-decoration: underline; }

.inspiring-blog-post-preview__title--content {
  font-weight: 600;
  line-height: 32px;
  line-height: var(--line-height-for-20-smaller);
  font-size: 20px;
  font-size: var(--font-size-20); }

.inspiring-blog-post-preview__excerpt--content {
  line-height: 32px;
  line-height: var(--line-height-for-20-smaller);
  font-size: 16px;
  font-size: var(--font-size-16); }

.inspiring-blog-post-preview__left {
  overflow: hidden;
  width: 100%; }

.inspiring-blog-post-preview__right {
  --iconography-arrow: 40px;
  margin-left: auto;
  text-align: right;
  width: 100%; }

.inspiring-blog-post-preview__left .animated-inline-button .animated-inline-button__text,
.inspiring-blog-post-preview__right .animated-inline-button .animated-inline-button__text {
  margin-right: 24px;
  margin-right: var(--space-between-xsmall); }

.inspiring-blog-post-preview__author {
  font-weight: bold; }

.inspiring-blog-post-preview__share {
  left: 24px;
  left: var(--space-between-xsmall);
  position: absolute;
  top: 24px;
  top: var(--space-between-xsmall);
  z-index: 1000; }

.inspiring-blog-post-preview__wrapper:hover {
  cursor: pointer; }
  .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content {
    color: #fff;
    color: var(--color-secondary); }
    .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content:after {
      -webkit-transform: scaleY(1);
              transform: scaleY(1); }
    .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button {
      pointer-events: none; }
      .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__main {
        color: #fff;
        color: var(--color-secondary); }
      .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__shaft:before, .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__shaft:after, .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__content .animated-inline-button .animated-inline-button__shaft {
        background-color: #fff;
        background-color: var(--color-secondary); }
  .inspiring-blog-post-preview__wrapper:hover .inspiring-blog-post-preview__picture .picture__image {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

@media screen and (min-width: 768px) {
  .inspiring-blog-post-preview--horizontal {
    flex-direction: row;
    height: 100%; }
  .inspiring-blog-post-preview--horizontal .inspiring-blog-post-preview__picture {
    flex-shrink: 0;
    width: 30%; }
  .inspiring-blog-post-preview--bigger-image .inspiring-blog-post-preview__picture {
    flex-shrink: 0;
    width: 35%; }
  .inspiring-blog-post-preview__picture-image {
    height: 100%; }
  .inspiring-blog-post-preview__content {
    padding-right: calc(16px / 4);
    padding-left: calc(16px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-right: calc(80px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 768px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1440px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 768px) and (min-width: 1680px) {
    .inspiring-blog-post-preview__content {
      padding-left: calc(80px / 4); } }

@media screen and (min-width: 768px) {
  .inspiring-blog-post-preview__left,
  .inspiring-blog-post-preview__right {
    width: 50%; } }

@media screen and (min-width: 1440px) {
  .inspiring-blog-post-preview__title--content {
    font-weight: 600;
    line-height: 40px;
    line-height: var(--line-height-for-20);
    font-size: 24px;
    font-size: var(--font-size-24); }
  .inspiring-blog-post-preview__excerpt--content {
    line-height: 32px;
    line-height: var(--line-height-for-20-smaller);
    font-size: 18px;
    font-size: var(--font-size-18); } }

@media screen and (min-width: 1440px) {
  .inspiring-blog-hero-card__content-title {
    line-height: 64px;
    line-height: var(--line-height-for-64-smaller); }
  .inspiring-blog-hero-card__date {
    right: 32px;
    right: var(--space-between-small);
    top: 32px;
    top: var(--space-between-small); } }

.table-of-contents {
  background-color: #f7f7f7;
  padding: var(--space-between-small); }

.table-of-contents__title {
  margin-bottom: var(--space-between-xsmall);
  display: flex; }

.table-of-contents__item {
  color: var(--color-main); }

.table-of-contents__item-type-H3 {
  margin-left: var(--space-between-xsmall); }

.author-biography {
  border-bottom: 2px solid var(--color-black);
  border-top: 2px solid var(--color-black);
  margin-bottom: 0;
  padding-bottom: var(--space-between-small);
  padding-top: var(--space-between-small); }

.author-biography .row.author-biography__row {
  margin-bottom: 0; }

.author-biography__image {
  width: 100%; }

.author-biography__tag,
.author-biography__title,
.author-biography__icon {
  margin-bottom: var(--space-between-xxxsmall); }

.author-biography__icon {
  fill: var(--color-main); }

.author-biography__image {
  margin-bottom: var(--space-between-xxsmall); }

.author-biography__biography:not(:last-child) {
  margin-bottom: var(--space-between-xsmall); }

.author-biography__more {
  color: var(--color-main); }

.author-biography__more:hover {
  cursor: pointer; }

@media screen and (min-width: 1280px) {
  .author-biography__image {
    margin-bottom: 0; }
  .author-biography__colum-image {
    width: calc((2 / 8) * 100%); }
  .author-biography__column-content {
    width: calc((6 / 8) * 100%); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiringblog__content,
.inspiringblog__main-excerpt,
.inspiringblog__main-toc {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .inspiringblog__content,
    .inspiringblog__main-excerpt,
    .inspiringblog__main-toc {
      margin-bottom: calc(48px + 0px); } }

.inspiringBlog .main-layout {
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .inspiringBlog .main-layout {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiringBlog .main-layout {
      margin-bottom: calc(72px + 0px); } }

.inspiringBlog .news-agenda-module {
  margin-top: calc(32px + 0px);
  padding-bottom: 0; }
  @media screen and (min-width: 1024px) {
    .inspiringBlog .news-agenda-module {
      margin-top: calc(48px + 0px); } }

.inspiringBlog .news-agenda-module__related-news {
  margin-top: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .inspiringBlog .news-agenda-module__related-news {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiringBlog .news-agenda-module__related-news {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiringBlog .news-agenda-module__related-news {
      margin-top: calc(80px + 0px); } }

.inspiringBlog .related-news .related-content-cards {
  margin-top: 0;
  padding: 0; }

.inspiringblog__aside__banner {
  width: 100%; }

.inspiringBlog .inspiringblog__related-technologies {
  padding-bottom: calc(64px + 0px); }
  @media screen and (min-width: 1024px) {
    .inspiringBlog .inspiringblog__related-technologies {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiringBlog .inspiringblog__related-technologies {
      padding-bottom: calc(120px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiringBlog .inspiringblog__related-technologies {
      padding-bottom: calc(160px + 0px); } }
  .inspiringBlog .inspiringblog__related-technologies .generic-preview {
    margin-bottom: 0; }

.hero__links-link:not(:last-child)::after {
  content: ', ';
  display: inline-block; }

.hero__links-link-anchor {
  text-decoration: underline; }

.inspiringblog__main-bottom {
  width: 100%; }

.inspiringblog__main-bottom-column {
  width: 100%; }

.inspiringBlog .comment-form {
  padding-top: calc(48px + 0px);
  padding-bottom: calc(48px + 0px);
  background: #f7f6f5;
  background: var(--color-desert-storm); }
  @media screen and (min-width: 1024px) {
    .inspiringBlog .comment-form {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiringBlog .comment-form {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiringBlog .comment-form {
      padding-top: calc(80px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiringBlog .comment-form {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiringBlog .comment-form {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiringBlog .comment-form {
      padding-bottom: calc(80px + 0px); } }

.inspiringBlog .row {
  margin-bottom: 32px;
  margin-bottom: var(--space-between-small); }
  .inspiringBlog .row:nth-child(6) input {
    margin-left: -20px;
    margin-right: 0; }

.inspiringBlog .form__form-checkbox-content {
  align-items: center;
  display: flex; }

.inspiring-blog__related-tags {
  margin-top: 32px;
  margin-top: var(--space-between-small); }

@media screen and (min-width: 1280px) {
  .inspiringBlog .news-agenda-module {
    margin-top: calc(48px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1024px) {
    .inspiringBlog .news-agenda-module {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1280px) {
    .inspiringBlog .news-agenda-module {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) and (min-width: 1440px) {
    .inspiringBlog .news-agenda-module {
      margin-top: calc(80px + 0px); } }

@media screen and (min-width: 1280px) {
  .inspiringBlog .news-agenda-module__related-news,
  .inspiringblog__technologies .related-content-cards {
    margin-top: 0; }
  .inspiringBlog .hero__column.hero__column-content {
    padding-left: 0; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.comment {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  line-height: 1.4; }
  .comment.child {
    padding-left: 50px; }
  .comment .column:first-child {
    padding-left: 0;
    padding-right: 0;
    flex-shrink: 1; }
  .comment .column:nth-child(2) {
    padding-left: 24px; }
  .comment .column:last-child {
    padding-right: 0;
    flex-grow: 1; }
  .comment .avatar {
    padding-top: 5px; }
  .comment .picture {
    height: 24px;
    width: 24px; }
  .comment .content {
    margin: 15px 0; }

@media screen and (min-width: 768px) {
  .comment {
    margin: 15px auto; } }

@media screen and (min-width: 1024px) {
  .comment {
    margin: 15px auto; } }

@media screen and (min-width: 1680px) {
  .comment {
    margin: 15px auto; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.comments-wrapper {
  flex-direction: column; }
  .comments-wrapper .column:first-child {
    margin-bottom: 30px; }
  .comments-wrapper .comments {
    flex-direction: column;
    margin: 0 40px;
    padding: 15px 0;
    border-top: 2px solid #27251f;
    border-top: 2px solid var(--color-black); }
  .comments-wrapper .typography--heading-medium {
    font-weight: bold; }

@media screen and (min-width: 768px) {
  .comments {
    margin: 15px 40px; } }

@media screen and (min-width: 1024px) {
  .comments {
    margin: 15px 40px; } }

@media screen and (min-width: 1680px) {
  .comments {
    margin: 15px 40px; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.comment-form {
  display: none; }
  .comment-form--visible {
    display: block; }
  .comment-form .button {
    z-index: 1; }
  .comment-form a {
    color: #ee2537;
    color: var(--color-main); }

.comment-form-toggle {
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1024px) {
    .comment-form-toggle {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .comment-form-toggle {
      padding-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .comment-form-toggle {
      padding-bottom: calc(80px + 0px); } }

.hero-slider {
  background-color: var(--color-black); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-blog-posts-hero__column {
  justify-content: center;
  position: relative;
  width: 100%; }

.inspiring-blog-posts-hero__column-content {
  padding-top: calc(32px + 0px);
  padding-bottom: calc(32px + 0px);
  padding-right: calc(16px + 0px);
  padding-left: calc(16px + 0px);
  background-color: #27251f;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  z-index: 99999;
  z-index: var(--z-index-header); }
  @media screen and (min-width: 1024px) {
    .inspiring-blog-posts-hero__column-content {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-blog-posts-hero__column-content {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-posts-hero__column-content {
      padding-right: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-posts-hero__column-content {
      padding-right: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-posts-hero__column-content {
      padding-right: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-posts-hero__column-content {
      padding-left: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog-posts-hero__column-content {
      padding-left: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog-posts-hero__column-content {
      padding-left: calc(80px + 0px); } }

.inspiring-blog-posts-hero {
  margin-bottom: calc(32px + 0px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index);
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  /* Slider */ }
  @media screen and (min-width: 1024px) {
    .inspiring-blog-posts-hero {
      margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog-posts-hero {
      margin-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 768px) and (min-width: 1280px) {
    .inspiring-blog-posts-hero {
      margin-bottom: calc(64px + 0px); } }
  .inspiring-blog-posts-hero .hero-category-title__category {
    color: #ec5e2a;
    color: var(--color-orange-blog);
    margin-bottom: 0; }
  .inspiring-blog-posts-hero .slider {
    display: block;
    position: relative; }
    .inspiring-blog-posts-hero .slider .slider__buttons {
      left: calc(16px + 0px);
      top: calc(32px + 0px);
      position: absolute;
      --iconography-size-default: 32px
    ; }
      @media screen and (min-width: 768px) {
        .inspiring-blog-posts-hero .slider .slider__buttons {
          left: calc(32px + 0px); } }
      @media screen and (min-width: 1440px) {
        .inspiring-blog-posts-hero .slider .slider__buttons {
          left: calc(48px + 0px); } }
      @media screen and (min-width: 1680px) {
        .inspiring-blog-posts-hero .slider .slider__buttons {
          left: calc(80px + 0px); } }
      @media screen and (min-width: 1024px) {
        .inspiring-blog-posts-hero .slider .slider__buttons {
          top: calc(48px + 0px); } }
    .inspiring-blog-posts-hero .slider .slider__button-previous svg {
      position: relative;
      top: 5px; }
    .inspiring-blog-posts-hero .slider .slider__button-next svg {
      position: relative;
      top: 5px; }
  .inspiring-blog-posts-hero .slider__card > * {
    margin-right: 0; }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.form-checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative; }

.form-checkbox:focus,
.form-checkbox:active {
  outline: none; }

.form-checkbox:focus .form-checkbox__content::before {
  border-color: #ee2537;
  border-color: var(--color-main); }

.form-checkbox__input {
  left: -9999px;
  position: absolute; }

.form-checkbox__content {
  padding-left: calc(16px / 4);
  color: #27251f;
  color: var(--color-black);
  display: block;
  position: relative; }
  @media screen and (min-width: 768px) {
    .form-checkbox__content {
      padding-left: calc(32px / 4); } }
  @media screen and (min-width: 1440px) {
    .form-checkbox__content {
      padding-left: calc(48px / 4); } }
  @media screen and (min-width: 1680px) {
    .form-checkbox__content {
      padding-left: calc(80px / 4); } }

.form-checkbox__content::before,
.form-checkbox__content::after {
  box-sizing: border-box;
  position: absolute; }

.form-checkbox__content:focus {
  outline: none; }

.form-checkbox__box {
  border: 2px solid #ee2537;
  border: var(--dimension-border) solid var(--color-main);
  height: 20px;
  height: var(--dimension-checkbox);
  padding: 4px;
  position: relative;
  width: 20px;
  width: var(--dimension-checkbox); }

.form-checkbox__box:focus {
  outline: none; }

.form-checkbox__box > svg {
  fill: #ee2537;
  fill: var(--color-main);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0; }

.form-checkbox__input:checked + .form-checkbox__box > svg {
  opacity: 1; }

.form-checkbox__input:focus + .form-checkbox__content::before {
  border-color: #27251f;
  border-color: var(--color-black); }

.form-checkbox--secondary .form-checkbox__content {
  color: #fff;
  color: var(--color-secondary); }

.form-checkbox__input:checked + .form-checkbox__box--filter {
  background-color: #ee2537;
  background-color: var(--color-main); }

.form-checkbox__content--filter {
  color: #ee2537;
  color: var(--color-main); }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.filter {
  width: 75%;
  position: fixed;
  z-index: 9999999;
  z-index: var(--z-index-modal);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: calc(32px*2 + 16px) 16px;
  padding: calc(var(--space-between-small)*2 + var(--space-between-xxsmall)) var(--space-between-xxsmall);
  background: #fff;
  height: 100%;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.2s ease;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  pointer-events: none; }

.filter.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  pointer-events: auto;
  opacity: 1; }

.filter__close-icon {
  position: absolute;
  top: 24px;
  top: var(--space-between-xsmall);
  right: 16px;
  right: var(--space-between-xxsmall);
  cursor: pointer; }

.filter__aside-title {
  display: none;
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .filter__aside-title {
      margin-bottom: calc(48px + 0px); } }

.filter__title {
  display: flex;
  padding: 16px 32px;
  padding: var(--space-between-xxsmall) var(--space-between-small);
  background-color: #fff;
  background-color: var(--color-secondary);
  color: #ee2537;
  color: var(--color-main);
  justify-content: space-between;
  -webkit-transform: ease all 400ms;
          transform: ease all 400ms;
  will-change: color, background-color; }

.filter__title:hover {
  cursor: pointer; }

.filter__title-icon svg {
  fill: #ee2537;
  fill: var(--color-main);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  will-change: transform, fill; }

.filter__items {
  max-height: 0;
  overflow: hidden;
  padding: 0 32px;
  padding: 0 var(--space-between-small);
  will-change: max-height, padding; }

.filter__item:not(:last-of-type) {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.filter__section {
  border: solid 1px #ee2537;
  border: solid 1px var(--color-main);
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.filter__items input[type='text'] {
  width: 100%;
  padding: 10px;
  border: solid 1px #ee2537;
  border: solid 1px var(--color-main); }

.filter__items > *:not(:last-of-type) {
  margin-bottom: 16px;
  margin-bottom: var(--space-between-xxsmall); }

.filter .form-checkbox__input {
  position: absolute; }

.filter__section--selected .filter__title {
  background-color: #ee2537;
  background-color: var(--color-main);
  color: #fff;
  color: var(--color-secondary); }

.filter__section--selected .filter__items {
  max-height: 9999px;
  padding: 16px 32px;
  padding: var(--space-between-xxsmall) var(--space-between-small); }

.filter__section--selected .filter__title-icon svg {
  fill: #fff;
  fill: var(--color-secondary);
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.filter__section-clean {
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .filter__section-clean {
      margin-bottom: calc(48px + 0px); } }

.filter__clean {
  text-decoration: underline;
  -webkit-text-decoration-color: #ee2537;
          text-decoration-color: #ee2537;
  -webkit-text-decoration-color: var(--color-main);
          text-decoration-color: var(--color-main); }

.filter--mobile {
  width: 100%;
  margin-bottom: calc(32px + 0px); }
  @media screen and (min-width: 1024px) {
    .filter--mobile {
      margin-bottom: calc(48px + 0px); } }

.filter--mobile .filter__title-icon svg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.filter__section-clean .button {
  display: none; }

.filter__item-date {
  font-size: 16px; }

@media screen and (min-width: 768px) {
  .filter--mobile {
    display: none; }
  .filter {
    display: inherit;
    position: relative;
    height: auto;
    padding: 0;
    z-index: 1;
    z-index: var(--base-z-index);
    -webkit-transform: translateX(0);
            transform: translateX(0);
    pointer-events: auto;
    opacity: 1; }
  .filter__close-icon {
    display: none; }
  .filter__aside-title {
    display: flex; } }

@media screen and (max-width: 767px) {
  .filter__section-clean {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 32px);
    grid-gap: 0;
    gap: 0;
    align-content: center; }
  .filter__section-clean .inline-button {
    order: 2;
    align-self: center;
    width: 50%;
    justify-content: center; }
  .filter__section-clean .button {
    display: block;
    font-weight: 700; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.posts-module {
  margin-bottom: calc(48px + 0px);
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 1;
  z-index: var(--base-z-index); }
  @media screen and (min-width: 1280px) {
    .posts-module {
      margin-bottom: calc(64px + 0px); } }

.posts-module .slider {
  padding-bottom: calc(16px + 0px);
  padding-top: calc(16px + 0px);
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .posts-module .slider {
      padding-bottom: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .posts-module .slider {
      padding-bottom: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .posts-module .slider {
      padding-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .posts-module .slider {
      padding-top: calc(32px + 0px); } }
  @media screen and (min-width: 1440px) {
    .posts-module .slider {
      padding-top: calc(48px + 0px); } }
  @media screen and (min-width: 1680px) {
    .posts-module .slider {
      padding-top: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .posts-module .slider {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .posts-module .slider {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .posts-module .slider {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .posts-module .slider {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .posts-module .slider {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .posts-module .slider {
      padding-right: calc(80px / 2); } }

.posts-module .slider::after {
  background: #27251f;
  background: var(--color-black);
  content: "";
  height: 95%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  z-index: var(--before-after-z-index); }

.posts-module .related-content-cards {
  padding-left: 0;
  padding-right: 0; }

.posts-module .related-content-cards__subitle,
.posts-module .related-content-cards__title {
  padding-right: calc(16px / 2);
  padding-left: calc(16px / 2); }
  @media screen and (min-width: 768px) {
    .posts-module .related-content-cards__subitle,
    .posts-module .related-content-cards__title {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .posts-module .related-content-cards__subitle,
    .posts-module .related-content-cards__title {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .posts-module .related-content-cards__subitle,
    .posts-module .related-content-cards__title {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 768px) {
    .posts-module .related-content-cards__subitle,
    .posts-module .related-content-cards__title {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .posts-module .related-content-cards__subitle,
    .posts-module .related-content-cards__title {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .posts-module .related-content-cards__subitle,
    .posts-module .related-content-cards__title {
      padding-left: calc(80px / 2); } }

@media screen and (min-width: 1280px) {
  .posts-module .slider::after {
    width: 66%; } }

@media screen and (min-width: 1440px) {
  .posts-module .slider::after {
    width: 50%; } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.inspiring-blog-index {
  overflow: visible;
  overflow: initial; }

.inspiring-blog__grid {
  margin-left: 0;
  margin-right: 0; }

.inspiring-blog .paginator {
  padding-right: calc(16px / 2);
  margin-top: calc(48px + 0px);
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .inspiring-blog .paginator {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog .paginator {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .inspiring-blog .paginator {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 1024px) {
    .inspiring-blog .paginator {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-blog .paginator {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog .paginator {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .inspiring-blog .paginator {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-blog .paginator {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-blog .paginator {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog .paginator {
      margin-bottom: calc(120px + 0px); } }

.inspiring-blog__non-content {
  margin-bottom: calc(48px + 0px);
  display: block; }
  @media screen and (min-width: 768px) {
    .inspiring-blog__non-content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .inspiring-blog__non-content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-blog__non-content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog__non-content {
      margin-bottom: calc(120px + 0px); } }

.inspiring-blog__content {
  display: flex;
  flex-direction: column-reverse; }
  .inspiring-blog__content .inspiring-blog__content-main {
    width: 100%; }
  .inspiring-blog__content .inspiring-blog__content-aside {
    overflow: visible;
    overflow: initial;
    width: 100%; }
    .inspiring-blog__content .inspiring-blog__content-aside .aside__banner {
      padding-bottom: calc(32px + 0px);
      display: none;
      max-width: 379px;
      width: 100%; }
      @media screen and (min-width: 1024px) {
        .inspiring-blog__content .inspiring-blog__content-aside .aside__banner {
          padding-bottom: calc(48px + 0px); } }
    .inspiring-blog__content .inspiring-blog__content-aside .filter__aside-title {
      margin-bottom: calc(32px + 0px); }
      @media screen and (min-width: 1024px) {
        .inspiring-blog__content .inspiring-blog__content-aside .filter__aside-title {
          margin-bottom: calc(48px + 0px); } }
    .inspiring-blog__content .inspiring-blog__content-aside .form-checkbox {
      align-items: flex-start; }
    .inspiring-blog__content .inspiring-blog__content-aside .form-checkbox__box {
      margin-top: 2px; }
    .inspiring-blog__content .inspiring-blog__content-aside .filter__section-clean {
      margin-bottom: calc(32px + 0px); }
      @media screen and (min-width: 1024px) {
        .inspiring-blog__content .inspiring-blog__content-aside .filter__section-clean {
          margin-bottom: calc(48px + 0px); } }
    .inspiring-blog__content .inspiring-blog__content-aside .filter {
      width: 100%; }

.inspiring-blog__paginator {
  width: 100%; }
  .inspiring-blog__paginator .paginator__item {
    font-size: 20px;
    font-size: var(--font-size-20); }
  .inspiring-blog__paginator .paginator__item--disabled,
  .inspiring-blog__paginator .break + .paginator__item,
  .inspiring-blog__paginator .break {
    display: none; }

.aside__banner-container {
  max-width: 379px;
  position: -webkit-sticky;
  position: sticky;
  text-align: right;
  top: 100px;
  width: 100%; }

.inspiring-blog__bottom > *:first-child {
  background-color: #f7f6f5;
  background-color: var(--color-desert-storm); }

.inspiring-blog__bottom .form {
  padding-top: calc(48px + 0px);
  padding-bottom: calc(48px + 0px); }
  @media screen and (min-width: 1280px) {
    .inspiring-blog__bottom .form {
      padding-top: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog__bottom .form {
      padding-top: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .inspiring-blog__bottom .form {
      padding-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1440px) {
    .inspiring-blog__bottom .form {
      padding-bottom: calc(72px + 0px); } }
  .inspiring-blog__bottom .form__title {
    margin-left: calc(16px / 2);
    margin-right: calc(16px / 2); }
    @media screen and (min-width: 768px) {
      .inspiring-blog__bottom .form__title {
        margin-left: calc(32px / 2); } }
    @media screen and (min-width: 1440px) {
      .inspiring-blog__bottom .form__title {
        margin-left: calc(48px / 2); } }
    @media screen and (min-width: 1680px) {
      .inspiring-blog__bottom .form__title {
        margin-left: calc(80px / 2); } }
    @media screen and (min-width: 768px) {
      .inspiring-blog__bottom .form__title {
        margin-right: calc(32px / 2); } }
    @media screen and (min-width: 1440px) {
      .inspiring-blog__bottom .form__title {
        margin-right: calc(48px / 2); } }
    @media screen and (min-width: 1680px) {
      .inspiring-blog__bottom .form__title {
        margin-right: calc(80px / 2); } }
  .inspiring-blog__bottom .form iframe {
    max-height: 900px;
    overflow: hidden;
    margin-left: calc(16px / 2);
    margin-right: calc(16px / 2); }
    @media screen and (min-width: 768px) {
      .inspiring-blog__bottom .form iframe {
        margin-left: calc(32px / 2); } }
    @media screen and (min-width: 1440px) {
      .inspiring-blog__bottom .form iframe {
        margin-left: calc(48px / 2); } }
    @media screen and (min-width: 1680px) {
      .inspiring-blog__bottom .form iframe {
        margin-left: calc(80px / 2); } }
    @media screen and (min-width: 768px) {
      .inspiring-blog__bottom .form iframe {
        margin-right: calc(32px / 2); } }
    @media screen and (min-width: 1440px) {
      .inspiring-blog__bottom .form iframe {
        margin-right: calc(48px / 2); } }
    @media screen and (min-width: 1680px) {
      .inspiring-blog__bottom .form iframe {
        margin-right: calc(80px / 2); } }

.inspiring-blog__bottom .posts-module .slider::after {
  display: none; }

.inspiring-blog__bottom .inspiring-blog-post-preview__content {
  background: none; }

.inspring-blog__banner-container .inspiring-blog__banner-container-column {
  width: 100%; }

.inspring-blog__banner-container .aside__banner:not(:last-child) {
  padding-bottom: 32px;
  padding-bottom: var(--space-between-small); }

@media screen and (min-width: 768px) {
  .inspiring-blog__paginator .paginator__item--disabled,
  .inspiring-blog__paginator .break + .paginator__item,
  .inspiring-blog__paginator .break {
    display: inherit; } }

@media screen and (min-width: 1024px) {
  .inspiring-blog__content {
    padding-left: calc(16px / 2);
    padding-right: calc(16px / 2);
    flex-direction: row;
    padding-bottom: 0; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .inspiring-blog__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .inspiring-blog__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .inspiring-blog__content {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .inspiring-blog__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .inspiring-blog__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .inspiring-blog__content {
      padding-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
    .inspiring-blog__content .inspiring-blog__content-main {
      padding-left: 0;
      padding-right: 0;
      width: calc((100% / 3) * 2); }
    .inspiring-blog__content .inspiring-blog__content-aside {
      padding-right: 0;
      width: calc(100% / 3); }
      .inspiring-blog__content .inspiring-blog__content-aside .aside__banner {
        display: block; }
      .inspiring-blog__content .inspiring-blog__content-aside .filter {
        max-width: 379px;
        width: 100%; }
      .inspiring-blog__content .inspiring-blog__content-aside > * {
        margin-left: auto;
        margin-right: 0; }
    .inspiring-blog__content .form iframe {
      max-height: 800px; }
  .inspring-blog__banner-container {
    display: none; } }

@media screen and (min-width: 1680px) {
  .inspiring-blog__content-aside {
    padding-left: calc(64px + 0px); } }
  @media screen and (min-width: 1680px) and (min-width: 1024px) {
    .inspiring-blog__content-aside {
      padding-left: calc(72px + 0px); } }
  @media screen and (min-width: 1680px) and (min-width: 1280px) {
    .inspiring-blog__content-aside {
      padding-left: calc(120px + 0px); } }
  @media screen and (min-width: 1680px) and (min-width: 1440px) {
    .inspiring-blog__content-aside {
      padding-left: calc(160px + 0px); } }

:root {
  --color-main: #ee2537;
  --color-main-faded: rgba(238, 37, 55, 0.5);
  --color-secondary: #fff;
  --color-secondary-faded: rgba(255, 255, 255, 0.5);
  --color-black: #27251f;
  --color-grey: #4d5357;
  --color-black-faded: rgba(37, 37, 31, 0.5);
  --color-black-extra-faded: rgba(37, 37, 31, 0.8);
  --color-timberwolf: #d7d2cb;
  --color-desert-storm: #f7f6f5;
  --color-orange-decoration: #ff5000;
  --color-orange-blog-home: #ff5A00;
  --color-orange-blog: #ec5e2a;
  --corporate-color-plum: #7e2574;
  --corporate-color-cloud-burst: #203244;
  --corporate-color-lipstick: #c0004e;
  --corporate-color-shiraz: #a6093d;
  --corporate-color-petite-orchid: #dc8699;
  --corporate-color-hippie-blue: #6ba4b8;
  --corporate-color-sundown: #ffb1bb;
  --corporate-color-orange-roughy: #c75b12;
  --corporate-color-supernova: #fecd00;
  --corporate-color-half-baked: #87c9c4;
  --corporate-color-blue-stone: #00685e;
  --corporate-color-salmon: #ff8d6d;
  --corporate-color-marzipan: #f8e08e;
  --ambit-color-shiraz: #b7003e;
  --ambit-color-electric-violet: #b0f;
  --ambit-color-blue: #0000f7;
  --ambit-color-azure-radiance: #09f;
  --ambit-color-caribbean-green: #00db92;
  --font-family-default: 'Open Sans', sans-serif;
  --font-family-headers: 'Mark', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-size-192: 192px;
  --font-size-96: 96px;
  --font-size-80: 80px;
  --font-size-64: 56px;
  --font-size-48: 48px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --line-height-for-192: 225px;
  --line-height-for-96: 112px;
  --line-height-for-80: 104px;
  --line-height-for-64: 72px;
  --line-height-for-64-smaller: 64px;
  --line-height-for-48-smaller: 48px;
  --line-height-for-40: 64px;
  --line-height-for-32: 48px;
  --line-height-for-20: 40px;
  --line-height-for-20-smaller: 32px;
  --line-height-for-18: 24px;
  --line-height-for-16: 24px;
  --line-height-for-14: 24px;
  --space-between-xxxxsmall: 0;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 16px;
  --space-between-xsmall: 24px;
  --space-between-small: 32px;
  --aspect-ratio-533-409: 409 / 533;
  --aspect-ratio-24-19: 19 / 24;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-8-3: 3 / 8;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;
  --aspect-ratio-1-1: 1 / 1;
  --aspect-ratio-5-4: 4 / 5;
  --dimension-border: 2px;
  --dimension-inline-button: 40px;
  --dimension-checkbox: 20px;
  --dimension-input: 64px;
  --dimension-input-big: 80px;
  --dimension-footer-logos: 64px;
  --dimension-table-link: 88px;
  --dimension-featured-link: 88px;
  --dimension-textarea: 160px;
  --dimension-gallery-caption: 50px;
  --inspiring-stories-logo: 50px;
  --dimension-horizontal-cards: 180px;
  --dimension-agenda-cards: 300px;
  --body-max-width: 1920px;
  --vertical-hero-max-height: 900px;
  --horizontal-talent-module-max-height: 850px;
  --horizontal-news-hero-max-height: 368px;
  --header-logo-max-width: 312px;
  --box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --linear-gradient: linear-gradient(180deg, #c75b12 0%, #ee2537 100%);
  --linear-gradient-black: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-purple-dark-blue: linear-gradient(90deg, var(--corporate-color-plum), var(--corporate-color-cloud-burst));
  --gradient-fuchsia-pink: linear-gradient(90deg, var(--corporate-color-lipstick), var(--corporate-color-plum));
  --gradient-garnet-salmon: linear-gradient(90deg, var(--corporate-color-shiraz), var(--corporate-color-petite-orchid));
  --gradient-garnet-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-shiraz),
    var(--corporate-color-cloud-burst)
  );
  --gradient-light-blue-purple: linear-gradient(90deg, var(--corporate-color-hippie-blue), var(--corporate-color-plum));
  --gradient-light-pink-purple: linear-gradient(90deg, var(--corporate-color-sundown), var(--corporate-color-plum));
  --gradient-yellow-orange: linear-gradient(
    90deg,
    var(--corporate-color-supernova),
    var(--corporate-color-orange-roughy)
  );
  --gradient-orange-red: linear-gradient(90deg, var(--corporate-color-orange-roughy), var(--corporate-color-lipstick));
  --gradient-turquoise-green: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-blue-stone)
  );
  --gradient-light-blue-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-hippie-blue),
    var(--corporate-color-cloud-burst)
  );
  --gradient-turquoise-dark-blue: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-cloud-burst)
  );
  --gradient-salmon-turquoise: linear-gradient(90deg, var(--corporate-color-salmon), var(--corporate-color-half-baked));
  --gradient-turquoise-yellow: linear-gradient(
    90deg,
    var(--corporate-color-half-baked),
    var(--corporate-color-supernova)
  );
  --gradient-light-yellow-yellow: linear-gradient(
    90deg,
    var(--corporate-color-marzipan),
    var(--corporate-color-supernova)
  );
  --gradient-yellow: var(--corporate-color-supernova);
  --gradient-red: var(--ambit-color-shiraz);
  --gradient-fuchsia: var(--ambit-color-electric-violet);
  --gradient-dark-blue: var(--ambit-color-blue);
  --gradient-light-blue: var(--ambit-color-azure-radiance);
  --gradient-light-green: var(--ambit-color-caribbean-green);
  --iconography-size-default: 24px;
  --iconography-search: 32px;
  --iconography-arrow: 60px;
  --iconography-arrow-head: 8px;
  --transition-timing-default: 0.3s;
  --transition-timing-faster: 0.2s;
  --previews-zoom-transform: scale(1.1);
  --generic-transform-timing: transform var(--transition-timing-default) ease-in-out;
  --base-z-index: 1;
  --before-after-z-index: -1;
  --z-index-negative: -5;
  --z-index-picture: 10;
  --z-index-header: 99999;
  --z-index-menu-onepage: 999999;
  --z-index-languages: 15;
  --z-index-modal: 9999999;
  --z-index-card-content: 25;
  --z-index-cookie-banner: 100;
  --youtube-video-padding: calc(var(--aspect-ratio-16-9) * 100%); }

body {
  color: #27251f;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family-default); }

.generic-container__content {
  display: flex;
  flex-direction: column; }

.generic-container__grid {
  margin-left: 0;
  margin-right: 0; }

.generic-container .paginator {
  padding-right: calc(16px / 2);
  margin-top: calc(48px + 0px);
  margin-bottom: calc(48px + 0px); }
  @media screen and (min-width: 768px) {
    .generic-container .paginator {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1440px) {
    .generic-container .paginator {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1680px) {
    .generic-container .paginator {
      padding-right: calc(80px / 2); } }
  @media screen and (min-width: 1024px) {
    .generic-container .paginator {
      margin-top: calc(64px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-container .paginator {
      margin-top: calc(72px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-container .paginator {
      margin-top: calc(80px + 0px); } }
  @media screen and (min-width: 768px) {
    .generic-container .paginator {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .generic-container .paginator {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-container .paginator {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-container .paginator {
      margin-bottom: calc(120px + 0px); } }

.generic-container__non-content {
  margin-bottom: calc(48px + 0px);
  display: block; }
  @media screen and (min-width: 768px) {
    .generic-container__non-content {
      margin-bottom: calc(64px + 0px); } }
  @media screen and (min-width: 1024px) {
    .generic-container__non-content {
      margin-bottom: calc(72px + 0px); } }
  @media screen and (min-width: 1280px) {
    .generic-container__non-content {
      margin-bottom: calc(80px + 0px); } }
  @media screen and (min-width: 1440px) {
    .generic-container__non-content {
      margin-bottom: calc(120px + 0px); } }

@media screen and (min-width: 1024px) {
  .generic-container__content {
    padding-left: calc(16px / 2);
    padding-right: calc(16px / 2);
    flex-direction: row;
    padding-bottom: 0; } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .generic-container__content {
      padding-left: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .generic-container__content {
      padding-left: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .generic-container__content {
      padding-left: calc(80px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 768px) {
    .generic-container__content {
      padding-right: calc(32px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1440px) {
    .generic-container__content {
      padding-right: calc(48px / 2); } }
  @media screen and (min-width: 1024px) and (min-width: 1680px) {
    .generic-container__content {
      padding-right: calc(80px / 2); } }

@media screen and (min-width: 1024px) {
  .generic-container__content-main {
    width: 100%; } }


