.table-of-contents {
  background-color: #f7f7f7;
  padding: var(--space-between-small); }

.table-of-contents__title {
  margin-bottom: var(--space-between-xsmall);
  display: flex; }

.table-of-contents__item {
  color: var(--color-main); }

.table-of-contents__item-type-H3 {
  margin-left: var(--space-between-xsmall); }
